
export async function logExecutionTime(fn: () => Promise<void>) {
    console.log(`Time: ${await executionTime(fn)}ms`)
}


export async function executionTime(fn: () => Promise<void>) {
    const start = Date.now()
    await fn()
    const end = Date.now()
    return end - start
}

