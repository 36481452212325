export class QuestionValidation {
    readonly acceptMultipleAnswers: boolean
    readonly minAccepted?: any
    readonly maxAccepted?: any

    constructor(props: {
        acceptMultipleAnswers: boolean,
        minAccepted?: any
        maxAccepted?: any
    }) {
        this.acceptMultipleAnswers = props?.acceptMultipleAnswers
        this.minAccepted = props?.minAccepted
        this.maxAccepted = props?.maxAccepted
    }
}