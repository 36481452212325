import { PlanConfiguration, classList } from '@punnet/web-client-kit-library'
import type { ParentComponent } from 'solid-js'
import { usePlanConfig } from '../../../app/hooks/usePlanConfig'
import { getCurrentPlans, getCurrentPolicies } from '../../../app/services/AccountProvider'
import { PlanMembership } from '../../PlanBuilder/PlanMembership'
import { displayConfig } from '../../PlanBuilder/config'
import styles from './CompanyPlanViewer.module.css'

export function CompanyPlanViewer() {
    const planConfig = usePlanConfig(), displayConfiguration = displayConfig({ readonly: true })

    const plans = getCurrentPlans()
    const policies = getCurrentPolicies()

    const getCountForPlan = (planId: string) => policies.filter(policy => Object.values(policy.lives).some(life => life.planId === planId)).length
    const isPlanActive = (planId: string) => getCountForPlan(planId) > 0

    const activeClass = styles.PlanCardActive

    return (
        <div class={styles.PlanViewer}>
            <PlanConfiguration
                planConfig={planConfig}
                displayConfig={displayConfiguration}
                plans={plans}
                isPlanActive={isPlanActive}
                class={styles.planConfiguration}
                planActiveClass={activeClass}
            />
            <div class={styles.EmployeeCardContainer}>
                <div class={styles.PlanLabelsContainer}>
                   <Label>Employees</Label>
                </div>
                <div class={styles.PlanInfoContainer}>
                    <PlanMembership
                        plans={plans}
                        policies={policies}
                        count={getCountForPlan}
                        isPlanActive={isPlanActive}
                        isEditable={false}
                        class={styles.PlanMembership}
                        planActiveClass={activeClass}
                    />
                </div>
            </div>
        </div>
    )
}

const Label: ParentComponent<{ class?: string }> = (props) => {
    return <div class={classList(styles.Label, props.class)}>{props.children}</div>
}
