import { type Accessor, type Setter } from 'solid-js'
import isEmail from 'validator/es/lib/isEmail'




export type IamEmailSignal = {
    email: Accessor<string>
    setEmail: Setter<string>
}



export type IamCredentialsSignal = {
    email: Accessor<string>
    password: Accessor<string>
    setEmail: Setter<string>
    setPassword: Setter<string>
}



export function isValidEmail(email: string): boolean {
    return isEmail(email)
}

export function isValidPassword(password: string): boolean {
    return !!password
}


export const hasCredentials = (email: Accessor<string>, password: Accessor<string>) => isValidEmail(email()) && isValidPassword(password())