import type { SubscriptionPrincipal } from '../relationships/SubscriptionRelationshipDefinition'

export type SubscriptionSummaryRecord = {
    accountId: string
    subscriptionId: string
    policyHolder: string
    postcode: string
    startDate: number
    members: number
    premium: number
}


export type SubscriptionSummary = SubscriptionSummaryRecord & {
    ownedBy: SubscriptionPrincipal[]
    sharedWith: SubscriptionPrincipal[]
}


export class SubscriptionSummaryList extends Array<SubscriptionSummary> {}