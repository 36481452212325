
export function popTransition(element: HTMLElement, classname: string) {
    const transitionProperty = getComputedStyle(element).transitionProperty
    if (transitionProperty && transitionProperty !== 'none') {
        element.classList.add(classname)
        element.addEventListener("transitionend", () => {
            element.classList.remove(classname), {once: true}
        })
    }
}

