import {DatePickerStore} from '../DatePickerStore'
import {asDate, DayMonthYear, getCalendarMonth, isDateWithinBounds, isToday, monthDayYearEqual} from '../date-kit'
import {For} from 'solid-js'
import styles from './MonthTable.module.css'
import {classList, NOOP} from '@punnet/pure-utility-kit'
import {DatePickerAppearance} from '../DatePicker'

type DatePickerPageProps = {
    store: DatePickerStore
    appearance: DatePickerAppearance
}

export function MonthTable(props: DatePickerPageProps) {

    const pageData = () => getCalendarMonth(props.store.monthYear)

    const tableClasses = classList(styles.DatePickerMonthTable, props.appearance?.monthTable)

    return (
        <table class={tableClasses}>
            <thead>
            <tr class={props.appearance?.weekNames}>
                <th>Sun</th>
                <th>Mon</th>
                <th>Tue</th>
                <th>Wed</th>
                <th>Thu</th>
                <th>Fri</th>
                <th>Sat</th>
            </tr>
            </thead>

            <tbody>
            <For each={pageData().weeks}>{(week) =>
                <tr>
                    <For each={week}>{(date) =>
                        <DayCell store={props.store} date={date} appearance={props.appearance}/>
                    }</For>
                </tr>
            }</For>

            </tbody>
        </table>
    )
}



type DayCellProps = {
    store: DatePickerStore
    date: DayMonthYear
    appearance: DatePickerAppearance
}


function DayCell(props: DayCellProps) {

    const [day, month, year] = props.date

    const cellDate = () => asDate(props.date)

    const isDisabled = () => !isDateWithinBounds(cellDate(), props.store.minDate, props.store.maxDate)
    const isSelected = () => monthDayYearEqual(props.date, props.store.selectedDate)
    const isWeekend = () => cellDate().getDay() === 0 || cellDate().getDay() === 6
    const isCellToday = () => isToday(cellDate())
    const isWithinDisplayedMonth = () => month === props.store.month

    const cellClasses = () => {
        const classes: string[] = [
            styles.allDays,
            props.appearance?.allDays
        ]
        if (isWithinDisplayedMonth()) {
            classes.push(styles.inMonth, props.appearance?.inMonth)
        } else {
            classes.push(styles.outMonth, props.appearance?.outMonth)
        }

        if (isDisabled()) {
            classes.push(styles.disabled, props.appearance?.disabled)
        }

        if (isSelected()) {
            console.log(styles.selected, props.appearance?.selected);
            
            classes.push(styles.selected, props.appearance?.selected)
        }

        if (isWeekend()) {
            classes.push(styles.weekend, props.appearance?.weekend)
        } else {
            classes.push(styles.weekday, props.appearance?.weekday)
        }

        if (isCellToday()) {
            classes.push(styles.today, props.appearance?.today)
        }

        return classList(...classes)
    }

    const onSelect = isDisabled () ? NOOP : () => {
        console.log('Wha?', cellDate())
        props.store.selectedDate = cellDate()
    }

    return (
        <td><span onClick={onSelect} class={cellClasses()}>{day}</span></td>
    )
}
