import { ParentComponent } from 'solid-js'
import { classList } from '@punnet/pure-utility-kit'
import styles from './Card.module.css'

type CardProps = {
    class?: string
}

// TODO: rename to PlanCard (as seems like this is a generic card component but its not)
export const Card: ParentComponent<CardProps> = (props) => {
    return (
        <div class={classList(styles.Card, 'PlanConfigurationCard', props.class)}>
            {props.children}
        </div>
    )
}