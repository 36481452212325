import {values} from '@punnet/pure-utility-kit'
import {Alteration} from '@punnet/alterations-pure'
import {Subscription, Policy, Life, LifeBenefit, type AlterationType} from '@punnet/subscription-pure'

export function expectAllSubscriptionStartDatesToBe(subscription: Subscription, expectedDate: number) {
    expect(subscription.startDate).toBe(expectedDate)
    values(subscription.policies).forEach(policy => expectAllPolicyStartDatesToBe(policy, expectedDate))
}

export function expectAllPolicyStartDatesToBe(policy: Policy, expectedDate: number) {
    expect(policy.startDate).toBe(expectedDate)
    values(policy.lives).forEach(life => expectAllLifeStartDatesToBe(life, expectedDate))
}

export function expectAllLifeStartDatesToBe(life: Life, expectedDate: number) {
    expect(life.startDate).toBe(expectedDate)
    values(life.benefits).forEach(benefit => expectAllBenefitStartDatesToBe(benefit, expectedDate))
}

export function expectAllBenefitStartDatesToBe(benefit: LifeBenefit, expectedDate: number) {
    expect(benefit.startDate).toBe(expectedDate)
    expect(benefit.effectiveDate).toBe(expectedDate)
    expect(benefit.moriDate).toBe(expectedDate)
}

export function expectAllSubscriptionEndDatesToBe(subscription: Subscription, expectedDate: number) {
    expect(subscription.endDate).toBe(expectedDate)
    values(subscription.policies).forEach(policy => expectAllPolicyEndDatesToBe(policy, expectedDate))
}

export function expectAllPolicyEndDatesToBe(policy: Policy, expectedDate: number) {
    expect(policy.endDate).toBe(expectedDate)
    values(policy.lives).forEach(life => expectAllLifeEndDatesToBe(life, expectedDate))
}

export function expectAllLifeEndDatesToBe(life: Life, expectedDate: number) {
    expect(life.endDate).toBe(expectedDate)
    values(life.benefits).forEach(benefit => expectBenefitEndDateToBe(benefit, expectedDate))
}

export function expectBenefitEndDateToBe(benefit: LifeBenefit, expectedDate: number) {
    expect(benefit.endDate).toBe(expectedDate)
}

export function expectAllSubscriptionItemsToBeCancelled(subscription: Subscription) {
    expect(subscription.status).toBe('CANCELLED')
    values(subscription.policies).forEach(policy => expectAllPolicyItemsToBeCancelled(policy))
}

export function expectAllPolicyItemsToBeCancelled(policy: Policy) {
    expect(policy.status).toBe('CANCELLED')
    values(policy.lives).forEach(life => expectAllLifeItemsToBeCancelled(life))
}

export function expectAllLifeItemsToBeCancelled(life: Life) {
    expect(life.status).toBe('CANCELLED')
    values(life.benefits).forEach(benefit => expectBenefitToBeCancelled(benefit))
}

export function expectBenefitToBeCancelled(benefit: LifeBenefit) {
    expect(benefit.status).toBe('CANCELLED')
}

export function expectAlterationsToMatch(receivedAlterations: Alteration<AlterationType>[], expectedAlterations: Alteration<AlterationType>[]) {
    expect(receivedAlterations.length).toEqual(expectedAlterations.length)
    for (const expectedAlteration of expectedAlterations) {
        expect(receivedAlterations).toContainEqual(expectedAlteration)
    }
}
