import { AddressLookup, type AddressLookupProps } from '@punnet/address-lookup-web-client'
import { classList } from '@punnet/web-client-kit-library'
import styles from './SovereignLookup.module.css'

export function SovereignAddressLookup(props: AddressLookupProps) {
    
    return <AddressLookup
        {...props}
        lookupInputClass={classList(styles.lookupInput, props.lookupInputClass)}
        lookupListClass={classList(styles.lookupList, props.lookupListClass)}
        spinnerClass={styles.spinner}
        showHideTransitionMarkerClass={styles.lookupShowHideTransition}
        updateTransitionMarkerClass={styles.lookupUpdateTransition}
    />
}
