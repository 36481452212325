
import { useIamService } from '@punnet/iam-client'
import { createMemo, type Accessor } from 'solid-js'

export type AuthenticatedState = 'SignedIn' | 'SignedOut' | 'Unauthenticated'

let hasSignedInBefore = false

/**
 * Returns the authenticated state of the user.
 * 
 * Only use in AuthenticationController!
 * 
 * @returns Accessor<AuthenticatedState>
 */
export function _determineAuthenticatedState(): Accessor<AuthenticatedState> {
    const iamService = useIamService()

    return createMemo(() => {
        if (iamService.isSignedIn()) {
            hasSignedInBefore = true
            return 'SignedIn'
        }
        else if (!iamService.isSignedIn() && hasSignedInBefore) {
            return 'SignedOut'
        } 

        return 'Unauthenticated'
    })
}