import { type Properties } from '@punnet/pure-utility-kit'
import { SanctionsCheckEvent } from './SanctionsCheckEvent'

export class SanctionsCheckFlagged extends SanctionsCheckEvent {

    constructor(from: Properties<SanctionsCheckFlagged>) {
        super(from)
        this.status = 'FLAGGED'
        this.type = this.constructor.name
    }
}