import { API } from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import { makeApiGatewayClient } from "@peachy/core-domain-client"
import { Address, GeoLocation } from "@peachy/core-domain-pure"
import { AddressLookupApiDefinition } from "@punnet/address-lookup-pure"


// TODO-DB Where to put this?
// TODO-CC https://bitbucket.org/peachy-punnet/peachy-mono-repo/pull-requests/482/overview#comment-560219920
const PEACHY_HQ: GeoLocation = {
    lat: 51.5444444,
    lng: -0.0228583
}

export function firstMatchingAddressFetcher(): (searchTerm: string) => Promise<Address | undefined> {
    const lookupApi = makeApiGatewayClient(AddressLookupApiDefinition, API, Auth)
    let requestId = 1

    return async searchTerm => {
        if (!searchTerm?.trim()) {
            return
        }
        const results = await lookupApi.lookupAddress({
            searchTerm,
            requestId: `${requestId++}`,
            location: PEACHY_HQ
        })
        if (results.matches?.length > 0) {
            return lookupApi.fetchAddress({ addressId: results.matches[0].id })
        } else {
            console.warn('No matching address found for: ', searchTerm)
        }
    }
}