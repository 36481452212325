import { useSubscriptionService } from '@punnet/subscription-web-client'
import { useNavigate } from '@solidjs/router'
import { Show } from 'solid-js'
import { getQuoteSubscriptionRequest } from '../../../services/quote/getQuoteSubscriptionRequest'
import { useStoreActions } from '../../../store/quotes/quoteAndBuyStore'
import { persistQuote } from '../../../services/quote/persistQuote'
import styles from './SaveAndClose.module.css'

export const SaveAndClose = () => {
    const navigate = useNavigate(), subscriptionService = useSubscriptionService()
    const canSaveQuote = useStoreActions.canSaveQuote

    const onSave = async () => {
        const request = getQuoteSubscriptionRequest()
        console.log('saving quote', request)
        await persistQuote(subscriptionService, request)
        navigate('/quotes')
    }

    return (
        <Show when={canSaveQuote()}>
            <span onClick={onSave} class={styles.Save}>Save & close</span>
        </Show>
    )
}
