import { type TransitionInterceptor } from '@punnet/web-client-kit-library'
import { createEffect, createSignal, untrack } from 'solid-js'
import type { AuthenticationError, IamApi, IamUser } from './IamApi'
import { createIamStateMachine, type IamStates } from './IamStateMachine'



export type IamService = {

    trySignBackIn(): boolean
    signIn(email: string, password: string): boolean

    signOut(redirectUrl?: string): boolean

    signUp(email: string, password: string): boolean
    requestSignUp(): boolean
    cancelSignUp(): boolean

    completeNewPassword(newPassword: string): boolean
    cancelNewPassword(): boolean

    confirmEmailCode(email: string, password: string, confirmationCode: string): boolean
    requestEmailCode(email: string): boolean
    cancelEmailCode(): boolean


    requestPasswordReset(): boolean
    cancelPasswordReset(): boolean
    requestPasswordCode(email: string): boolean
    resetPassword(email: string, newPassword: string, passwordCode: string): boolean

    currentState(): IamStates
    isInState(...s: IamStates[]): boolean
    isSignedIn(): boolean
    isInUserGroups(...groups: string[]): boolean
    userGroups(): string[]
    idToken(): string

    isBusy(): boolean
    allowSelfSignUp(): boolean

    error(): AuthenticationError
}


export function createIamService(
    iamApi: IamApi,
    transitionInterceptor?: TransitionInterceptor
): IamService {

    const iamStateMachine = createIamStateMachine(transitionInterceptor)

    const [error, setError] = createSignal<AuthenticationError | undefined>()
    const [user, setUser] = createSignal<IamUser>()

    const clearError = () => setError()


    const iamService = {
        trySignBackIn: () => iamStateMachine.fire.signBackIn(async () => {
            clearError()
            try {
                const user = await iamApi.currentAuthenticatedUser()
                setUser(user)
                iamStateMachine.fire.signBackInSuccess()
            } catch (e) {
                iamStateMachine.fire.signBackInFailure()
            }
        }),

        requestSignUp: () => {
            console.log('requestSignUp', iamApi.allowSelfSignUp)
            return iamApi.allowSelfSignUp && iamStateMachine.fire.requestSignUp(clearError)
        },

        signUp(email: string, password: string): boolean {
            return iamApi.allowSelfSignUp && iamStateMachine.fire.signUp(async () => {
                clearError()
                try {
                    await iamApi.signUp(email, password)
                    iamStateMachine.fire.signUpSuccess()
                } catch (e) {
                    iamStateMachine.fire.signUpFailure(() => setError(iamApi.extractError(e)))
                }
            })
        },

        cancelSignUp: () => iamStateMachine.fire.cancelSignUp(clearError),

        signIn(email: string, password: string): boolean {
            return iamStateMachine.fire.signIn(async () => {
                clearError()
                try {
                    const user: IamUser = await iamApi.signIn(email, password)
                    setUser(user)
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        iamStateMachine.fire.requiresNewPassword()
                    } else {
                        iamStateMachine.fire.signInSuccess()
                    }

                } catch (e) {
                    const error = iamApi.extractError(e)
                    console.log(e)

                    if (error === 'PasswordExpired') {
                        // await smeUserService.reInvite(email)
                    }
                    iamStateMachine.fire.signInFailure(() => setError(error))
                }
            })
        },

        confirmEmailCode(email: string, password: string, confirmationCode: string): boolean {
            return iamStateMachine.fire.confirmEmailCode(async () => {
                clearError()
                try {
                    await iamApi.confirmSignUp(email, confirmationCode)
                    iamStateMachine.fire.confirmEmailCodeSuccess()
                } catch (e) {
                    iamStateMachine.fire.confirmEmailCodeFailure(() => setError(iamApi.extractError(e)))
                }
            })
        },

        requestEmailCode(email: string): boolean {
            return iamStateMachine.fire.requestEmailCode(async () => {
                clearError()
                try {
                    await iamApi.resendSignUp(email)
                    iamStateMachine.fire.requestEmailCodeSuccess()
                } catch (e) {
                    iamStateMachine.fire.requestEmailCodeFailure(() => setError(iamApi.extractError(e)))
                }
            })
        },

        cancelEmailCode: () => iamStateMachine.fire.cancelEmailCode(clearError),


        signOut(redirectUrl?: string): boolean {
            return iamStateMachine.fire.signOut(async () => {
                clearError()
                try {
                    await iamApi.signOut()
                    if (redirectUrl) {
                        window.location.href = redirectUrl
                    } else {
                        iamStateMachine.fire.signOutSuccess()
                    }

                } catch (e) {
                    iamStateMachine.fire.signOutFailure(() => setError(iamApi.extractError(e)))
                }
            })
        },

        requestPasswordReset: () => iamStateMachine.fire.requestPasswordReset(clearError),

        cancelPasswordReset: () => iamStateMachine.fire.cancelPasswordReset(clearError),

        requestPasswordCode: (email: string) => iamStateMachine.fire.requestPasswordCode(async () => {
            clearError()
            try {
                const response = await iamApi.forgotPassword(email)
                console.log(response)
                iamStateMachine.fire.requestPasswordCodeSuccess()

            } catch (e) {
                iamStateMachine.fire.requestPasswordCodeFailure(() => setError(iamApi.extractError(e)))
            }
        }),

        resetPassword: (email: string, newPassword: string, passwordCode: string) => iamStateMachine.fire.resetPassword(async () => {
            clearError()
            try {
                await iamApi.forgotPasswordSubmit(email, passwordCode, newPassword)
                iamStateMachine.fire.resetPasswordSuccess()
            } catch (e) {
                iamStateMachine.fire.resetPasswordFailure(() => setError(iamApi.extractError(e)))
            }
        }),

        completeNewPassword(newPassword: string): boolean {
            return iamStateMachine.fire.completeNewPassword(async () => {
                clearError()
                try {
                    const authedUser = await iamApi.completeNewPassword(untrack(user), newPassword)
                    iamStateMachine.fire.newPasswordSuccess()
                    setUser(authedUser)
                } catch (e) {
                    iamStateMachine.fire.newPasswordFailure(() => setError(iamApi.extractError(e)))
                }
            })
        },

        cancelNewPassword: () => iamStateMachine.fire.cancelNewPassword(clearError),

        isInState(...s: IamStates[]): boolean {
            return iamStateMachine.isInState(...s)
        },

        currentState() {
            return iamStateMachine.currentState()
        },

        isBusy(): boolean {
            return iamStateMachine.hasTag('busy')
        },

        isInUserGroups(...groups: string[]): boolean {
            return this.userGroups()?.some((g: string) => groups.includes(g))
        },

        isSignedIn(): boolean {
            return this.isInState('SignedIn')
        },

        idToken() {
            // @ts-ignore

            return asDoc(user()?.signInUserSession)
        },

        userGroups(): string[] {
            // @ts-ignore
            return user()?.signInUserSession.accessToken.payload['cognito:groups']
        },

        error(): AuthenticationError {
            return error()
        },

        allowSelfSignUp(): boolean {
            return iamApi.allowSelfSignUp
        }
    }

    createEffect(() => {
        iamStateMachine.currentState()
        console.log(iamStateMachine.currentState())
    })


    return iamService
}
