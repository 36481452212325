import {Observable} from 'rxjs'
import {AsyncDisposableResource} from '@peachy/utility-kit-disposable'

export type SocketOut<M = any> = (message: M, timeout?: number) => Promise<void>
export type SocketIn<M = any> = Observable<M>



export interface Socket<M = any> extends AsyncDisposableResource {
    in: SocketIn<M>
    out: SocketOut<M>
}


export type ApiAction =
    | (() => Promise<any>)
    | (() => Observable<any>)
    | ((m: any) => Promise<any>)
    | ((m: any) => Observable<any>)
    | ((o: Observable<any>) => Promise<any>)
    | ((o: Observable<any>) => Observable<any>)


export type ActionApi = {
    [k: string]: ApiAction
}


export const ApiDefMarker = Symbol('ApiDefinition')

export type ActionApiDefinition<A extends ActionApi> = A & {
    [ApiDefMarker]?: string
}


export function defineActionApi<A extends ActionApi>(name: string, def: A): ActionApiDefinition<A> {
    return {
        [ApiDefMarker]: name,
        ...def
    }
}


export function returnsPromise<T = void>() {
    return Promise.resolve<T>(null)
}

export function returnsObservable<T = void>() {
    return new Observable<T>()
}
