import { Properties } from '@punnet/pure-utility-kit'
import { AnEvent } from '@punnet/core-event-pure'

import type { LifeTurnover, LifeEventData } from '../../notifications/notification-types'
import type { AccountType } from '../../../domain/models/Account'

export class MemberPolicyAlterationDocSendRequest extends AnEvent implements LifeEventData {
    constructor(from: Properties<MemberPolicyAlterationDocSendRequest>) {
        super()
        Object.assign(this, from)
    }

    lifeTurnover?: LifeTurnover

    cognitoId: string

    accountId: string
    accountType: AccountType
    lifeId: string
    policyId: string
    subscriptionId: string
}
