import {defineValidator, MessageProducer, ValidationDirectives,} from '../validation-core'

export default function ValidateNested<T>(
    message?: string | MessageProducer<T>
) {
    return defineValidator<T>({
        name: 'ValidateNested',
        message:
            message ??
            (({property}) => `Property ${property.toString()} must be a valid instance`),
        validate() {
            return ValidationDirectives.VALIDATE_NESTED
        },
    })
}
