import type { SubscriptionQuoteRequest } from '@punnet/subscription-pure'
import { untrack } from "@punnet/web-client-kit-library"
import { onMount } from "solid-js"
import { PageRouter } from "../../../components/PageRouter/PageRouter"
import type { PageRouterPath } from "../../../components/PageRouter/PageRouterPath"
import { getActiveAccount, getCurrentPlans, getCurrentPolicies } from "../../services/AccountProvider"
import { createSubscription } from '../../services/quote/createSubscription'
import { mapToMemberPolicies } from "../../store/members/mapToMemberPolicies"
import { mapToSubscriptionRequest } from '../../store/members/mapToSubscriptionRequest'
import { subscribeToMembersStore, useMembersStoreActions, type MembersStateData } from "../../store/members/membersStore"
import { QuoteController } from '../Quotes/QuoteController/QuoteController'
import { Members } from "./Members"
import { MembersSummary } from "./MembersSummary/MembersSummary"

const BASE_PATH = '/members'

const routes = [
    {
        component: Members,
        path: BASE_PATH,
        canAccess: () => true
    },
    {
        component: MembersSummary,
        path: `${BASE_PATH}/summary`,
        canAccess: () => false
    },
] as PageRouterPath[]


export function MembersRoutes() {

    // load the current active subscription data
    const policies = () => getCurrentPolicies()
    const plans = () => getCurrentPlans()
    const activeAccount = getActiveAccount()

    // TODO probably should make a component to wrap members store logic
    const { init, hasChangedMemberPolicies } = useMembersStoreActions()

    onMount(() => {
        init({
            plans: untrack(plans()),
            memberPolicies: untrack(mapToMemberPolicies(policies(), 'IDLE')),
            initial: untrack(mapToMemberPolicies(policies(), 'IDLE'))
        })
    })

    const hasMemberChanges = () => hasChangedMemberPolicies()

    const quoteSubscription = createSubscription<MembersStateData, SubscriptionQuoteRequest>(
        subscribeToMembersStore,
        (state, prevState) => hasMemberChanges(),
        (state) => mapToSubscriptionRequest(activeAccount, state.memberPolicies)
    )

    return (
        <QuoteController subscribe={quoteSubscription}>
            <PageRouter routes={routes}></PageRouter>
        </QuoteController>
    )
}