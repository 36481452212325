import {defineValidator, MessageProducer, ValidateIf} from '../validation-core'
import {isIsoDate} from '@punnet/pure-utility-kit'

export default function IsIsoDateString<T>(
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'IsIsoDateString',
        message:
            message ??
            (({property}) =>
                `Property ${property.toString()} must be a valid ISO date string`),
        validateIf,
        validate({value}) {
            return isIsoDate(value)
        },
    })
}
