import { createSignal, type JSX } from 'solid-js'
import { withViewTransition } from '../../functions/withViewTransition'



export type Tab = {
    id: string
    label: string
    content: JSX.Element
}

export type TabsProps = {
    children: Tab[]
    enableTransitions?: boolean
    transitionMarkerClass?: string
    initialTabId?: string
    class?: string
}

export function Tabs(props: TabsProps) {
    const [currentTab, setCurrentTab] = createSignal<string>(props.initialTabId ?? props.children[0].id)

    const onTabClick = (tabId: string) => {
        withViewTransition({
            transitionMarkerClass: props.transitionMarkerClass,
            condition: props.enableTransitions,
            updateDom: () => {
                setCurrentTab(tabId)
            }
        })
    }

    return (
        <section class={props.class}>
            <header>
                <nav>
                    {props.children.map(tab => (
                        <a onClick={() => onTabClick(tab.id)} data-active={tab.id === currentTab()}>
                            <span>
                                {tab.label}
                            </span>
                        </a>
                    ))}
                </nav>
            </header>
            <main>
                {props.children.find(tab => tab.id === currentTab())?.content}
            </main>
        </section>
    )
}