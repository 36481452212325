import { AnEvent } from '@punnet/core-event-pure'


import type { AccountType } from '../../domain/models/Account'
import type { LifeEventData } from '../notifications/notification-types'

export class LifeRepoUpserted extends AnEvent implements LifeEventData {

    accountId: string
    accountType: AccountType
    subscriptionId: string
    policyId: string
    lifeId: string
    cognitoId: string

    constructor(from: LifeRepoUpserted) {
        super()
        Object.assign(this, from)
    }
}
