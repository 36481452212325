import type { ParentComponent } from 'solid-js'
import { PrimaryButton } from '../../../../components/Button/Buttons'
import { Next } from '../../../../components/PageRouter/Next'
import { Previous } from '../../../../components/PageRouter/Previous'
import styles from './NavigationButtons.module.css'

type NavigationButtonsProps = {
    nextButtonDisabled?: boolean
    nextButtonText?: string
    onNext?: () => Promise<void>
}

export const NavigationButtons: ParentComponent<NavigationButtonsProps> = (props) => {
    return <div class={styles.Buttons}>
        <Next onClick={props.onNext}>
            <PrimaryButton disabled={props.nextButtonDisabled}>{props.nextButtonText ?? 'Next'}</PrimaryButton>
        </Next>
        {props.children}
    </div>
}

export const PreviousButton = () => <Previous><PreviousButtonOnly /></Previous>

export const PreviousButtonOnly = () => <div class={styles.PreviousButton}><i class="fal fa-angle-left" /></div>