import {MultiMap} from 'mnemonist'
import {Alteration} from './alteration-types'


export function mapAlterationsById<T extends string>(alterations: Alteration<T>[]): AlterationMap<T> {
    const map = new MultiMap<string, Alteration<T>>()
    for (const alteration of alterations) {
        map.set(alteration.id, alteration)
    }
    return map
}

export function mapNestedAlterationsById<T extends string>(alterations: Alteration<T>[]): AlterationMap<T> {
    const map = new MultiMap<string, Alteration<T>>()
    for (const alteration of alterations) {
        map.set(alteration.id, alteration)
        for (const ancestor of alteration.path) {
            map.set(ancestor, alteration)
        }
    }
    return map
}


export type AlterationMap<T extends string> = MultiMap<string, Alteration<T>>
