import {Class} from 'type-fest'
import {KeyMapped, keys, Optional} from '@peachy/utility-kit-pure'
import {
    ApiGatewayDefinition,
    ApiGatewayRouteDefinition,
    ApiGatewayRoutesDefinition,
    hasParams,
    insertParams,
    paramNames,
    Params
} from '@peachy/core-domain-pure'
import {Auth} from '@aws-amplify/auth'
import {API} from '@aws-amplify/api'

import {fetchServiceConfig} from './fetchServiceConfig'
import {getZoneInfo} from '@peachy/zone-config-pure'


type RouteImpl<RD extends ApiGatewayRouteDefinition> =
    {} extends Params<RD['path']>
        ? RD['requestType'] extends Class<infer REQ>
            ? (request: REQ) => ResponseType<RD>
            : () => ResponseType<RD>
        : RD['requestType'] extends Class<infer REQ>
            ? (pathParams: Params<RD['path']>, request: REQ) => ResponseType<RD>
            : (pathParams: Params<RD['path']>) => ResponseType<RD>

type RoutesImpl<R extends ApiGatewayRoutesDefinition> = {
    [r in keyof R]: RouteImpl<R[r]>
}

type ResponseType<RD extends ApiGatewayRouteDefinition> =
    RD['responseType'] extends Class<infer RES>
        ? Promise<RES>
        : Promise<void>


function implementRoute<const RD extends ApiGatewayRouteDefinition>(
    apiName: string,
    route: string,
    routeDef: RD,
    remoteApi: typeof API,
    auth: typeof Auth,
    servicePatchUri: Optional<string>
): RouteImpl<RD> {

    const impl = async (pathParamsOrRequestObject?: any, requestObjectOrUndefined?: any) => {

        await ensureApiGateway(apiName, remoteApi, servicePatchUri, auth)

        let pathParams: KeyMapped<string>
        let requestObject: unknown

        if (hasParams(routeDef.path)) {
            pathParams = pathParamsOrRequestObject
            requestObject = requestObjectOrUndefined
        } else {
            pathParams = {}
            requestObject = pathParamsOrRequestObject
        }


        const actualPathParams = validatePathParams(routeDef.path, pathParams, route, apiName)
        const actualRequestObject = validateRequestObject(routeDef.requestType, requestObject, route, apiName)

        const httpRequest = {
            body: actualRequestObject,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            } as KeyMapped<string>
        }

        if (!routeDef.isPublic) {
            const user = await auth.currentAuthenticatedUser()
            httpRequest.headers.Authorization = user.signInUserSession.idToken.jwtToken
        }

        const path = insertParams(routeDef.path, actualPathParams)

        switch (routeDef.method) {
            case 'POST': {
                return remoteApi.post(apiName, path, httpRequest)
            }
            case 'GET': {
                return remoteApi.get(apiName, path, httpRequest)
            }
            case 'DELETE': {
                return remoteApi.del(apiName, path, httpRequest)
            }
            case 'PUT': {
                return remoteApi.put(apiName, path, httpRequest)
            }
        }
        routeDef.path
    }

    return impl as RouteImpl<RD>
}





function validatePathParams<P extends string>(
    path: P,
    params: KeyMapped<string>,
    route: string,
    api: string
): Params<P> {
    const expectedParams = paramNames(path)
    const actualParams = keys(params)
    if (actualParams.length != expectedParams.length || !expectedParams.every(p => actualParams.includes(p))) {
        throw `Invalid path params passed to ${api}.${route}(). Expected path params [${expectedParams.join(',')}], but received ${actualParams.join(',')}. Check your API definition and usage`
    }
    return params as Params<P>
}


function validateRequestObject(
    expectedRequestType: Class<unknown>,
    actualRequestObject: unknown,
    route: string,
    api: string

) {

    if (expectedRequestType && !actualRequestObject) {
        throw `No request object passed to ${api}.${route}(). Expected request object of type ${expectedRequestType.name}`
    }
    if (!expectedRequestType && actualRequestObject) {
        throw `Unexpected request object passed to ${api}.${route}(). `
    }
    return actualRequestObject
}


export type ApiConfig = {
    name: string
    endpoint: string
    authorizationType: string
}[]

const apiConfigs: Map<string, ApiConfig> = new Map()

type Config = {
    auth?: typeof Auth,
    servicePatchUri?: string
}



async function ensureApiGateway(apiName: string, remoteApi: typeof API, servicePatchUri: Optional<string>, auth: Optional<typeof Auth>) {
    if (apiConfigs.has(apiName)) {
        remoteApi
    } else {
        await configureApiGateway(apiName, remoteApi, {servicePatchUri, auth})
    }
}


export async function configureApiGateway(apiName: string, remoteApi: typeof API, {auth, servicePatchUri}: Config = {}) {

    const servicePatch = await fetchServiceConfig(apiName, servicePatchUri ?? getZoneInfo().servicePatchUrl)

    apiConfigs.set(apiName, {
        name: apiName,
        ...servicePatch,
        ...(auth ? jwtIdentityTokenAuth(auth): {})
    })

    remoteApi.configure({
        endpoints: [...apiConfigs.values()]
    })
}





function jwtIdentityTokenAuth(auth: typeof Auth) {
    return {
        custom_header: async () => {
            const identityToken = await getJwtIdentityToken(auth)
            return identityToken ? {Authorization: `Bearer ${identityToken}`} : {}
        }
    }
}

async function getJwtIdentityToken(auth: typeof Auth) {
    try {
        return (await auth.currentSession())?.getIdToken()?.getJwtToken()
    } catch(e) {
        // Auth.currentSession() throws when not authenticated and unhelpfully there is no way to test if authed either, so catch and ignore, yay!
    }
}



export function makeApiGatewayClient<const R extends ApiGatewayRoutesDefinition>(
    apiDefinition: ApiGatewayDefinition<R>,
    remoteApi: typeof API,
    auth: typeof Auth,
    servicePatchUri?: string
): ApiGatewayClient<ApiGatewayDefinition<R>> {

    const client = {} as any

    const routeNames = keys(apiDefinition.routes)
    for (const route of routeNames) {
        client[route] = implementRoute(apiDefinition.name, route, apiDefinition.routes[route], remoteApi, auth, servicePatchUri)
    }
    return client as RoutesImpl<R>
}

export type ApiGatewayClient<AD extends ApiGatewayDefinition<any>> = AD extends ApiGatewayDefinition<infer R>
    ? RoutesImpl<R>
    : never
