import {dematerialize, map, materialize, Observable, ObservableNotification} from 'rxjs'
import {completeNotification, errorNotification} from '@peachy/utility-kit-rxjs'
import {SocketOut} from './socket-kit'
import {CallReceiver} from './CallCenter'

export type MessageId = string
export type CallId = string


export type CallConnection = {
    clientId: string,
    connectionId: string,
    endpoint: string,
    callId: string
}

export type CallBegin = {
    type: 'CallBegin'
    messageId: MessageId
    callId: CallId
}

export type CallUpdate<M> = {
    type: 'CallUpdate'
    messageId: MessageId
    callId: CallId
    message: M
}

export type CallEnd = {
    type: 'CallEnd'
    callId: CallId
    messageId: MessageId
}

export type CallReceipt = {
    type: 'CallReceipt'
    callId: CallId
    for: CallPacket<any>['type']
    messageId: MessageId
    error?: any
}

export type CallMessage<M> =
    | CallBegin
    | CallUpdate<M>
    | CallEnd

export type CallPacket<M> =
    | CallMessage<M>
    | CallReceipt


export type CallPacketType = CallPacket<unknown>['type']

const CallPacketTypes = [
    'CallBegin',
    'CallUpdate',
    'CallEnd',
    'CallReceipt',
] as const satisfies readonly CallPacketType[]



export type ConnectCall = () => Promise<void>

export type RouteCallPacket = (callPacket: CallPacket<any>, connectCall: ConnectCall) => Promise<void>

export type ReceiveCall<M> = (
    callId: CallId,
    socketOut: SocketOut,
    callReceiver: CallReceiver<M>,
) => Promise<void>




export function isCallPacket<M>(packet: any): packet is CallPacket<M> {
    return typeof packet.callId === 'string'
        && typeof packet.messageId === 'string'
        && typeof packet.type === 'string'
        && CallPacketTypes.includes(packet.type)
        && packet.type === 'CallReceipt' ? CallPacketTypes.includes(packet.for) : true
}

export function isCallMessage<M>(packet: any): packet is CallMessage<M> {
    return isCallPacket(packet) && packet.type !== 'CallReceipt'
}

export function isCallBegin(packet: any): packet is CallBegin {
    return isCallPacket(packet) && packet.type === 'CallBegin'
}
export function isCallUpdate<M>(packet: any): packet is CallUpdate<M> {
    return isCallPacket(packet,) && packet.type === 'CallUpdate'
}
export function isCallEnd(packet: any): packet is CallEnd {
    return isCallPacket(packet) && packet.type === 'CallEnd'
}
export function isCallReceipt(packet: any): packet is CallReceipt {
    return isCallPacket(packet) && packet.type === 'CallReceipt'
}




export function mapToIncomingUpdate$<M>() {
    return (socketIn: Observable<CallMessage<M>>): Observable<M> => {
        return socketIn.pipe(
            materialize(),
            map((n) => {
                if (n.kind === 'N') {
                    switch (n.value.type) {
                        case 'CallBegin':
                            return errorNotification('incoming call on same line!')
                        case 'CallEnd':
                            return completeNotification()
                        case 'CallUpdate':
                            return n as ObservableNotification<CallUpdate<M>>
                    }
                } else {
                    return n
                }
            }),
            dematerialize(),
            map(p => p.message)
        )
    }
}
