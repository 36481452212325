import {defineValidator, MessageProducer, ValidateIf} from '../validation-core'
import {enumerate, isString, last} from '@punnet/pure-utility-kit'

export const PasswordRequirements = enumerate([
    'UPPERCASE',
    'LOWERCASE',
    'NUMBER',
    'SPECIAL',
] as const)

export type PasswordRequirement = keyof typeof PasswordRequirements;

const validations = {
    [PasswordRequirements.UPPERCASE]: new RegExp('[A-Z]+'),
    [PasswordRequirements.LOWERCASE]: new RegExp('[a-z]+'),
    [PasswordRequirements.NUMBER]: new RegExp('[0-9]+'),
    [PasswordRequirements.SPECIAL]: new RegExp('[%@]+'),
}

export default function IsValidPassword<T>(
    minLength: number,
    requirements: PasswordRequirement[],
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'IsValidPassword',
        message: message ?? (({property}) => getMessage(requirements, minLength)),
        messageParams: {minLength, requirements},
        validateIf,
        validate({value}) {
            return (
                isString(value) &&
                value.length >= minLength &&
                requirements.every((r) => validations[r].test(value))
            )
        },
    })
}

export function validatePassword(
    value: string,
    minLength: 8,
    requirements: PasswordRequirement[]
) {
    return (
        isString(value) &&
        value.length >= minLength &&
        requirements.every((r) => validations[r].test(value))
    )
}

function getMessage(requirements: PasswordRequirement[], minLength: number) {
    const parts = requirements.map((r) => {
        switch (r) {
            case 'LOWERCASE':
                return 'one lowercase letter'
            case 'UPPERCASE':
                return 'one uppercase letter'
            case 'NUMBER':
                return 'one number'
            case 'SPECIAL':
                return 'one special character'
        }
    })

    let message = `Passwords must have at least ${minLength} characters`

    if (parts.length > 1) {
        message = `${message}, including ${parts
            .slice(0, parts.length - 2)
            .join(',')} and ${last(parts)}`
    } else {
        message = `${message}, including ${parts[0]}`
    }

    return message
}
