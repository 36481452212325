import {SubscriptionQuoteRequest} from './SubscriptionQuoteRequest'

export class SubscriptionQuoteResponse extends SubscriptionQuoteRequest {

    quoteResponseId: string
    quoteResponseTimestamp: number

    totalMonthlyPremium: number
    totalAnnualPremium: number

    constructor(from: SubscriptionQuoteResponse) {
        super(from)
        Object.assign(this, from)
    }
}
