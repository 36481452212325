import { Properties } from '@punnet/pure-utility-kit'
import { AnEvent } from '@punnet/core-event-pure'
import type { IdCheckStatus } from '../../domain/models/statuses'


export class IdCheckEvent extends AnEvent {
    constructor(from: Properties<IdCheckEvent>) {
        super()
        Object.assign(this, from)
    }
    firstname: string
    lastname: string
    birthdate: string
    cognitoId: string
    status: IdCheckStatus
    s3Url: string
    vouchedJobId: string
    vouchedPortalUrl: string
    errors?: VouchedError[]
}

export type VouchedError = {
    type: string,
    message: string
    warning: boolean
    suggestion: string
}
