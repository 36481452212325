import {utf8ByteLength} from '@punnet/pure-utility-kit'


export type JsonItem<T> = {
    item: T,
    json: string,
    bytes: number
}

export class JsonBuffer<T> {

    private buffer: JsonItem<T>[] = []
    private bufferMaxBytes = 0

    constructor(private maxBytes: number) {
        this.bufferMaxBytes = maxBytes
    }

    private bufferedBytes = 0

    addItem(item: T): boolean {
        const json = JSON.stringify(item)
        const bytes = utf8ByteLength(json)

        if (this.jsonBytes() + bytes > this.maxBytes) {
            return false
        } else {
            this.bufferedBytes += bytes
            this.buffer.push({
                item,
                json,
                bytes
            })
            return true
        }
    }

    itemCount() {
        return this.buffer.length
    }

    jsonBytes() {
        return this.bufferedBytes + jsonOverhead(this.buffer)
    }
    toJson() {
        return `[${this.buffer.map(jsonItem => jsonItem.json).join(',')}]`
    }

    items() {
        return this.buffer.map(ji => ji.item)
    }

}


function jsonOverhead(elements: any[]) {
    return '[]'.length + (','.length * elements.length-1)
}
