import {isArray, isObject} from '@punnet/pure-utility-kit'
import {Stack} from 'mnemonist'


type Key = string | number

export type WalkMode = {
    traversal: 'DEPTH' | 'BREADTH'
    direction: 'UP' | 'DOWN'
    reverse?: boolean
}



type WalkStep = {
    node: unknown,
    path: Stack<Key>,
    stack: Stack<unknown>,
    onStep: OnWalkStep,
    mode: WalkMode
}


export function walkNode(node: unknown, onStep: OnWalkStep, mode?: Partial<WalkMode>) {
    walk({
        node,
        path: new Stack(),
        stack: new Stack(),
        onStep,
        mode: {
            traversal: mode?.traversal ?? 'DEPTH',
            direction: mode?.direction ?? 'UP',
            reverse: !!mode?.reverse
        }
    })
}


export type OnWalkStep<T = unknown> = (
    node: T,
    path: Key[],
    stack: T[],

) => void






function walk(walkStep: WalkStep) {
    const {node, path, stack, onStep, mode} = walkStep
    stack.push(node)

    let preStep = mode.direction === 'DOWN'
    if (mode?.reverse) {
        preStep = !preStep
    }
    if (preStep) {
        onStep(node, path.toJSON().reverse(), stack.toJSON().reverse())
    }

    if (isArray(node)) {
        const children = mode.reverse ? [...node].reverse() : node
        let i = 0
        for (const e of children) {
            path.push(i++)
            walk({...walkStep, node: e})
            path.pop()
        }

    } else if (isObject(node)) {
        type KeyType = keyof typeof node

        const keys = Object.keys(node) as KeyType[]
        if (mode.reverse) {
            keys.reverse()
        }

        for (const key of keys) {
            path.push(key)
            walk({...walkStep, node: node[key]})
            path.pop()
        }
    }
    if (!preStep) {
        onStep(node, path.toJSON().reverse(), stack.toJSON().reverse())
    }
    stack.pop()
}





