import { classList } from '@punnet/pure-utility-kit'
import type { JSX, ParentComponent } from 'solid-js'
import styles from './Buttons.module.css'

type ButtonProps = JSX.ButtonHTMLAttributes<HTMLButtonElement>


// default button - solid
export const Button: ParentComponent<ButtonProps> = (props) => {
    return <button {...getProps(props, styles.Button)} >{props.children}</button>
}

// outline button - transparent background
export const OutlineButton: ParentComponent<ButtonProps> = (props) => {
    return <Button {...getProps(props, styles.Outline)} >{props.children}</Button>
}

// Green outline button - transparent background, green text/border (outline)
export const GreenOutlineButton: ParentComponent<ButtonProps> = (props) => {
    return <OutlineButton {...getProps(props, styles.OutlineGreen)} >{props.children}</OutlineButton>
}

export const GreenButton: ParentComponent<ButtonProps> = (props) => {
    return <Button {...getProps(props, styles.Green)} >{props.children}</Button>
}

// solid button - blue background, white text
export const PrimaryButton: ParentComponent<ButtonProps> = (props) => {
    return <Button {...getProps(props, styles.Primary)} >{props.children}</Button>
}

// secondary button - transparent background, blue text/border (outline)
export const SecondaryButton: ParentComponent<ButtonProps> = (props) => {
    return <OutlineButton {...getProps(props, styles.Secondary)} >{props.children}</OutlineButton>
}

// grey button - grey background/border, blue text
export const GreyButton: ParentComponent<ButtonProps> = (props) => {
    return <Button {...getProps(props, styles.Grey)} >{props.children}</Button>
}

// green button - green background, white text
export const QuoteFooterButton: ParentComponent<ButtonProps> = (props) => {
    return <Button {...getProps(props, styles.QuoteFooter)} >{props.children}</Button>
}

type FileReaderButtonProps = ButtonProps & {
    onFileRead: (data: string) => void
    accept?: string
}

//TODO: this Buttons file is about look and feel of buttons, not functionality - move elsewhere
export function FileReaderButton(props: FileReaderButtonProps) {
    let inputRef: HTMLInputElement

    const readFile = event => {
        const [file] = event.target.files
        const reader = new FileReader()
        reader.onload = event => props.onFileRead(event.target.result.toString())
        reader.readAsText(file)
    }

    // TODO-CC Using the default styles (green outline and text, lighter gren background)
    return (
        <>
            <GreenButton {...props} onClick={() => inputRef.click()}>{props.children}</GreenButton>
            <input ref={inputRef} type="file" onChange={readFile} accept={props.accept} hidden />
        </>
    )
}

const getProps = (props: ButtonProps, className: string) => {
    return {
        ...props,
        class: classList(className, props.class)
    }
}