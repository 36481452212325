import {removeUndefinedsFrom} from '@peachy/utility-kit-pure'
import {GeoLocation} from './types'

export class Address {

    constructor(
        readonly line1: string,
        readonly line2: string | undefined,
        readonly city: string,
        readonly county: string | undefined,
        readonly postcode: string,
        readonly country: string | undefined,
        readonly geolocation: GeoLocation | undefined) {
    }

    toFormattedString(separator: string = '\n') {
        return this.asList().join(separator)
    }

    asList() {
        return removeUndefinedsFrom([
            this.line1,
            this.line2,
            this.city,
            this.county,
            this.postcode
        ])
    }
}
