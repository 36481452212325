import { classList } from '@punnet/pure-utility-kit'
import { For, type ParentComponent } from 'solid-js'
import styles from './ListTable.module.css'


export type ListTableProps = {
    headers: string[]
    tableClass?: string
}

export const ListTable: ParentComponent<ListTableProps> = (props) => {

    return (
        <table class={classList(styles.ListTable, props.tableClass)}>
            <thead>
                <tr class={styles.Header}>
                    <For each={props.headers}>
                        {(header) => <th>{header}</th>}
                    </For>
                </tr>
            </thead>

            <tbody>
                {props.children}
            </tbody>
        </table>
    )
}
