import {GeoLocation} from './GeoLocation'
import {UkRegion} from './uk-regions'

export class Address {
    display: string
    building: string[]
    settlement: string[]
    county: string
    country: string
    region: UkRegion
    postcode: string
    location: GeoLocation
}

