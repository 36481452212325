import { PropertiesOnly } from '@peachy/utility-kit-pure'
import { assign } from 'lodash-es'

export class PlanUpgrade {
    readonly dateUpgraded: Date
    readonly fromPackagedPlanId?: string
    readonly toPackagedPlanId: string
    readonly premiumUpliftInPence: number

    constructor(props: PropertiesOnly<PlanUpgrade>) {
        assign(this, props)
    }
}