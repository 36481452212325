import {keyLookup} from '@punnet/pure-utility-kit'
import {Type} from 'class-transformer'
import {Address} from './geo-location/Address'

export type CompanySource =
    | 'COMPANIES_HOUSE'
    | 'CHARITY_COMMISSION'

export const CompanySources = keyLookup<CompanySource>()

export class Company {
    id: string
    source: CompanySource
    name: string

    @Type(() => Address)
    address: Address

    isCharity?() {
        return this.source === CompanySources.CHARITY_COMMISSION
    }

    intercomCompanyId?: string
}
