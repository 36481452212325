import {RepoHash, StringHash} from '../../primatives/hash-primatives'
import {concatMap, from, lastValueFrom, map, Observable, of, share} from 'rxjs'
import {Page} from '../../primatives/page-primatives'
import {
    assertNotNullish,
    Bookmark,
    compareBookmarks,
    displayableId,
    getTimelineBookmarkProvider,
    last,
    newUUID,
    ZERO_BOOKMARK
} from '@punnet/pure-utility-kit'
import {
    BookmarkedPage,
    IPageIo,
    PageInfo,
    PageReceipt,
    PageReceiptStream,
    PageSyncState
} from '../../key-interfaces/IPageIo'
import {MultiMap} from 'mnemonist'
import {InstallationId} from '../../primatives/repo-primatives'
import equal from 'fast-deep-equal/es6'
import { RxOps } from '@punnet/rxjs-utility-kit'



export class PageIoMemory implements IPageIo {

    private pages = new Map<StringHash, Page>()

    private bookmarkedPages: BookmarkedPage[] = []
    private bookmarkProvider = getTimelineBookmarkProvider()
    private pageSyncStates = new MultiMap<InstallationId, PageSyncState>()

    constructor(public id: string = displayableId(newUUID())) {
    }

    filterForUnknownPageHashes(repoHash$: Observable<RepoHash>): Observable<RepoHash> {

        const sharedRepoHash$ = repoHash$.pipe(share())

        const existence$ = sharedRepoHash$.pipe(
            concatMap(async repoHash => this.hasPage(repoHash))
        ).pipe(
            map(exists => !exists)
        )
        return RxOps.zipFilter(sharedRepoHash$, existence$)
    }

    filterForUnknownPages(pageInfo$: Observable<PageInfo>): Observable<PageInfo> {

        const sharedPageInfo$ = pageInfo$.pipe(share())

        const existence$ = sharedPageInfo$.pipe(
            concatMap(async pageInfo => this.hasPage(pageInfo.repoHash))
        ).pipe(
            map(exists => !exists)
        )
        return RxOps.zipFilter(sharedPageInfo$, existence$)
    }

    commitPageInfoStream(
        pageInfo$: Observable<PageInfo>,
        skipExistenceCheck?: boolean
    ): PageReceiptStream {

        if (!skipExistenceCheck) {
            pageInfo$ = this.filterForUnknownPages(pageInfo$)
        }
        return pageInfo$.pipe(
            concatMap(async pageInfo => {

                this.pages.set(pageInfo.repoHash.hash, pageInfo.page)
                const bookmarkedPage: BookmarkedPage = {
                    bookmark: this.bookmarkProvider(),
                    repoHash: pageInfo.repoHash
                }
                this.bookmarkedPages.push(bookmarkedPage)
                return {
                    ...bookmarkedPage,
                    page: pageInfo.page
                }
            })
        )
    }

    fetchPageInfoStream(
        repoHash$: Observable<RepoHash>,
    ): Observable<PageInfo> {
        return repoHash$.pipe(
            concatMap(async repoHash => {
                return {
                    repoHash: repoHash,
                    page: this.pages.get(repoHash.hash)
                }
            })
        )
    }

    async commitPage(page: Page): Promise<PageReceipt> {
        const repoHash = RepoHash.from(page)
        return lastValueFrom(this.commitPageInfoStream(of({repoHash, page})), {
            defaultValue: {
                repoHash,
                page: page,
                bookmark: null,
            }
        })
    }


    async hasPage(pageHash: RepoHash) {
        return this.pages.has(pageHash.hash)
    }

    async fetchPage(pageHash: RepoHash): Promise<PageInfo> {
        assertNotNullish(pageHash)
        const page = this.pages.get(pageHash.hash)
        return page ? {
            repoHash: pageHash,
            page: page
        } : undefined
    }

    fetchPageKeysSince(bookmark: Bookmark = ZERO_BOOKMARK): Observable<BookmarkedPage> {
        return from(this.bookmarkedPages.filter(bookmarked =>
            compareBookmarks(bookmarked.bookmark, bookmark) > 0
        ))
    }


    async fetchBookmark(): Promise<Bookmark> {
        return last(this.bookmarkedPages)?.bookmark
    }

    async fetchPageSyncState(remoteInstallationId: InstallationId): Promise<PageSyncState> {
        return last(this.pageSyncStates.get(remoteInstallationId)) ?? {
            localBookmark: ZERO_BOOKMARK,
            remoteBookmark: ZERO_BOOKMARK,
        }
    }

    async commitPageSyncState(remoteInstallationId: InstallationId, pageSyncState: PageSyncState, expectedState?: PageSyncState): Promise<PageSyncState> {
        const currentState = await this.fetchPageSyncState(remoteInstallationId)
        if (expectedState && !equal(expectedState, currentState)) {
            throw `Cannot store pageSyncState ${pageSyncState}. Expected ${expectedState}, but got ${currentState}`
        }
        pageSyncState.remoteBookmark ??= ZERO_BOOKMARK
        pageSyncState.localBookmark ??= ZERO_BOOKMARK

        if (!equal(pageSyncState, currentState)) {
            this.pageSyncStates.set(remoteInstallationId, pageSyncState)
        }
        return pageSyncState
    }


    dumpAll() {
        console.log(`PageIoMemory ${this.id}`, [...this.pages.entries()])
    }
}
