import { Type } from 'class-transformer'
import { Address } from './geo-location/Address'
import { Required } from '@punnet/model-validation-pure'

export class PaymentDetails {
    name?: string
    email?: string
    stripePaymentId?: string
    hasSeparateBillingAddress?: boolean = false

    @Type(() => Address)
    @Required<PaymentDetails>('', ({ instance }) => instance.hasSeparateBillingAddress)
    billingAddress?: Address = undefined

    promoCode?: string
}


