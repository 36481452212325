
import { toClass } from '@punnet/model-validation-pure'
import { values } from '@punnet/pure-utility-kit'
import { Policy, isActive, type Company, type Life, type QuoteSummaryRecord, type SubscriptionQuoteResponse } from '@punnet/subscription-pure'


export function quoteToQuoteSummaryRecord(
    quoteId: string,
    quote: SubscriptionQuoteResponse,
    quoteExpiryInSeconds: number
): QuoteSummaryRecord {

    const quoteSummary: Partial<QuoteSummaryRecord> = {
        quoteId: quoteId,
        quoteDate: quote.quoteResponseTimestamp,
        startDate: quote.subscription.startDate,
        expiryDate: (quote.quoteResponseTimestamp ?? Date.now()) + (quoteExpiryInSeconds * 1000),
        premium: quote.subscription.totalMonthlyPremium,
        members: getMemberCount(quote)
    }

    if (quote.account.type === 'COMPANY') {
        const policyHolder = getCompanyPolicyHolder(quote)
        quoteSummary.policyHolder = policyHolder.name
        quoteSummary.postcode = policyHolder.address.postcode
    } else {
        const policyHolder = getIndividualPolicyHolder(quote)
        quoteSummary.policyHolder = policyHolder.fullName()
        quoteSummary.postcode = policyHolder.address.postcode
    }

    return quoteSummary as QuoteSummaryRecord
}


function getMemberCount(quote: SubscriptionQuoteResponse): number {
    return quote.policies.flatMap(policy => values(policy.lives)).filter(isActive).length   
}



function getIndividualPolicyHolder(quote: SubscriptionQuoteResponse): Life {
    if (quote.policies.length !== 1) {
        throw new Error('Individual Quote must have a single policy to store quote summary')
    }
    const policy = toClass(quote.policies[0], Policy)
    return policy.getPrimaryLife()
}

function getCompanyPolicyHolder(quote: SubscriptionQuoteResponse): Company {
    if (!quote.account.company) {
        throw new Error('Company quote must have a Account.Company set')
    }
    return quote.account.company
}