import { formatISO } from 'date-fns'
import { format } from 'date-fns-tz'
import xss from 'xss'
import { isArray, isNumber, isObject, isString } from './type-check-kit'
import { isInteger } from 'mathjs'
const DEFAULT_INDENT = 2

export type JsonReviver = (k: string | number, value: unknown) => unknown
export const toJson = (v: unknown, indent?: number) => JSON.stringify(v, null, indent)
export const fromJson = <T>(s: unknown, reviver?:JsonReviver): T => typeof s === 'string' ? JSON.parse(s, reviver) : undefined

export function toObjectIfPossible(thing: any, otherwise?: any) {
    let resolvedContent = otherwise ?? thing
    if (looksLikeJsonString(thing)) {
        try {
            resolvedContent = JSON.parse(thing)
        } catch (e) {
            // do nothing
        }
    }
    return resolvedContent
}

export function looksLikeJsonString(content: any): content is string {
    return (
        isString(content) &&
        (content.trim().startsWith('[') || content.trim().startsWith('{'))
    )
}

export function stringifyIfItCanBe(thing: any, indent?: number|string) {
    return (isObject(thing) || isArray(thing)) ? JSON.stringify(thing, null, indent) : thing
}

export function cleanParseJson<T>(json: string): T {
    // sanitize
    return JSON.parse(xss(json))
}

export function toCleanJson(thing: any): string {
    return xss(JSON.stringify(thing))
}

export function sanitize<T>(thing: T): T {
    return JSON.parse(xss(JSON.stringify(thing)))
}

export function safeStringify(thing: any, indent = DEFAULT_INDENT) {
    try {
        return stringifyIfItCanBe(thing, indent)
    } catch (e) {
        const justTheKeysThen = Object.keys(thing)
        return `[KEYS ONLY] ${justTheKeysThen}`
    }
}









export function looksLikeTimestamp(v: any): v is number {
    return v && isNumber(v) && isInteger(v) && (v.toString().length == 12 || v.toString().length == 13)
}


export function toJsonWithDateFormat(o: any, formatString?: string) {
    return JSON.stringify(o, (key, value) => {
        if (looksLikeTimestamp(value)) {
            if (formatString) {
                return format(value, formatString)
            }
            return formatISO(value)
        }
        return value
    }, 2)
}

export function readableDateOnly(date: number) {
    return format(date, 'dd <</yyyy')
}



export function dumpWithReadableDates(o: any) {
    console.log(toJsonWithReadableDates(o))
}


export function toJsonWithReadableDates(o: any) {
    return toJsonWithDateFormat(o, 'dd/MM/yyyy HH:mm')
}


