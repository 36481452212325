import {identity, isEqual} from 'lodash-es'
import {instanceToPlain, plainToInstance, Transform, Type} from 'class-transformer'
import 'reflect-metadata'

import {QuestionAnswer, QuestionPresentation, QuestionPresentations, QuestionType, QuestionTypes} from './answer/types'
import {QuestionOptionsDefinition} from './QuestionOptionsDefinition'
import {QuestionValidation} from './QuestionValidation'
import {ModalDefinition} from './types'
import {answerIsMedia, Media} from './answer/Media'
import {QuestionOption} from './QuestionOption'
import {DomainMappings} from '../../mappings/DomainMappings'
import {BankDetails} from './answer/BankDetails'
import {Dictionary} from '@peachy/utility-kit-pure'

export class Question {
    readonly id: string
    readonly type: QuestionType
    readonly presentation: QuestionPresentation

    @Type(() => QuestionOptionsDefinition)
    readonly optionsDefinition?: QuestionOptionsDefinition

    readonly text: string
    readonly helpText?: string
    readonly helpHeading?: string
    public dataModel: Dictionary<string> = {}

    @Transform(({value, obj}) => getQuestionValidationInstanceToPlainTransformerFor(obj as Question)(value), { toPlainOnly: true })
    @Transform(({value, obj}) => getQuestionValidationPlainToInstanceTransformerFor(obj as Question)(value), { toClassOnly: true })
    readonly validation: QuestionValidation

    readonly tags: string[]
    readonly modals: ModalDefinition[]
    satisfied: boolean
    required: boolean = true

    @Transform(({value, obj}) => getQuestionAnswerPlainToInstanceTransformerFor(obj as Question)(value), { toClassOnly: true })
    answers: QuestionAnswer[] = []

    constructor(props: {
        id: string,
        type: QuestionType,
        presentation: QuestionPresentation,
        optionsDefinition?: QuestionOptionsDefinition,
        text: string,
        helpText?: string,
        helpHeading?: string,
        validation: QuestionValidation,
        tags?: string[],
        modals?: ModalDefinition[],
        satisfied?: boolean
    }) {
        this.id = props?.id
        this.type = props?.type
        this.presentation = props?.presentation
        this.optionsDefinition = props?.optionsDefinition
        this.text = props?.text
        this.helpText = props?.helpText
        this.helpHeading = props?.helpHeading
        this.validation = props?.validation
        this.tags = props?.tags || []
        this.modals = props?.modals || []
        this.satisfied = props?.satisfied || false
    }

    satisfyWith(answers: QuestionAnswer[]) {
        this.answers = answers
        this.satisfied = answers.length > 0
        return this
    }

    getFirstAnswer<T extends QuestionAnswer>() {
        return (this.answers.length > 0 ? this.answers[0] : undefined) as T
    }

    getMediaAnswers() {
        return this.answers.filter(answerIsMedia) as Media []
    }

    hasAnswer(answer: QuestionAnswer) {
        const matchOnId = this.isOptionBacked()
        return this.answers.some(it => matchOnId ? (it as QuestionOption).id === (answer as QuestionOption).id : isEqual(it, answer))
    }

    hasAnyAnswer() {
        return this.answers.length > 0
    }

    setSatisfied(value: boolean) {
        this.satisfied = value
        return this
    }

    setRequired(value: boolean) {
        this.required = value
        return this
    }

    hasTag(tag: string) {
        return this.tags.includes(tag)
    }

    isFullScreenPresentation() {
        const presentations: QuestionPresentation[] = [QuestionPresentations.CAMERA, QuestionPresentations.CAMERA_AND_FILE_PICKER]
        return presentations.includes(this.presentation)
    }

    isOptionBacked() {
        return this.type == QuestionTypes.OPTION
    }

    get numberOfEmbeddedOptions() {
        return (this.optionsDefinition?.embeddedOptions ?? []).length
    }
}

//QUESTION ANSWER TRANSFORMERS
const questionAnswerPlainToInstanceTransformerByQuestionType: Dictionary<(answers: any[]) => any> = {
    [QuestionTypes.OPTION]: (answers: any[]) => answers.map(it => plainToInstance(QuestionOption, it)),
    // [QuestionTypes.DATE_DAY_MONTH_YEAR]: undefined, //should fix how we're storing this type of answer
    [QuestionTypes.BANK_DETAILS]: (answers: any[]) => answers.map(it => BankDetails.isBankDetails(it) ? plainToInstance(BankDetails, it) : plainToInstance(QuestionOption, it)),
    [QuestionTypes.VIDEO]: (answers: any[]) => answers.map(it => Media.fromJson(it)),
    [QuestionTypes.IMAGE]: (answers: any[]) => answers.map(it => Media.fromJson(it)),
    [QuestionTypes.SIGNATURE]: (answers: any[]) => answers.map(it => Media.fromJson(it)),
}
function getQuestionAnswerPlainToInstanceTransformerFor(question: Question) {
    const specialTransformer = questionAnswerPlainToInstanceTransformerByQuestionType[question.type]
    return specialTransformer ?? identity
}

//QUESTION VALIDATION TRANSFORMERS
const dateQuestionValidationPlainToInstanceTransformer = (plainObject: {minAccepted?: string, maxAccepted?: string}) => {
    const plainObjectWithParsedDates = {
        ...plainObject,
        minAccepted: DomainMappings.fromRepo.toDate(plainObject.minAccepted),
        maxAccepted: DomainMappings.fromRepo.toDate(plainObject.maxAccepted)
    }
    return plainToInstance(QuestionValidation, plainObjectWithParsedDates)
}
const questionValidationPlainToInstanceTransformerByQuestionType: Dictionary<typeof dateQuestionValidationPlainToInstanceTransformer> = {
    [QuestionTypes.DATE_DAY_MONTH_YEAR]: dateQuestionValidationPlainToInstanceTransformer,
    [QuestionTypes.DATE_MONTH_YEAR]: dateQuestionValidationPlainToInstanceTransformer,
}
function getQuestionValidationPlainToInstanceTransformerFor(question: Question) {
    const specialTransformer = questionValidationPlainToInstanceTransformerByQuestionType[question.type]
    return specialTransformer ?? ((plain: any) => plainToInstance(QuestionValidation, plain))
}

const dateQuestionValidationInstanceToPlainTransformer = (instance: QuestionValidation) => {
    const plain = instanceToPlain(instance)
    return {
        ...plain,
        maxAccepted: DomainMappings.fromDomain.toRepoDate(plain.maxAccepted),
        minAccepted: DomainMappings.fromDomain.toRepoDate(plain.minAccepted)
    }
}
const questionValidationInstanceToPlainTransformerByQuestionType: Dictionary<typeof dateQuestionValidationInstanceToPlainTransformer> = {
    [QuestionTypes.DATE_DAY_MONTH_YEAR]: dateQuestionValidationInstanceToPlainTransformer,
    [QuestionTypes.DATE_MONTH_YEAR]: dateQuestionValidationInstanceToPlainTransformer,
}
function getQuestionValidationInstanceToPlainTransformerFor(question: Question) {
    const specialTransformer = questionValidationInstanceToPlainTransformerByQuestionType[question.type]
    return specialTransformer ?? ((instance: QuestionValidation) => instanceToPlain(instance))
}