import { useIamService } from '@punnet/iam-client'
import { Plan, Policy, Subscription, AccountSubscriptionDto, SubscriptionQuoteResponse, type SubscriptionStackApi } from '@punnet/subscription-pure'
import { useSubscriptionService } from '@punnet/subscription-web-client'
import { Show, createContext, createEffect, createMemo, createResource, useContext, type ParentComponent } from 'solid-js'
import { Pulse } from '../../components/Pulse/Pulse'
import { getPermissions } from './permissions/PermissionsController'
import { Permissions } from './permissions/Permissions'
import { toClass } from '@punnet/model-validation-pure'
import { values } from 'lodash-es'
import { untrack } from '@punnet/web-client-kit-library'


type AccountData = {
    accountSubscription?: AccountSubscriptionDto
    latestQuote?: SubscriptionQuoteResponse
}

const AccountContext = createContext<AccountData>()


async function loadLatestQuote(
    permissions: Permissions,
    subscriptionService: SubscriptionStackApi
) {
    
    if (permissions.canPurchaseQuote()) {
        try {
            const quoteSummaries = await subscriptionService.fetchQuoteSummaries()
            if (quoteSummaries.length > 0) {
                const latestQuoteSummary = quoteSummaries.sort((a, b) => b.quoteDate - a.quoteDate)[0]
                const latestQuote = await subscriptionService.loadSmeDraftQuote({ quoteId: latestQuoteSummary.quoteId })
                return latestQuote
            } else {
                return null
            }

        } catch (error) {
            console.log('error loading latest quote', error)
        }
    }
}


async function loadAccountData() {
    const permissions = getPermissions()
    const subscriptionService = useSubscriptionService()

    // TODO: at the moment, we only load the account data for non-broker users - so just use this permission for now but we should probably change this
    if (!permissions.canPurchaseQuote()) {
        return {}
    }

    try {
        const latestQuote = await loadLatestQuote(permissions, subscriptionService)
        if (latestQuote) {
            return { latestQuote }
        }
    } catch (error) {
        console.log('loadAccountData - no latest quote')
        //expected if no draft quote exists
    }

    try {
        const accountSubscription = await subscriptionService.loadActiveSmeSubscription()
        return { accountSubscription }
    } catch (error) {
        console.log('loadAccountData - no active subscription')
        // expected if no active subscription exists
    }


    return {}
}

export const AccountProvider: ParentComponent = (props) => {
    const iamService = useIamService()

    const [accountData, { mutate: setAccountData }] = createResource(() => iamService.isSignedIn(), loadAccountData)

    createEffect(() => {
        if (!iamService.isSignedIn()) {
            setAccountData()
        }
    })

    return <>
        <Show when={!accountData.loading} fallback={<Pulse isShown={true} />}>
            <AccountContext.Provider value={accountData()}>
                {props.children}
            </AccountContext.Provider>
        </Show>
    </>
}

const getAccountContext = () => useContext(AccountContext)

export function getActiveSubscription() {
    const accountData = getAccountContext()
    return accountData?.accountSubscription
}

// exported subscription functions

export function hasActiveSubscription() {
    return !!getActiveSubscription()
}

export function getCurrentPolicies() {
    return values(getActiveSubscription()?.subscription.policies).map(p => toClass(p, Policy))
}

export function getCurrentPolicyForId(id: string) {
    return getActiveSubscription()?.subscription.policies[id]
}

export function getCurrentPlans() {
    return values(getActiveSubscription()?.subscription.plans).map(p => toClass(p, Plan))
}

export function getCurrentTotalMonthlyPremium() {
    return getActiveSubscription()?.subscription.totalMonthlyPremium
}

export function getCurrentCompany() {
    return getActiveSubscription()?.account.company
}

export function getCurrentPolicyNumber() {
    const subscription = createMemo(() => toClass(getActiveSubscription()?.subscription, Subscription))
    return subscription()?.referenceNumber()
}

export function getCurrentSubscriptionStartDate() {
    return getActiveSubscription()?.subscription.startDate
}

// TODO: fix - this is the same as getActiveSubscription
export function getActiveAccount() {
    const account = getAccountContext()?.accountSubscription
    return untrack(account)
}

// exported quote functions

export function getLatestQuote() {
    const accountData = getAccountContext()
    return accountData?.latestQuote
}

export function hasLatestQuote() {
    return !!getLatestQuote()
}
