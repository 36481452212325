import { SubscriptionQuoteLoader } from '../SubscriptionQuoteLoader'
import { DirectDebitDetails } from '../stp/DirectDebitDetails'
import type { Component } from 'solid-js'
import styles from '../../Section.module.css'

export const DirectDebit: Component = () => {
    return <>
        <SubscriptionQuoteLoader>
            <div class={styles.StripeySections}>
                <DirectDebitDetails/>
            </div>
        </SubscriptionQuoteLoader>
    </>
}