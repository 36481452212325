import { createContext, useContext, type ParentProps } from 'solid-js'

import { makeApiGatewayClient } from '@peachy/core-domain-client'
import { SubscriptionStackApiDefinition, type SubscriptionStackApi } from '@punnet/subscription-pure'

import { API } from '@aws-amplify/api'
import { Auth } from '@aws-amplify/auth'


const SubscriptionControllerContext = createContext<SubscriptionStackApi>()

export function SubscriptionController(props: ParentProps) {

    const subscriptionApi = makeApiGatewayClient(SubscriptionStackApiDefinition, API, Auth)

    return (
        <SubscriptionControllerContext.Provider value={subscriptionApi}>
            {props.children}
        </SubscriptionControllerContext.Provider>
    )
}


export function useSubscriptionService() {
    return useContext(SubscriptionControllerContext)
}

