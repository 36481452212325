import { useIamService } from '../IamController'
import { IamError } from '../IamError'



export function SignOut() {
    const iamService = useIamService()

    const signOut = () => iamService.signOut()

    return <>
        <h3>Sign Out</h3>
        <IamError/>
        <button onClick={signOut} >Sign out</button>
    </>
}

