import { mapToPlanBenefit, type Benefit } from '@punnet/product-client'
import { Plan } from '@punnet/subscription-pure'

const removeBenefitFromPlan = (plan: Plan, benefit: Benefit) => {
    mapToPlanBenefit(benefit).forEach(planBenefit => {
        delete plan.benefits[planBenefit.id]
    })
}

export function removeBenefit (plans: Plan[], benefit: Benefit, planId?: string) {
    // global benefit
    if (!planId) {
        plans.forEach(plan => removeBenefitFromPlan(plan, benefit))
   } else {
       removeBenefitFromPlan(plans.find(p => p.id === planId), benefit)
   }
}