import {useIamService} from './IamController'
import {Show} from 'solid-js'


export function IamError() {
    const iamService = useIamService()
    return (
        <Show when={!!iamService.error()}>
            <mark>{iamService.error()}</mark>
        </Show>
    )
}