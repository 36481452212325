import { displayableId, getNextAnniversaryOf, getNextPaymentDateAfter, keysOf, newUUID } from '@peachy/utility-kit-pure'
import { Type } from 'class-transformer'
import { startOfToday } from 'date-fns'
import Required from '../validation/validators/Required'
import { PaymentDetails } from './PaymentDetails'

export type SubscriptionStatus =
    | 'DRAFT'
    | 'ACTIVE'
    | 'OVERDUE'
    | 'SUSPENDED'
    | 'CANCELLED'

export const SubscriptionStatuses = keysOf<SubscriptionStatus>()


export class DraftSubscription {
    @Required()
    id: string
    @Required()
    name: string

    @Required()
    contactName: string

    @Required()
    contactEmail: string

    @Required()
    startDate: number
    @Type(() => PaymentDetails)
    paymentDetails?: PaymentDetails

    referenceNumber?(): string {
        return displayableId(this.id)
    }

    getNextPaymentDate?(): number {
        return getNextPaymentDateAfter(new Date(this.startDate), startOfToday()).getTime()
    }

    // TODO requirement about exactly what date should be
    getAnnualRenewalDate?(): Date {
        return getNextAnniversaryOf(new Date(this.startDate))
    }
}

export class Subscription extends DraftSubscription {
    stripeSubscriptionId?: string
    stripeProductId?: string
    status: SubscriptionStatus
    totalMonthlyPremium?: number
    planIds?: string[] = []
    policyIds?: string[] = []
    paymentIds?: string[] = []
    cancelledAt?: number
    cancellationReason?: string
}


// repeatable uuid
export function generateSubscriptionId(startDate: number, contactEmail: string) {
    return newUUID('subscription' + startDate + contactEmail)
}
