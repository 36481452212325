import {Observable, tap} from 'rxjs'
import {asDoc} from '@punnet/pure-utility-kit'


export function tapLog<T>(blurb: string = '', isLogging = true) {
    return (o: Observable<T>): Observable<T> => {
        return isLogging ? o.pipe(
            tap({
                next: (x: T) => {
                    console.log(blurb, 'Next', asDoc(x))
                },
                error: (e) => {
                    console.log(blurb, 'Error', e)
                },
                complete: () => {
                    console.log(blurb, 'Complete')
                },
            })
        ) : o
    }
}
