import { type ParentComponent } from 'solid-js'
import styles from './Quote.module.css'
import { QuoteController } from './QuoteController/QuoteController'
import type { SubscriptionQuoteRequest, SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import type { SubscribeFunction } from '../../services/quote/createSubscription'

export const Title: ParentComponent<{}> = (props) => {
    return <h2>{props.children}</h2>
}

export const Label: ParentComponent<{}> = (props) => {
    return <h3>{props.children}</h3>
}

type QuoteProps = {
    subscribe: SubscribeFunction<SubscriptionQuoteRequest>
    onQuoteResponse?: (response: SubscriptionQuoteResponse) => void
}

export const Quote: ParentComponent<QuoteProps> = (props) => {
    return (
        <QuoteController onQuoteResponse={props.onQuoteResponse} subscribe={props.subscribe}>
            <div class={styles.Quote}>
                {props.children}
            </div>
        </QuoteController>
    )
}