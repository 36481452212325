type Unit = 'B'| 'KB' | 'MB'

export const calculateBytes = (baseByte: number, unit: Unit) => {

    const multiplier = () => {
        switch (unit) {
            case 'KB': return 1024
            case 'MB': return Math.pow(1024, 2)
            case 'B':
            default: return 1
        }
    }

    return baseByte * multiplier()
}