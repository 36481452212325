import { AnEvent } from '@punnet/core-event-pure'
import { Properties } from '@punnet/pure-utility-kit'


export class IdCheckRequest extends AnEvent {
    constructor(from: Properties<IdCheckRequest>) {
        super()
        Object.assign(this, from)
    }
    firstname: string
    lastname: string
    birthdate: number
    selfie: string
    photoId: string
    email?: string
    cognitoId: string
    webhook: string
}

