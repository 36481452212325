import { Address } from '@punnet/subscription-pure'
import { createToggleSignal } from '@punnet/web-client-kit-library'
import { type Component } from 'solid-js'
import { useManageLifeValidation, useManageTooltipMessages, useManageStoreActions } from '../../../../app/store/quotes/manageEmployeesStore'
import { SovereignAddressLookup } from '../../../lookup/SovereignAddressLookup'
import { type LifeRowCellProps } from '../ManageEmployeesTable'

export const AddressCell: Component<LifeRowCellProps> = (props) => {
    const { updateLife } = useManageStoreActions()

    const initialAddress = () => props.life?.address?.display ?? ''

    const { address } = useManageLifeValidation()
    const { addressTooltip } = useManageTooltipMessages()
    const isValid = () => address(props.life)
    const [isFocus, setIsFocus] = createToggleSignal(false)

    const onSelectAddress = (address: Address) => {
        updateLife(props.policy.id, {
            ...props.life,
            address: address,
            postcode: address.postcode
        })
    }

    const onBlur = (value: string) => {
        if (!value) {
            updateLife(props.policy.id, {
                ...props.life,
                address: undefined,
                postcode: undefined
            })
        }
        setIsFocus()
    }

    return <td title={isValid() ? '' : addressTooltip()}>
        <SovereignAddressLookup
            initialValue={initialAddress()}
            onSelect={onSelectAddress}
            onFocus={(v: string) => setIsFocus()}
            onBlur={onBlur}
            lookupInputClass={isFocus() || isValid() ? '' : props.errorStyles}
        />
    </td>
}
