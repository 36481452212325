
export abstract class AnEvent {
    public type?: string
    public id?: string
    public source?: string
    public sourceId?: string
    public time?: number

    protected constructor() {
        this.type = this.constructor.name
    }
}




export type Fires<E extends AnEvent> = (e: E) => void
export type EventHandler<E extends AnEvent, F extends Fires<any>> = (e: E, fire?: F) => Promise<void>


export function redisEventKey(eventId: string) {
    return `core-event/${eventId}`
}