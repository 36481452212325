import type { SubscriptionQuoteRequest } from '@punnet/subscription-pure'
import { onMount, type ParentComponent } from 'solid-js'
import { createSubscription } from '../../../services/quote/createSubscription'
import { mapToSubscriptionRequest } from '../../../store/quotes/mapToSubscriptionRequest'
import { subscribeToStore, useStoreActions, type QuoteAndBuyState } from '../../../store/quotes/quoteAndBuyStore'
import { SubscriptionQuoteLoader, useSavedSubscriptionQuote } from '../PurchaseQuote/SubscriptionQuoteLoader'
import { Quote } from '../Quote'
import { useQuoteResponse } from '../QuoteController/QuoteController'
import styles from './NewQuote.module.css'

export const EditQuote: ParentComponent = (props) => {
    return (
        <SubscriptionQuoteLoader>
            <Content {...props} />
        </SubscriptionQuoteLoader>
    )
}

const Content: ParentComponent = (props) => {
    onMount(() => {
        useStoreActions.requestQuote()
    })
    
    const quoteResponse = useQuoteResponse()
    const savedQuote = useSavedSubscriptionQuote()

    const quoteSubscription = createSubscription<QuoteAndBuyState, SubscriptionQuoteRequest>(
        subscribeToStore,
        (state, prevState) => state.quoteRequestId !== prevState.quoteRequestId || !quoteResponse(),
        (state) => mapToSubscriptionRequest(state, quoteResponse() ?? savedQuote) as SubscriptionQuoteRequest
    )
    return (
        <div class={styles.NewQuote}>
            <Quote subscribe={quoteSubscription}>
                {props.children}
            </Quote>
        </div>
    )
}
