import {ClassConstructor, instanceToPlain, plainToInstance} from 'class-transformer'
import {Dictionary} from '@peachy/utility-kit-pure'

export class QuestionOption {

    public metadata?: any

    constructor(readonly id: string, readonly text: string, readonly label?: string) {
    }

    toString() {
        return this.text
    }

    withMetadata(metadata: Dictionary<any>) {
        this.metadata = instanceToPlain(metadata)
        return this
    }

    getInMetadata<T>(key: string, asClass?: ClassConstructor<T>): T | undefined {
        const item = this.metadata?.[key]
        if (item) {
            return asClass ? plainToInstance(asClass, item) : item
        }
    }
}