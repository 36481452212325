
export type ServiceProviderFunction<T> = (...args: any[]) => T

export function createServiceProvider<const SP extends ServiceProviderFunction<any>>(
    defaultProvider: SP
) {
    let currentProvider = defaultProvider

    return {
        get currentProvider() {     
            return currentProvider
        },
        overrideProvider(provider: SP) {
            currentProvider = provider
        },
        resetProvider() {
            currentProvider = defaultProvider
        },
        get provide() {
            return currentProvider
        }
    }
}
