export * from './src/CompanyLookup'

        import * as me from '@punnet/company-lookup-web-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/charlie-gould/git/peachy-repo/punnet/stacks/company-lookup/web-client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@punnet/company-lookup-web-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    