import { AnEvent } from '@punnet/core-event-pure'

import {Life} from '../../domain/models/Life'


export type AddLifeRequestType = {
    accountId: string
    subscriptionId: string
    policyId: string
    life: Life
}


export class AddLifeRequest extends AnEvent implements AddLifeRequestType {
    accountId: string
    subscriptionId: string
    policyId: string

    life: Life

    constructor(from: AddLifeRequestType) {
        super()
        Object.assign(this, from)
    }
}
