import {Type} from 'class-transformer'
import {KeyMapped} from '@punnet/pure-utility-kit'
import {Plan} from '../Plan'
import {Policy} from '../Policy'
import {PlanChange} from './PlanChange'
import {toClass} from '@punnet/model-validation-pure'

export class PolicyDocData {
    @Type(() => Policy)
    policy: Policy
    changes?: PlanChange[]

    plans: KeyMapped<Plan>
    domain: string
    isSample: boolean
    subscriptionStartDate?: number
    isAlteration: boolean = false

    getPrimaryLife() {
        return this.policy.getPrimaryLife()
    }

    getOtherLives() {
        return [this.policy.getSecondaryLife(), ...this.policy.getDependents()].filter(el => el)
    }

    getPlan() {
        const rawPlan = Object.values(this.plans)[0]
        return toClass(rawPlan, Plan)
    }

    hasExcess() {
        return this.getPlan().hasExcess()
    }
}
