import { type Draft } from '@punnet/pure-utility-kit'
import { validate } from '@punnet/model-validation-pure'
import { Life, Policy } from '@punnet/subscription-pure'
import { validateDob, type AgeBounds } from './validateDob'
import { AppConfig } from '@sovereign/product-config'


const fieldsToValidate = ['firstname', 'lastname', 'dateOfBirth', 'address', 'postcode', 'email', 'marketingAccepted', 'smsMarketingAccepted']

// TODO where to put this
export const AGE_BOUNDS: AgeBounds = {
    'PRIMARY': {
        minAge: AppConfig.getPrimaryAgeBounds().min,
        maxAge: AppConfig.getPrimaryAgeBounds().max
    },
    'SECONDARY': {
        minAge: AppConfig.getSecondaryAgeBounds().min,
        maxAge: AppConfig.getSecondaryAgeBounds().max
    },
    'DEPENDANT': {
        minAge: AppConfig.getDependantAgeBounds().min,
        maxAge: AppConfig.getDependantAgeBounds().max
    }
}

export const validateLives = (startDate: number, ageBounds: AgeBounds, policy: Draft<Policy>) => {
    const lives = Object.values(policy.lives)

    return lives.length && lives.every(life => {
        const errorMap = validate(life, null, Life)
        const validFields = !fieldsToValidate.some(f => errorMap?.[f])

        const hasPlanId = life.planId
        const validDob = validateDob(startDate, life, ageBounds)

        return validFields && validDob && hasPlanId
    })
}
