import styles from './CompanyCard.module.css'
import { Title } from './Title'
import { Item } from './Item'
import { getCurrentCompany } from '../../../app/services/AccountProvider'

export const CompanyDetailsCard = () => {
    const company = getCurrentCompany()

    const name = company.name
    const address = company.address.display

    return <div class={styles.CompanyCard}>
        <Title>{name}</Title>
        <Item>Address <b>{address}</b></Item>
    </div>
}