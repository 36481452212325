import { mapToPlanBenefit, type Benefit } from '@punnet/product-client'
import { Plan, PlanBenefit } from '@punnet/subscription-pure'

const addBenefitToPlan = (plan: Plan, benefit: Benefit) => {
    const mappedBenefits: PlanBenefit[] = mapToPlanBenefit(benefit)
    mappedBenefits.forEach(planBenefit => plan.benefits[planBenefit.id] = planBenefit)
}

// this is going to mutate the plans array
export function addBenefit (plans: Plan[], benefit: Benefit, planId?: string) {
        // global benefit
        if (!planId) {
             plans.forEach(plan => addBenefitToPlan(plan, benefit))
        } else {
            addBenefitToPlan(plans.find(p => p.id === planId), benefit)
        }
}