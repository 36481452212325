import { keyLookup } from '@punnet/pure-utility-kit'


export const PrincipalTypeList = [
    'BROKER',
    'BROKER-ADMIN',
    'COMPANY-ADMIN',
    'FINANCE-USER',
    'PRIMARY-MEMBER',
    'SECONDARY-MEMBER',
    'DEPENDANT-MEMBER',
    'ROOT'
] as const

export type PrincipalType = typeof PrincipalTypeList[number]

export const PrincipalTypes = keyLookup<PrincipalType>()


export const RelationshipTypeList = [
    'IS-SHARED-WITH',
    'IS-ASSIGNED-TO',
    'IS-OWNED-BY'
] as const

export type RelationshipType = typeof RelationshipTypeList[number]

export const RelationshipTypes = keyLookup<RelationshipType>()



export const ResourceTypeList = [
    'SUBSCRIPTION',
    'QUOTE',
    'CLAIMS_ACTIVITY'
] as const

export type ResourceType = typeof ResourceTypeList[number]  

export const ResourceTypes = keyLookup<ResourceType>()