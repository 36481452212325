import type { KeyMapped } from '@punnet/pure-utility-kit'
import { mapBy } from './mapping'
import { isNullish, isObject, isArray, isString, isUndefined, isExactlyNull } from './type-check'


export function keys<O>(o: O): Extract<keyof O, string>[] {
    const keys: any[] = o ? Object.keys(o) : []
    return keys
}
export function values<O>(o: O): O[keyof O][] {
    return o ? Object.values(o) : []
}

export function entries<O>(o: O):[[keyof O, O[keyof O]]] {
    return (o? Object.entries(o) : []) as [[keyof O, O[keyof O]]]
}

export function mapEntries<T>(object: T, fn: (value: T[keyof T]) => T[keyof T]) {
    return Object.fromEntries(entries(object).map(([key, value]) => {
        return [key, fn(value)]
    }))
}

export function isEmpty(value: unknown) {
    if (isNullish(value)) return true

    if (isObject(value)) {
        return Object.keys(value).length === 0
    }
    else if (isArray(value) || isString(value)) {
        return isNullish(value) || value.length === 0
    }
    
}


export function pickAndClone<T, const K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    return structuredClone(pick(obj, ...keys))
}


export function mapById<T extends {id: string}>(items: T[]): KeyMapped<T> {
    return mapBy(items, (i) => i.id)
}

export function omitAndClone<T, const K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> {
    return structuredClone(omit(obj, ...keys))
}


export function pick<T, const K extends keyof T>(obj: T, ...keysToPick: K[]): Pick<T, K> {
    const picked: Partial<T> = {}
    keysToPick.forEach(k => {
        picked[k] = obj[k]
    })
    return picked as Pick<T, K>
}

export function omit<T, const K extends keyof T>(obj: T, ...keysToOmit: K[]): Omit<T, K> {
    const picked: Partial<T> = {...obj}
    keysToOmit.forEach(k => {
        delete picked[k]
    })
    return picked as Omit<T, K>
}



export function pruneUndefined<T>(x: T): T {
    return prune(x, isUndefined)
}

export function pruneNull<T>(x: T): T {
    return prune(x, isExactlyNull)
}

export function pruneNullish<T>(x: T): T {
    return prune(x, isNullish)
}

export function prune<T>(x: T, predicate: (x: unknown) => boolean): T {
    if (isObject(x)) {
        Object.keys(x).forEach(k => {
            if (predicate(x[k])) {
                delete x[k]
            } else {
                prune(x[k], predicate)
            }
        })
    } else if (isArray(x)) {
        x.forEach((e, i) => {
            if (predicate(e)) {
                x[i] = null
            } else {
                prune(x[i], predicate)
            }
        })
    }
    return x
}


