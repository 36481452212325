import {type Accessor, type Setter} from 'solid-js'
import {createBufferedSignal} from './createBufferedSignal'


export type PriorSignal<T> = [
    priorValue: Accessor<T>,
    setValue: Setter<T>,
    value: Accessor<T>,
    buffer: Accessor<T[]>
]

export function createPriorSignal<T>(initialValue?: T): PriorSignal<T> {

    const [priorValue, setCurrentValue, advanceBuffer, buffer] = createBufferedSignal<T>(null, 3)
    setCurrentValue(initialValue)
    const currentValue = () => buffer()[1]

    const setValue: Setter<T> = (newValue) => {
        if (setCurrentValue(newValue) && buffer().length > 2) {
            advanceBuffer()
        }
    }


    return [
        priorValue,
        setValue,
        currentValue,
        buffer
    ]
}
