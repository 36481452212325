import { GreenButton, GreenOutlineButton } from '../../../../../components/Button/Buttons'
import { QuoteBuilder } from '../../QuoteBuilder/QuoteBuilder'
import { QuoteFooter } from '../../QuoteFooter/QuoteFooter'
import { TextSection, WideSection } from '../../Section'
import { CompanySchemeHeader } from '../CompanySchemeHeader'
import styles from './PlanDetails.module.css'

export const PlanDetails = () => {
    return (
        <div class={styles.PlanDetails}>
            <TextSection>
                <CompanySchemeHeader />
                <p>
                    You can make any changes to your scheme here and add employee details. Once you are happy, 
                    you can hit next to add the final details and finish your purchase.
                </p>
            </TextSection>

            <WideSection>
                <QuoteBuilder />
            </WideSection>

            <TextSection>
                <h1>What's not covered</h1>
                <p>There are some things we don't cover, please make sure you read the terms & conditions before purchasing this plan;</p>
                <dl>
                    <dt><a href="">Terms of Business</a></dt>
                    <dt><a href="">Policy Terms & Conditions</a></dt>
                    <dt><a href="">Insurance product information document</a></dt>
                </dl>
            </TextSection>

            <TextSection>
                <h1>Any questions?</h1>
                <p>We're here to help, please get in touch</p>
                <div class={styles.Buttons}>
                    <GreenOutlineButton>Contact us</GreenOutlineButton>
                    <GreenButton>FAQs</GreenButton>
                </div>
            </TextSection>

            <QuoteFooter />
        </div>
    )
}