import {Life} from './Life'
import {Plan} from './Plan'
import {KeyMapped, values} from '@punnet/pure-utility-kit'
import {HasLifecycleStatus, isCancelled, LifecycleStatus, TransferDetails} from './LifecycleStatus'
import {toClass} from '@punnet/model-validation-pure'
import type { Pence } from '@peachy/core-domain-pure'

export class Policy implements HasLifecycleStatus{
    id: string

    status: LifecycleStatus
    startDate: number
    endDate?: number
    cancellationReason?:string

    nextRenewalDate?: number
    lastRenewalDate?: number


    transfer?: TransferDetails

    totalMonthlyPremium?: Pence

    lives: KeyMapped<Life> = {}

    plans?: KeyMapped<Plan> = {}

    getAllLives?(): Life[] {
        return values(this.lives).map(l => toClass(l, Life))
    }

    getActiveLives?(): Life[] {
        return values(this.lives)
            .filter(l => !isCancelled(l))
            .map(l => toClass(l, Life))
    }

    getPrimaryLife?(): Life {
        const life = Object.values(this.lives).find(l => l.type === 'PRIMARY')
        return toClass(life, Life)
    }

    getSecondaryLife?(): Life {
        const life = Object.values(this.lives).find(l => l.type === 'SECONDARY')
        return toClass(life, Life)
    }

    getDependents?(): Life[] {
        const lives = Object.values(this.lives).filter(l => l.type === 'DEPENDANT')
        return lives.map(l => toClass(l, Life))
    }

    getAppCustomers?(): Life[] {
        const lives = Object.values(this.lives).filter(l => l.cognitoUserId)
        return lives.map(l => toClass(l, Life))
    }

    getNonPrimaryLives?(): Life[] {
        const lives = Object.values(this.lives).filter(l => l.type !== 'PRIMARY')
        return lives.map(l => toClass(l, Life))
    }

    getLifeByCognitoUserId?(cognitoUserId: string) {
        const life = Object.values(this.lives).find(it => it.cognitoUserId === cognitoUserId)
        return toClass(life, Life)
    }

    findLife? = (where: (life: Life) => boolean) =>
        values(this.lives).find(p => where(p))

    hasLife? = (lifeId: string) => this.lives[lifeId]
}
