import { LifeBenefit } from './LifeBenefit'
import { AlivenessCheckStatus, IdCheckStatus, SanctionsCheckStatus } from './statuses'


import { differenceInYears, } from 'date-fns'
import { Address } from './geo-location/Address'

import { KeyMapped, enumerate, keyLookup } from '@punnet/pure-utility-kit'
import { IsEmail, IsIn, Optional, Required } from '@punnet/model-validation-pure'
import { Type } from 'class-transformer'
import { HasLifecycleStatus, LifecycleStatus, TransferDetails } from './LifecycleStatus'


export type LifeType =
    | 'PRIMARY'
    | 'SECONDARY'
    | 'DEPENDANT'

export const LifeTypes = keyLookup<LifeType>()


export const GenderTypeArray = [
    'MALE',
    'FEMALE',
    'BLENDED',
] as const


export const GenderTypes = enumerate(GenderTypeArray)

export type GenderType = keyof typeof GenderTypes


export class Life implements HasLifecycleStatus {

    planId?: string
    customPlanId?: string

    status: LifecycleStatus
    startDate: number
    endDate?: number
    cancellationReason?:string
    nextRenewalDate?: number
    lastRenewalDate?: number

    transfer?: TransferDetails
    totalMonthlyPremium?: number

    benefits: KeyMapped<LifeBenefit> = {}

    id: string
    cognitoUserId?: string
    intercomUserId?: string

    @Required()
    type: LifeType

    @Required()
    firstname: string

    @Required()
    lastname: string

    // todo - age validation with respect to the start date!
    @Required()
    dateOfBirth: number

    @IsIn(GenderTypeArray)
    @Optional()
    gender?: GenderType

    @IsEmail()
    email?: string

    phone?: string
    @Type(() => Address)
    @Required()
    address: Address

    @Required()
    postcode: string

    @Required()
    marketingAccepted: boolean = false

    @Required()
    smsMarketingAccepted: boolean = false


    idCheckStatus?: IdCheckStatus
    sanctionsCheckStatus?: SanctionsCheckStatus
    alivenessCheckStatus?: AlivenessCheckStatus

    fullName?() {
        return `${this.firstname} ${this.lastname}`
    }

    age?() {
        let age = undefined
        if (this.dateOfBirth) {
            const date = new Date(this.dateOfBirth)
            if (date) {
                age = differenceInYears(Date.now(), this.dateOfBirth)
            }
        }
        return age
    }

    ageAt?(aDate: Date) {
        let ageAt = undefined
        if (this.dateOfBirth) {
            ageAt = differenceInYears(aDate, this.dateOfBirth)
        }
        return ageAt
    }
}
