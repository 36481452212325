import {enumerate, removeUndefinedsFrom} from '@peachy/utility-kit-pure'
import {QuestionOption} from './QuestionOption'
import {Life} from '../Life'

export const gotItOption = new QuestionOption('GOT_IT', 'Got it')
export const gotItOptions = [
    gotItOption
]

export const imReadyOption = new QuestionOption('READY', "Ok, I'm ready!")
export const imReadyOptions = [
    imReadyOption
]

export const doneOption = new QuestionOption('DONE', 'Done')
export const doneOptions = [
    doneOption
]

export const yesOption = new QuestionOption('YES', 'Yes')
export const noOption = new QuestionOption('NO', 'No')
export const yesNoOptions = [
    yesOption,
    noOption
]

export const iDontKnowOption = new QuestionOption('DONT_KNOW', "I don't know")
export const yesNoDontKnowOptions = [
    yesOption,
    noOption,
    iDontKnowOption
]

export const inPatientOption = new QuestionOption('IN_PATIENT', 'In-Patient')
export const outPatientOption = new QuestionOption('OUT_PATIENT', 'Out-Patient')
export const inOrOutPatientOptions = [
    inPatientOption,
    outPatientOption
]

export const dentalBenefitOption = new QuestionOption('DENTAL', 'Dental')
export const opticalBenefitOption = new QuestionOption('OPTICAL', 'Optical')
export const therapiesBenefitOption = new QuestionOption('THERAPIES', 'Therapies')
export const mentalHealthBenefitOption = new QuestionOption('MENTAL_HEALTH', 'Mental health')
export const consultationsBenefitOption = new QuestionOption('CONSULTATIONS_AND_DIAGNOSTICS', 'Consultations & diagnostics')
export const hospitalCareBenefitOption = new QuestionOption('HOSPITAL_CARE', 'Hospital care')

export const physiotherapistOption = new QuestionOption('PHYSIOTHERAPIST', 'Physiotherapist')
export const osteopathOption = new QuestionOption('OSTEOPATH', 'Osteopath')
export const chiropracterOption = new QuestionOption('CHIROPRACTER', 'Chiropracter')
export const chiropodistPodiatristOption = new QuestionOption('CHIROPODIST_PODIATRIST', 'Chiropodist / Podiatrist')
export const acupuncturistOption = new QuestionOption('ACUPUNCTURIST', 'Acupuncturist')
export const dietitianOption = new QuestionOption('DIETITIAN', 'Dietitian')
export const therapistOptions = [
    physiotherapistOption,
    osteopathOption,
    chiropracterOption,
    chiropodistPodiatristOption,
    acupuncturistOption,
    dietitianOption
]

export const psychologistOption = new QuestionOption('PSYCHOLOGIST', 'Consultation with a psychologist')
export const psychiatristOption = new QuestionOption('PSYCHIATRIST', 'Consultation with a psychiatrist')
export const hospitalTreatmentOption = new QuestionOption('HOSPITAL', 'Hospital treatment')
export const otherOption = new QuestionOption('OTHER', 'Other')
export const mentalHealthCareOptions = [
    hospitalTreatmentOption,
    psychologistOption,
    psychiatristOption,
    otherOption
]

export const submitOption = new QuestionOption('SUBMIT', 'Submit')
export const reviewSubmitOptions = [
    submitOption
]

export const whoOptions = (lives: Life[]) => lives.map(it => new QuestionOption(it.id,  it.fullName))

export const newPhoneNumberOption = new QuestionOption('NEW_PHONE_NUMBER', `Another number`)
export const whichPhoneNumberOptions = (existingPhoneNumber: string ) => [
    new QuestionOption('EXISTING_PHONE_NUMBER', existingPhoneNumber),
    newPhoneNumberOption
]

export const VideoOrPhoneOptionIds = enumerate(['VIDEO', 'PHONE'])
export type VideoOrPhoneOptionId = keyof typeof VideoOrPhoneOptionIds
export const videoOption = new QuestionOption(VideoOrPhoneOptionIds.VIDEO, 'Video', '(Mon-Sun, 8:00-22:00)')
export const phoneOption = new QuestionOption(VideoOrPhoneOptionIds.PHONE, 'Phone', '(24/7)')
export const videoOrPhoneOptions = [
    videoOption,
    phoneOption
]


export const BinaryGenderOptionIds = enumerate(['FEMALE', 'MALE'])
export type BinaryGenderOptionId = keyof typeof BinaryGenderOptionIds

export const GenderPreferenceOptionIds = enumerate([BinaryGenderOptionIds.FEMALE, BinaryGenderOptionIds.MALE, 'NO_PREFERENCE'])
export type GenderPreferenceOptionId = keyof typeof GenderPreferenceOptionIds
export const femaleOption = new QuestionOption(GenderPreferenceOptionIds.FEMALE, 'Female')
export const maleOption = new QuestionOption(GenderPreferenceOptionIds.MALE, 'Male')
export const noPreferenceOption = new QuestionOption(GenderPreferenceOptionIds.NO_PREFERENCE, 'No preference')
export const genderPreferenceOptions = [
    femaleOption,
    maleOption,
    noPreferenceOption
]

export const useExistingGpOption = new QuestionOption('EXISTING_GP', "Yes, that's my GP")
export const changeMyGpOption = new QuestionOption('NEW_GP', 'No I need to change it')
export const whichRegisteredGpOptions = (life: Life) => removeUndefinedsFrom([
    life.registeredGp ? useExistingGpOption : undefined, 
    changeMyGpOption
])

export const bookNowOption = new QuestionOption('BOOK_NOW', 'Book now')
export const bookNowOptions = [
    bookNowOption
]

export const cantMakeTheAppointmentOption = new QuestionOption('CANT_MAKE_IT', `Can't make it anymore`)
export const dontNeedTheAppointmentOption = new QuestionOption('DONT_NEED_IT', `Don't need it anymore`)
export const madeInErrorAppointmentOption = new QuestionOption('MADE_IN_ERROR', `Made it in error, oops!`)
export const cancelAppointmentReasonOptions = [
    cantMakeTheAppointmentOption,
    dontNeedTheAppointmentOption,
    madeInErrorAppointmentOption
]

export const newInjuryOption = new QuestionOption('NEW_INJURY', 'New injury')
export const existingInjuryOption = new QuestionOption('EXISTING_INJURY', 'Existing injury')
export const newOrExistingInjuryOptions = [
    newInjuryOption,
    existingInjuryOption
]

//should use currencyFormatter
export const PriceOptionIds = enumerate(['LOW', 'MEDIUM', 'HIGH'])
export type PriceOptionId = keyof typeof GenderPreferenceOptionIds
export const lowPriceOption = new QuestionOption(PriceOptionIds.LOW, '£')
export const mediumPriceOption = new QuestionOption(PriceOptionIds.MEDIUM, '££')
export const highPriceOption = new QuestionOption(PriceOptionIds.HIGH, '£££')
export const priceOptions = [
    lowPriceOption,
    mediumPriceOption,
    highPriceOption
]


export const binaryGenderOptions = [
    maleOption, femaleOption
]

export const AppointmentTypeOptionIds = enumerate(['VIRTUAL', 'IN_PERSON'])
export type AppointmentTypeOptionId = keyof typeof AppointmentTypeOptionIds
export const virtualAppointmentOption = new QuestionOption(AppointmentTypeOptionIds.VIRTUAL, 'Virtual')
export const inPersonAppointmentOption = new QuestionOption(AppointmentTypeOptionIds.IN_PERSON, 'Face-to-face')
export const appointmentOptions = [
    virtualAppointmentOption,
    inPersonAppointmentOption
]

export const useSavedAccountOption = new QuestionOption('USE_SAVED_ACC', 'Use my saved account')
export const addNewAccountOption = new QuestionOption('ADD_NEW_ACC', 'Enter a new account')
export const savedOrNewAccountOptions = [
    useSavedAccountOption,
    addNewAccountOption
]
