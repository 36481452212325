import { createSignal } from 'solid-js'
import { useStoreActions } from '../../../../store/quotes/quoteAndBuyStore'
import { Title } from '../../Quote'
import styles from '../../Quote.module.css'
import { FormSection, TextSection } from '../../Section'
import { CompanySchemeHeader } from '../CompanySchemeHeader'
import { NavigationButtons } from '../NavigationButtons'
import { useQuotePersister } from '../PurchaseQuote'


export const Schedules = () => {
    const quotePersister = useQuotePersister()
    const state = useStoreActions.getState()
    
    const [sendWelcomeEmail, setSendWelcomeEmail] = createSignal<string | undefined>(state.sendWelcomeEmailSchedule)

    const isValid = () => sendWelcomeEmail() !== undefined

    const onNext = async () => {
        useStoreActions.setSendWelcomeEmailSchedule(sendWelcomeEmail())
        await quotePersister.persist()
    }

    return <>
        <TextSection>
            <CompanySchemeHeader />
            <p>Set your preference on when to send out the welcome email and documents to your employees</p>
        </TextSection>
        
        <FormSection>
            <Title>Employee welcome email:</Title>

            <div class={styles.Section}>
                <label>
                    <input required type="radio" name="scheduleEmail" value={'now'} checked={sendWelcomeEmail() === 'now'} onChange={() => setSendWelcomeEmail('now')} />
                    Send welcome email now
                </label>
                <label>
                    <input required type="radio" name="scheduleEmail" value={'before-start-date'} checked={sendWelcomeEmail() === 'before-start-date'} onChange={() => setSendWelcomeEmail('before-start-date')} />
                    Send welcome email 3-5 days before company scheme start date
                </label>
            </div>
        </FormSection>

        <TextSection>
            <NavigationButtons nextButtonDisabled={!isValid()} onNext={onNext} />
        </TextSection>
    </>
}