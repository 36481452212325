import {defineValidator, MessageProducer, ValidateIf} from '../validation-core'

export default function Min<T>(
    minValue: any,
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'Min',
        message:
            message ??
            (({property}) =>
                `Property ${property.toString()} must not be less than ${minValue}`),
        messageParams: {minValue},
        validateIf,
        validate({value, context}) {
            return value >= minValue
        },
    })
}
