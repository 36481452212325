import { Auth, type CognitoUser } from '@aws-amplify/auth'
import { fetchServiceConfig, useServicePatch } from '@punnet/web-client-kit-library'
type AuthType = typeof Auth


export type IamConfigType = 'PortalIamConfig' | 'MemberIamConfig'


export type IamApi = {
    extractError: (e: any) => AuthenticationError
    allowSelfSignUp: boolean
} & Pick<AuthType,
    | 'currentAuthenticatedUser'
    | 'changePassword'
    | 'completeNewPassword'
    | 'confirmSignIn'
    | 'confirmSignUp'
    | 'verifyUserAttribute'
    | 'verifyUserAttributeSubmit'
    | 'updateUserAttributes'
    | 'forgotPassword'
    | 'forgotPasswordSubmit'
    | 'resendSignUp'
    | 'signIn'
    | 'signOut'
    | 'signUp'>



export type IamUser = CognitoUser
const region = 'eu-west-2'



export async function createIamApi(iamConfigType: IamConfigType): Promise<IamApi> {
    return configureAws(iamConfigType)
}



function isTempPasswordExpireError(message: string): boolean {
    return message.includes('Temporary password has expired and must be reset by an administrator')
}

export type AuthenticationError =
    'PasswordPolicyException' | 'PasswordExpired' | 'InvalidCredentials' | 'ExceededMaxAttempts' | 'UserNotFound' | 'Unknown'


function extractError(e: any): AuthenticationError {
    const code = e.code
    const message = e.toString()

    switch (code) {
        case 'InvalidPasswordException':
            return 'PasswordPolicyException'
        case 'NotAuthorizedException':
            return isTempPasswordExpireError(message) ? 'PasswordExpired' : 'InvalidCredentials'
        case 'LimitExceededException':
            return 'ExceededMaxAttempts'
        case 'UserNotFoundException':
            return 'UserNotFound'
        default:
            return 'Unknown'
    }
}





async function configureAws(iamConfigType: IamConfigType): Promise<IamApi> {

    const servicePatchUrl = useServicePatch()

    //auth config
    const iamConfig = await fetchServiceConfig(iamConfigType, servicePatchUrl)

    
    Auth.configure({
        userPoolId: iamConfig.userPoolId,
        userPoolWebClientId: iamConfig.userPoolWebClientId,
        identityPoolId: iamConfig.identityPoolId,
        region,
    })
    
    const augmentedAuth = Auth as Partial<IamApi>
    augmentedAuth.allowSelfSignUp = iamConfig.allowSelfSignUp
    augmentedAuth.extractError = extractError
    return augmentedAuth as IamApi
}




