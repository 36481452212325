import {Observable} from 'rxjs'

export function minRate<T>(timeout: number) {
    return (o: Observable<T>): Observable<T> => {
        return minRate ? new Observable(subscriber => {
            let id: any
            const bumpTimeout = () => {
                clearTimeout(id)
                id = setTimeout(() => {
                    subscriber.error(`Stream timed-out due to ${timeout} ms idle..`)
                }, timeout)
            }

            bumpTimeout()

            o.subscribe({
                next: (element: T) => {
                    bumpTimeout()
                    subscriber.next(element)
                },
                error: (err: any) => {
                    clearTimeout(id)
                    subscriber.error(err)
                },
                complete: () => {
                    clearTimeout(id)
                    subscriber.complete()
                },
            })
        }) : o
    }
}
