import {Type} from 'class-transformer'
import 'reflect-metadata'
import {Question} from './Question'
import {QuestionAnswer} from './answer/types'
import {answerIsMedia} from './answer/Media'
import {EnquiryDefinitionId} from './types'

export class Enquiry {

    @Type(() => Question)
    questions: Question[] = []

    constructor(readonly id: string, readonly definition: EnquiryDefinitionId) { }

    static copyOf(otherEnquiry: Enquiry): Enquiry {
        return Object.assign(new Enquiry(otherEnquiry.id, otherEnquiry.definition), otherEnquiry)
    }

    isSatisfied() {
        return this.getRequiredQuestions().every(questionIsSatisfied)
    }

    getQuestionsUptoAndIncludingFirstUnsatisfied() {
        const firstUnsatisfied = this.getFirstUnsatisfiedQuestion()
        const questions = this.getRequiredQuestions()
        return firstUnsatisfied ? [...questions.slice(0, questions.indexOf(firstUnsatisfied) + 1)] : [...questions]
    }

    getQuestionsUptoButNotIncludingFirstUnsatisfied() {
        const firstUnsatisfied = this.getFirstUnsatisfiedQuestion()
        const questions = this.getRequiredQuestions()
        return firstUnsatisfied ? [...questions.slice(0, questions.indexOf(firstUnsatisfied))] : [...questions]
    }

    getQuestionsFromAndAfter(questionId: string) {
        let questionFromFound = false
        return this.questions.filter(it => {
            questionFromFound = questionFromFound || it.id === questionId
            return questionFromFound
        })
    }

    getFirstUnsatisfiedQuestion() {
        return this.getRequiredQuestions().find(questionIsNotSatisfied)
    }

    getLastSatisfiedQuestion() {
        return this.getRequiredQuestions().reverse().find(questionIsSatisfied)
    }

    getLastSatisfiedNonFullScreenQuestion() {
        return this.getRequiredQuestions().reverse().find(it => questionIsSatisfied(it) && !it.isFullScreenPresentation())
    }

    getQuestionEvenIfNotRequired(id: string) {
        return this.questions.find(questionWithId(id))
    }

    getQuestion(id: string) {
        return this.getRequiredQuestions().find(questionWithId(id))
    }

    getFirstAnswerTo<T extends QuestionAnswer>(questionId: string) {
        return this.getQuestion(questionId)?.getFirstAnswer<T>()
    }

    getAnswersTo<T extends QuestionAnswer>(questionId: string) {
        return (this.getRequiredQuestions().find(questionWithId(questionId))?.answers || []) as T[]
    }

    getRequiredQuestions() {
        return this.questions.filter(questionIsRequired)
    }

    getQuestionsWithMediaAnswers() {
        return this.getRequiredQuestions().filter(questionHasMediaAnswers)
    }

    getQuestionsWithMediaAnswersEvenIfNotRequired() {
        return this.questions.filter(questionHasMediaAnswers)
    }

    isUntouched() {
        return !this.isDirty()
    }

    isDirty() {
        return this.questions.some(questionHasAnswers)
    }
}

const questionIsRequired = (question: Question) => question.required
const questionIsSatisfied = (question: Question) => question.satisfied && question.hasAnyAnswer()
const questionWithId = (id: string) => (question: Question) => question.id === id
const questionIsNotSatisfied = (question: Question) => !question.satisfied
const questionHasMediaAnswers = (question: Question) => question.answers.some(answerIsMedia)
const questionHasAnswers = (question: Question) => question.answers.length > 0