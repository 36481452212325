import { Modal } from '@punnet/web-client-kit-library'
import { useNavigate } from '@solidjs/router'
import { type Component } from 'solid-js'
import { useCustomerEmailAddress } from '../../../store/quotes/quoteAndBuyStore'
import { Title } from '../Quote'
import styles from './SuccessModal.module.css'

export const SuccessModal: Component<{ onClose: () => void }> = (props) => {
    const navigate = useNavigate()

    const onClose = () => {
        props.onClose()
        navigate('/quotes')
    }
    
    return <Modal isOpen={true} onDismiss={onClose} class={styles.modal}>
        <section class={styles.Content}>
            <Title>All done!</Title>
            <div class={styles.Message}>
                <p>Thanks! An email has been sent to {useCustomerEmailAddress()}</p>
                <p>You will no longer be able to edit this quote</p>
            </div>
        </section>
    </Modal>
}