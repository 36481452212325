import {classList} from '@punnet/pure-utility-kit'
import styles from './Toggle.module.css'

export interface ToggleProps {
    isOn: boolean
    onToggle: (b: boolean) => void
    label?: string
    class?: string
    disabled?: boolean
}

export const Toggle = (props: ToggleProps) => {
    const classes = classList(styles.Toggle, props.class)

    const onKeyDown = (e: any) => {
        if (['ArrowDown', 'ArrowRight'].includes(e.key)) {
            props.onToggle(true)
        } else if (['ArrowUp', 'ArrowLeft'].includes(e.key)) {
            props.onToggle(false)
        }
    }

    const onChange = (evt: Event) => {
        const element = evt.currentTarget as HTMLInputElement
        props.onToggle(element.checked)
    }

    return (
        <label class={classes}>
            <span>{props.label}</span>
            <input type={'checkbox'} disabled={props.disabled} checked={props.isOn} onKeyDown={onKeyDown} onChange={onChange}/>
        </label>
    )
}