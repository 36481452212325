import { currencyFromPence, readableDate, type Draft } from "@punnet/pure-utility-kit"
import { toClass } from "@punnet/model-validation-pure"
import { Life, Plan, type Policy } from "@punnet/subscription-pure"
import { For, type Component } from "solid-js"
import { getCurrentPlans, getCurrentPolicyForId } from '../../../app/services/AccountProvider'
import { type MemberPolicy } from '../../../app/store/members/mapToMemberPolicies'
import styles from './SummaryOfChanges.module.css'
import { useQuoteResponse } from '../../../app/pages/Quotes/QuoteController/QuoteController'

type Action = 'Added' | 'Cover change' | 'Removed'

export const SummaryOfChanges: Component<{ changedPolicies: MemberPolicy[] }> = (props) => {

    const plans = getCurrentPlans()
    const quoteResponse = useQuoteResponse()

    const getCost = (memberPolicy: MemberPolicy, currentPolicy?: Policy) => {
        const premiumFromQuote = quoteResponse().policies.find(p => p.id === memberPolicy.id)?.totalMonthlyPremium
        const currentPremium = currentPolicy?.totalMonthlyPremium ?? 0
        return premiumFromQuote - currentPremium
    }

    return (
        <div class={styles.SummaryChanges}>
            <h4>Summary of changes</h4>
            <table class={styles.SummaryChangesTable}>
                <thead>
                    <tr class={styles.Header}>
                        <th>Action</th>
                        <th>Name</th>
                        <th>Date of birth</th>
                        <th>Policy level</th>
                        <th>Cost</th>
                    </tr>
                </thead>
                <tbody>
                    <For each={props.changedPolicies}>{(memberPolicy) =>
                        <ChangesRow
                            plans={plans}
                            memberPolicy={memberPolicy}
                            cost={getCost(memberPolicy, getCurrentPolicyForId(memberPolicy.id))}
                        />}
                    </For>
                </tbody>
            </table>
        </div>
    )
}

const ChangesRow: Component<{ plans: Plan[], memberPolicy: MemberPolicy, cost: number }> = (props) => {
    const planName = (id: string) => props.plans.find(p => p.id === id)?.name ?? 'N/A'
    const changes = mapToPolicyChange(props.memberPolicy, props.cost)

    return <tr>
        <td>{changes.action}</td>
        <td>{changes.name}</td>
        <td>{readableDate(changes.dateOfBirth) ?? 'N/A'}</td>
        <td>{planName(changes.planId)}</td>
        <td>{changes.cost > 0 ? '+' : ''}{currencyFromPence(changes.cost)}</td>
    </tr>
}


// policy changes

type PolicyPrimaryLifeChanges = {
    action: Action
    name: string
    dateOfBirth?: number
    planId?: string
    cost: number
}

const getAction = (memberPolicy: MemberPolicy) => {
    switch (memberPolicy.action) {
        case 'ADD': return 'Added'
        case 'CHANGE_COVER': return 'Cover change'
        case 'REMOVE': return 'Removed'
    }
}

const mapToPolicyChange = (memberPolicy: MemberPolicy, cost: number) => {
    const life = toClass(memberPolicy.primary, Life)

    return {
        action: getAction(memberPolicy),
        name: life.fullName(),
        dateOfBirth: life.dateOfBirth,
        planId: life.planId,
        cost
    } as PolicyPrimaryLifeChanges
}