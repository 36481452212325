import {defineValidator, MessageProducer, ValidateIf} from '../validation-core'

export default function Max<T>(
    maxValue: any,
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'Max',
        message:
            message ??
            (({property}) =>
                `Property ${property.toString()} must not be less than ${maxValue}`),
        messageParams: {maxValue},
        validateIf,
        validate({value}) {
            return value <= maxValue
        },
    })
}
