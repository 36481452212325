import { type Draft } from '@punnet/pure-utility-kit'
import { Plan, Policy } from '@punnet/subscription-pure'
import type { ModalProps } from '@punnet/web-client-kit-library'
import { Modal, createToggleSignal, untrack } from '@punnet/web-client-kit-library'
import { Show, createEffect, createSignal } from 'solid-js'
import { useManagePolicies, useManageStoreActions, type LifeValidation, type Tooltips as Tooltips } from '../../../app/store/quotes/manageEmployeesStore'
import { GreenOutlineButton, GreyButton, PrimaryButton } from '../../Button/Buttons'
import { ManageEmployeesTable } from '../table/ManageEmployeesTable'
import { BulkAddMembers } from './bulk-add/BulkAddMembers'
import styles from './ManageEmployeesModal.module.css'

type DefaultView = 'bulk' | 'manual'

type ManageEmployeesModalProps = ModalProps & {
    plans: Plan[]
    policies: Draft<Policy>[]
    startDate: number
    minPolicyCount: number
    onUpdate: (policies: Draft<Policy>[]) => void
    lifeValidation: LifeValidation
    toolTips: Tooltips
    defaultView?: DefaultView
}


export function ManageEmployeesModal(props: ManageEmployeesModalProps) {
    const { init } = useManageStoreActions()
    const [canBulkUpload, setCanBulkUpload] = createSignal<boolean>(false)

    createEffect(() => {
        // ensure that we re-init when the modal is opened
        // We don't want to run again. Don't call other signals here
        if (props.isOpen) {
            init({
                ...props
            })
        }
    })

    createEffect(() => {
        if (props.isOpen) {
            setCanBulkUpload(isBulk())
        }
    })

    const policies = useManagePolicies()

    const [isBulk, toggleBulk] = createToggleSignal(props.defaultView === 'bulk')

    const closeAndUpdate = () => {
        setCanBulkUpload(false)
        props.onUpdate(untrack(policies))
        props.onDismiss?.()
    }

    return (
        <Modal isOpen={props.isOpen} class={styles.modal} onDismiss={closeAndUpdate}>
            <Show when={isBulk()}>
                <BulkAddMembers
                    canMap={canBulkUpload}
                    onManualUpload={toggleBulk}
                    onUpdate={closeAndUpdate}
                    footerStyles={styles.footerContainer}
                />
            </Show>
            <Show when={!isBulk()}>
                <ManualAddMembers
                    onBulkUpload={toggleBulk}
                    onUpdate={closeAndUpdate}
                />
            </Show>
        </Modal>
    )
}

// TODO-MN Move to own component file

type ManualAddMembersProps = {
    onBulkUpload: () => void
    onUpdate: () => void
}

const ManualAddMembers = (props: ManualAddMembersProps) => {
    const { addPolicy } = useManageStoreActions()

    const onAdd = () => {
        addPolicy()
    }

    return <section>
        <h3>Add employee details</h3>
        <p>Please add your employees' details below. To import directly from a spreadsheet, use the bulk upload feature.</p>

        <ManageEmployeesTable />

        <div class={styles.footerContainer}>
            <div class={styles.bottomLeft}>
                <PrimaryButton onClick={onAdd}>Add another employee</PrimaryButton>
                <GreyButton onClick={props.onBulkUpload}>Bulk upload</GreyButton>
                <GreenOutlineButton>Clear all</GreenOutlineButton>
            </div>
            <PrimaryButton onClick={props.onUpdate}>Update quote</PrimaryButton>
        </div>
    </section>
}