import type { SubscriptionQuoteRequest } from '@punnet/subscription-pure'
import { onMount, type ParentComponent } from 'solid-js'
import { createSubscription } from '../../../services/quote/createSubscription'
import { mapToSubscriptionRequest } from '../../../store/quotes/mapToSubscriptionRequest'
import { subscribeToStore, useStoreActions, type QuoteAndBuyState } from '../../../store/quotes/quoteAndBuyStore'
import { Quote } from '../Quote'
import { useQuoteResponse } from '../QuoteController/QuoteController'
import styles from './NewQuote.module.css'

export const NewQuote: ParentComponent = (props) => {
    onMount(() => {
        useStoreActions.clear()
    })

    const quoteResponse = useQuoteResponse()

    const quoteSubscription = createSubscription<QuoteAndBuyState, SubscriptionQuoteRequest>(
        subscribeToStore,
        (state, prevState) => state.quoteRequestId !== prevState.quoteRequestId,
        (state) => mapToSubscriptionRequest(state, quoteResponse()) as SubscriptionQuoteRequest
    )

    return (
        <div class={styles.NewQuote}>
            <Quote subscribe={quoteSubscription}>
                {props.children}
            </Quote>
        </div>
    )
}