import {defineValidator, MessageProducer} from '../validation-core'
import {isUUID} from 'validator'

export default function IsUUID<T>(message?: string | MessageProducer<T>) {
    return defineValidator<T>({
        name: 'IsUUID',
        message:
            message ??
            (({property}) => `Property ${property.toString()} must be a valid UUID`),
        validate({value}) {
            return value && isUUID(value)
        },
    })
}
