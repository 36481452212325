import { AnEvent } from '@punnet/core-event-pure'


export class CancelPolicyRequest extends AnEvent {
    public accountId: string
    public subscriptionId: string
    public policyId: string
    public effectiveDate?: number
    public cancellationReason?: string

    constructor(from: CancelPolicyRequest) {
        super()
        Object.assign(this, from)
    }
}
