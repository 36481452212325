import {createEffect, onCleanup} from 'solid-js'

const root: HTMLHtmlElement = document.querySelector(':root')

export function dynamicCssPageScrollTop() {

    createEffect(() => {

        window.addEventListener('scroll', () => {
            const scrollTop = window.scrollY
            root.style.setProperty('--page-scroll-top', scrollTop.toString())
        })
        onCleanup(() => {
            window.removeEventListener('scroll', () => {
                const scrollTop = window.scrollY
                root.style.setProperty('--page-scroll-top', scrollTop.toString())
            })
        })
    })
}
