import {
    defineApiGateway,
} from '@peachy/core-domain-pure'

import { SubscriptionQuoteRequest } from './domain/models/SubscriptionQuoteRequest'
import { SubscriptionQuoteResponse } from './domain/models/SubscriptionQuoteResponse'

import { ApiGatewayClient } from '@peachy/core-domain-client'
import { InviteCompanyAdminRequest } from './domain/models/InviteCompanyAdminRequest'
import { InviteUserResponse } from '@punnet/iam-pure'
import { AccountSubscriptionDto } from './domain/models/AccountSubscriptionDto'
import { ContentBucketResponse, QuoteSummaryList, SubscriptionSummaryList } from '@punnet/subscription-pure'





export const SubscriptionStackApiDefinition = defineApiGateway('SubscriptionStackApi').withRoutes({
    getSmeQuote: {
        method: 'POST',
        path: '/quote/sme',
        isPublic: false,
        requestType: SubscriptionQuoteRequest,
        responseType: SubscriptionQuoteResponse
    },
    saveSmeDraftQuote: {
        method: 'POST',
        path: '/quote/sme/draft/{quoteId}',
        isPublic: false,
        requestType: SubscriptionQuoteRequest,
        responseType: SubscriptionQuoteResponse
    },
    loadSmeDraftQuote: {
        method: 'GET',
        path: '/quote/sme/draft/{quoteId}',
        isPublic: false,
        responseType: SubscriptionQuoteResponse
    },

    fetchQuoteSummaries: {
        method: 'GET',
        path: '/quote/summary',
        isPublic: false,
        responseType: QuoteSummaryList
    },  

    fetchSubscriptionSummaries: {
        method: 'GET',
        path: '/subscription/summary',
        isPublic: false,
        responseType: SubscriptionSummaryList
    },

    activateSmeSubscription: {
        method: 'POST',
        path: '/subscription/sme/activate',
        isPublic: false,
        requestType: SubscriptionQuoteRequest,
        responseType: SubscriptionQuoteResponse
    },

    inviteCompanyAdmin: {
        method: 'POST',
        path: '/subscription/sme/invite',
        isPublic: false,
        requestType: InviteCompanyAdminRequest,
        responseType: InviteUserResponse
    },

    inviteCompanyFinanceUser: {
        method: 'POST',
        path: '/subscription/sme/invite/finance',
        isPublic: false,
        requestType: InviteCompanyAdminRequest,
        responseType: InviteUserResponse
    },

    loadActiveSmeSubscription: {
        method: 'GET',
        path: '/subscription/sme/load',
        isPublic: false,
        responseType: AccountSubscriptionDto
    },

    alterSmeSubscription: {
        method: 'POST',
        path: '/subscription/sme/alter',
        isPublic: false,
        requestType: SubscriptionQuoteRequest,
        responseType: SubscriptionQuoteResponse
    },

    resolveContentBucket: {
        method: 'POST',
        path: '/resolve-content-bucket',
        isPublic: false,
        responseType: ContentBucketResponse
    }


    // adminLoadActiveSubscription: {
    //     method: 'GET',
    //     path: '/subscription/load/{accountId}/{subscriptionId}',
    //     isPublic: false,
    //     responseType: AccountSubscriptionDto
    // },

    

    // cancelSubscription: {
    //     method: 'POST',
    //     path: '/subscription/cancel',
    //     isPublic: false,
    //     requestType: CancelSubscriptionRequest,
    // },

    // cancelPolicy: {
    //     method: 'POST',
    //     path: '/policy/cancel',
    //     isPublic: false,
    //     requestType: CancelPolicyRequest,
    // },

    // cancelLife: {
    //     method: 'POST',
    //     path: '/life/cancel',
    //     isPublic: false,
    //     requestType: CancelLifeRequest,
    // },

    // },
    // getIndividualQuote: {
    //     method: 'POST',
    //     path: '/quote/individual',
    //     isPublic: true,
    //     requestType: MrSubscriptionQuoteRequest,
    //     responseType: MrSubscriptionQuoteResponse
    // },
    // activateIndividualSubscription: {
    //     method: 'POST',
    //     path: '/quote/individual/activate',
    //     isPublic: true,
    //     requestType: MrSubscriptionQuoteRequest,
    //     responseType: MrSubscriptionQuoteResponse

    // },
    // alterIndividualSubscription: {
    //     method: 'POST',
    //     path: '/subscription/individual/alter',
    //     isPublic: false,
    //     requestType: MrSubscriptionQuoteRequest,
    //     responseType: MrSubscriptionQuoteResponse
    // },

    // reissueCompanyPolicyDoc: {
    //     method: 'POST',
    //     path: '/quote/sme/policy-doc',
    //     isPublic: true,
    //     requestType: ReissuePolicyDocRequest,
    //     responseType: ReissuePolicyDocResponse
    // },


})

export type SubscriptionStackApi = ApiGatewayClient<typeof SubscriptionStackApiDefinition>
