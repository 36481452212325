import { parseDmyDate, type Draft } from "@punnet/pure-utility-kit"
import { firstMatchingAddressFetcher } from "@punnet/address-lookup-web-client"
import type { Plan, Policy } from "@punnet/subscription-pure"
import { parseExpectedFields } from "@punnet/web-client-kit-library"
import type { Accessor, Setter } from "solid-js"
import { createLife } from "./createLife"
import { createPolicy } from "./createPolicy"


type ParseTabularDraftPoliciesArgs = {
    expectedCsvFields: string[]
    text: string
    plans: Plan[]
    startDate: number
    // TODO-CC https://bitbucket.org/peachy-punnet/peachy-mono-repo/pull-requests/482/overview#comment-560226130
    setMessage?: Setter<ProgressMessage>
    canMap?: Accessor<boolean>
}

// TODO-MN is there a better place for this
export type ProgressMessage = {
    text?: string
    style?: ProgressMessageStyle
}

export type ProgressMessageStyle = 'PROGRESS' | 'ERROR'

export async function mapCsvToPolicies(args: ParseTabularDraftPoliciesArgs): Promise<Draft<Policy>[]> {
    const primaryLives = await parseExpectedFields(args.expectedCsvFields, args.text)
    const policies: Draft<Policy>[] = []
    const fetchFirstMatchingAddress = firstMatchingAddressFetcher()

    const defaultPlanId = args.plans[0]?.id ?? undefined
    const getPlanId = (name: string) => args.plans.find(p => p.name === name)?.id ?? defaultPlanId

    for (let i = 0; i < primaryLives.length; i++) {
        if (!args.canMap()) {
            args.setMessage?.({
                style: 'ERROR',
                text: 'Upload cancelled'
            })
            console.warn('Upload cancelled')

            return []
        }

        const life = primaryLives[i]
        const address = await fetchFirstMatchingAddress(life.address)
        const policy = createPolicy(
            createLife({
                planId: getPlanId(life.planType),
                type: 'PRIMARY',
                ...life,
                dateOfBirth: parseDmyDate(life.dateOfBirth).getTime(),
                address: address,
            }),
            args.startDate
        )

        policies.push(policy)
        args.setMessage?.({
            style: 'PROGRESS',
            text: `Upload in progress: ${i + 1}/${primaryLives.length}. Please keep this window open.`
        })
    }

    return policies
}