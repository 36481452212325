import type { SubscriptionStackApi } from '@punnet/subscription-pure'
import { SubscriptionQuoteResponse } from '@punnet/subscription-pure'

export const persistQuote = async (subscriptionService: SubscriptionStackApi, request: SubscriptionQuoteResponse) => {
    const quoteId = request.subscription.id
    console.log(`persisting quote under id: ${quoteId}`, request)

    const response = subscriptionService.saveSmeDraftQuote({ quoteId }, request)
    console.log('saved!')
    return response
}