import { defineRelationships, type Principal, type Relationship, type Resource } from '@punnet/heirarchy-pure'
import { PrincipalTypeList, RelationshipTypeList, ResourceTypes } from './common'



export const QuoteRelationshipDefinition = defineRelationships({
    redisKeySpace: 'quote',
    resourceTypes: [ResourceTypes.QUOTE],
    relationshipTypes: RelationshipTypeList,
    principalTypes: PrincipalTypeList
})

export type QuoteRelationshipDefinitionType = typeof QuoteRelationshipDefinition
export type QuoteRelationship = Relationship<QuoteRelationshipDefinitionType>

export type QuoteResource = Resource<QuoteRelationshipDefinitionType>
export type QuoteRelationshipType = QuoteRelationshipDefinitionType['relationshipTypes'][number]
export type QuotePrincipal = Principal<QuoteRelationshipDefinitionType>

