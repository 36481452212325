import {assertNotNullish, isArray, isNullish, isObject, isString, last} from '@punnet/pure-utility-kit'
import {canBePaged, Page, PagePrimitive, PageValue,} from '../../primatives/page-primatives'
import {PageCache} from './PageCache'
import {isRepoHash, NULL_HASH, REPO_HASH_NULL, RepoHash} from '../../primatives/hash-primatives'
import {KeyPath} from '../../primatives/graph-primitives'
import equal from 'fast-deep-equal/es6'
import {IPageStore, PathScan} from '../../key-interfaces/IPageStore'
import {IPageIo} from '../../key-interfaces/IPageIo'
import {from, lastValueFrom, tap} from 'rxjs'

import {mapToPageInfoStream} from './mapToPageInfoStream'
import { RxOps } from '@punnet/rxjs-utility-kit'

export class PageStore implements IPageStore {

    pageCache: PageCache

    constructor(public readonly pageIo: IPageIo, public readonly pageCacheSize: number) {
        this.pageCache = new PageCache(pageCacheSize)
    }

    async store(node: unknown): Promise<RepoHash> {
        if (isRepoHash(node)) return node

        const pageInfoStream = mapToPageInfoStream(node)

        const pageInfos = await RxOps.collectFrom(pageInfoStream)

        const lastCommit = await lastValueFrom(
            this.pageIo.commitPageInfoStream(from(pageInfos)).pipe(
                tap(pageInfo => {
                    this.pageCache.set(pageInfo.repoHash, pageInfo.page)
                })
            ),
            {
                defaultValue: last(pageInfos)
            }
        )
        return lastCommit?.repoHash
    }


    async fetch<T = unknown>(repoHash: RepoHash): Promise<T | null> {
        assertNotNullish(repoHash)

        let layer = this.pageCache.gatherUncachedKeys(repoHash)

        while (layer.length) {
            const pages = await RxOps.collectFrom(this.pageIo.fetchPageInfoStream(from(layer)))
            pages.forEach(p => p && this.pageCache.set(p.repoHash, p.page))
            layer = layer.flatMap(uncachedHash => this.pageCache.gatherUncachedKeys(uncachedHash))
        }

        const recur = (pageKey: RepoHash): unknown => {
            const page = this.pageCache.get(pageKey)
            if (isString(page)) {
                return page
            } else if (isArray(page)) {
                return page.map(e => isRepoHash(e) ? recur(e) : e)
            } else if (isObject(page)) {
                return Object.fromEntries(
                    Object.entries(page).map(entry => {
                        const [k, v] = entry
                        return isRepoHash(v) ? [k, recur(v)] : [k, v]
                    })
                )
            }
        }
        return recur(repoHash) as T
    }


    async fetchPage(pageHash: RepoHash): Promise<Page | null> {
        assertNotNullish(pageHash)
        if (pageHash.toString() === NULL_HASH) {
            return null
        }

        let page = this.pageCache.get(pageHash)
        if (!page) {
            const pageInfo = await this.pageIo.fetchPage(pageHash)

            console.log('fetchPage', pageInfo, pageHash)
            
            page = pageInfo.page!
            this.pageCache.set(pageInfo.repoHash, page)
        }
        return page
    }


    async fetchAt<T = unknown>(rootPageHash: RepoHash, path: KeyPath = []): Promise<T | null> {
        assertNotNullish(rootPageHash)
        if (rootPageHash.equals(REPO_HASH_NULL)) {
            return null
        }

        if (!path.length) {
            return this.fetch<T>(rootPageHash)
        }

        const scan = await this.scanPath(rootPageHash, path)
        const lastEntry = last(scan)
        return isRepoHash(lastEntry?.value) ? this.fetch<T>(lastEntry!.value) : lastEntry?.value as T ?? null
    }



    async fetchPageAt(rootPageHash: RepoHash, path: KeyPath = []): Promise<Page | PagePrimitive> {

        assertNotNullish(rootPageHash)
        if (rootPageHash.equals(REPO_HASH_NULL)) {
            return null
        }

        if (!path.length) {
            return this.fetchPage(rootPageHash)
        }

        const scan = await this.scanPath(rootPageHash, path)
        const lastEntry = last(scan)
        return isRepoHash(lastEntry?.value) ? this.fetchPage(lastEntry!.value) : lastEntry?.value
    }




    async storeAt(rootPageHash: RepoHash, path: KeyPath, value: unknown): Promise<RepoHash | null> {
        assertNotNullish(rootPageHash)

        if (rootPageHash.equals(REPO_HASH_NULL) && path.length) {
            throw new Error(`Boom! path is not reachable, ${path} from pageKey NULL_HASH`)
        }

        const pathScan = await this.scanPath(rootPageHash, path)

        const currentRawValue = pathScan.length ? last(pathScan)!.value : rootPageHash

        if (equal(currentRawValue, value)) return null

        let newValue: PageValue | undefined = canBePaged(value) ? await this.store(value) : value as PageValue

        if (equal(currentRawValue, newValue)) return null

        for (const scanElement of pathScan.reverse()) {
            const node = await this.fetchPage(scanElement.pageKey)
            let newNode: Page
            if (isArray(node) && !isNaN(+scanElement.propKey)) {
                newNode = [...node]
                newNode[+scanElement.propKey] = newValue!
            } else if (isObject(node) && isString(scanElement.propKey)) {
                newNode = {...node}
                newNode[scanElement.propKey] = newValue!
            } else {
                throw `Cannot store value at path ${path} from pageKey ${rootPageHash}`
            }

            newValue = (await this.store(newNode))!
        }


        if (isRepoHash(newValue)) {
            return rootPageHash.equals(newValue) ? null : newValue
        } else {
            throw `Cannot store value at path ${path} from pageKey ${rootPageHash}`
        }
    }


    async scanPath(rootPageHash: RepoHash, path: KeyPath = []): Promise<PathScan> {
        const scan: PathScan = []

        for (const propKey of path) {
            const page = await this.fetchPage(rootPageHash)

            if (isString(page) || isNullish(page)) {
                break
            }
            else {
                const value = isArray(page) ? page[+propKey] : page[propKey]
                scan.push({pageKey: rootPageHash, propKey, value})
                if (isRepoHash(value)) {
                    rootPageHash = value
                } else {
                    break
                }
            }
        }

        if (path.length !== scan.length) {
            throw new Error(`Boom! path is not reachable, ${path} from pageKey ${rootPageHash}`)
        }

        return scan
    }
}
