import {Properties} from '@punnet/pure-utility-kit'
import { AnEvent } from '@punnet/core-event-pure'

import type { AccountType } from '../../../domain/models/Account'
import type { SubscriptionEventData } from '../../notifications/notification-types'


export class CompanyPolicyDocSendRequest extends AnEvent implements SubscriptionEventData {
     constructor(from: Properties<CompanyPolicyDocSendRequest>) {
        super()
        Object.assign(this, from)
    }
    accountId: string
    accountType: AccountType
    subscriptionId: string
}
