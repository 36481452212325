import {Observable, Subscription} from 'rxjs'
import {take} from 'rxjs/operators'

export function firstOrTimeout<T>(o: Observable<T>, timeout?: number): Promise<T> {

    let sub: Subscription
    let timeoutId: any

    return new Promise<T>((resolve, reject) => {
        sub = o
            .pipe(take(1))
            .subscribe({
                next: (value) => {
                    resolve(value)
                },
                error: (err) => {
                    reject(err)
                },
            })

        if (timeout) {
            timeoutId = setTimeout(() => {
                sub.unsubscribe()
                reject(`Promise timeout after ${timeout} millis`)
            }, timeout)
        }

    }).finally(() => {
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        sub.unsubscribe()
    })
}

