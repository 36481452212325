import { QuoteBuilder } from '../QuoteBuilder/QuoteBuilder'
import { QuoteFooter } from '../QuoteFooter/QuoteFooter'
import styles from './PlanDetails.module.css'

export const PlanDetails = () => {
    return (<>
        <section class={styles.QuoteBuilder}>
            <QuoteBuilder />
        </section>
        <QuoteFooter />
    </>)
}