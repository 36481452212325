import {concatMap, EMPTY, materialize, Observable, ObservableNotification, of} from 'rxjs'
import {JsonBuffer} from './JsonBuffer'


// observable pipeline function
export function bufferJson<T>(byteSize: number) {
    return (o: Observable<T>): Observable<JsonBuffer<T>> => {

        let jsonBuffer = new JsonBuffer<T>(byteSize)

        return o.pipe(
            materialize(),
            concatMap((note: ObservableNotification<T>): Observable<JsonBuffer<T>> => {
                switch (note.kind) {
                    case 'N': {
                        if (jsonBuffer.addItem(note.value)) {
                            return EMPTY
                        } else {
                            const bufferToEmit = jsonBuffer
                            jsonBuffer = new JsonBuffer<T>(byteSize)
                            jsonBuffer.addItem(note.value)
                            return of(bufferToEmit)
                        }
                    }
                    case 'C': {
                        return jsonBuffer.itemCount ? of(jsonBuffer) : EMPTY
                    }
                    case 'E':
                        throw note.error
                }
            }),
        )

    }
}
