import type { Plan } from "@punnet/subscription-pure"
import { createEffect, createSignal, For, Show, type Component } from "solid-js"
import type { MemberPolicyAction } from "../../../app/store/members/mapToMemberPolicies"
import { useMembersStoreActions } from "../../../app/store/members/membersStore"

export const LevelSelector: Component<{
    policyId: string,
    plans: Plan[],
    planId: string,
    action: MemberPolicyAction
    disabled?: boolean
}> = (props) => {
    const { updateMemberCover } = useMembersStoreActions()

    const plansInSelector = props.plans.map(p => p.name)

    const initialPlan = () => props.plans.find(p => p.id === props.planId)
    const initialPlanSelect = () => initialPlan() ? plansInSelector.find(p => p === initialPlan().name) : plansInSelector[0]

    const [currentPlan, setCurrentPlan] = createSignal()

    createEffect(() => {
        setCurrentPlan(initialPlanSelect())
    })

    const onPlanSelect = (event: SelectEvent) => {
        const selectedPlan = event.currentTarget.value
        const plan = props.plans.find(p => p.name === selectedPlan)

        updateMemberCover(props.policyId, plan.id, props.action)
    }

    return (
        <Show when={props.planId} fallback={'N/A'}>
            <select onChange={onPlanSelect} disabled={props.disabled}>
                <For each={plansInSelector}>{(plan: string) => <option value={plan} selected={plan === currentPlan()}>{plan}</option>}</For>
            </select>
        </Show>
    )
}

// TODO re-use this somewhere
type SelectEvent = Event & {
    currentTarget: HTMLSelectElement
}