
import {Draft, firstFrom, KeyMapped, mapBy, mapById, newUUID, omit, pick, values} from '@punnet/pure-utility-kit'
import { Account } from '../../models/Account'
import { Subscription } from '../../models/Subscription'
import { Life } from '../../models/Life'
import { LifeBenefit } from '../../models/LifeBenefit'

import {filterStatus} from '../alteration-kit/loose-end-kit'
import type { SubscriptionQuoteRequest } from '../../models/SubscriptionQuoteRequest'
import { QuoteLifeRequest, QuoteLife, QuoteBenefit, QuoteParameterSet } from '@punnet/quote-pure'

export function mapToSubscriptionQuoteRequest(
    account: Account,
    subscription?: Subscription
) {
    subscription ??= firstFrom(account?.subscriptions)

    const plans = values(subscription.plans)
    const policies = values(subscription.policies)

    const requestSubscription: Subscription = {
        ...subscription,
        policies: null,
        plans: null
    }

    const requestAccount: Account = {
        ...account,
        subscriptions: null
    }

    const request: SubscriptionQuoteRequest = {
        quoteRequestId: newUUID(),
        quoteRequestTimestamp: Date.now(),
        account: requestAccount,
        plans,
        policies,
        subscription: requestSubscription,
    }

    return request
}


export function mapToQuoteRequest(
    lives: KeyMapped<Draft<Life>>,
    quoteParameterSet?: QuoteParameterSet,
): QuoteLifeRequest {
    const quoteRequest: QuoteLifeRequest = {
        quoteRequestId: newUUID(),
        quoteRequestTimestamp: Date.now(),
        quoteParamDefaults: quoteParameterSet?.defaults,
        quoteParamOverrides: quoteParameterSet?.overrides,
        lives: mapToQuoteLives(lives)
    }

    return quoteRequest
}


function mapToQuoteLives(lives: KeyMapped<Draft<Life>>): KeyMapped<QuoteLife> {
    const quoteLives =
        filterStatus(values(lives), 'ACTIVE')
            .map(mapToQuoteLife)
    return mapById(quoteLives)
}

function mapToQuoteLife(life: Draft<Life>): QuoteLife {
    return {
        ...structuredClone(pick(life,
            'id',
            'planId',
            'type',
            'dateOfBirth',
            'postcode',
        )),
        gender: life.gender ?? 'BLENDED',
        region: life.address?.region ?? 'LONDON',
        benefits: mapToQuoteLifeBenefits(life.benefits),
    } as QuoteLife
}

function mapToQuoteLifeBenefits(lifeBenefits: KeyMapped<Draft<LifeBenefit>>): KeyMapped<QuoteBenefit> {
    const activeBenefits =
        filterStatus(values(lifeBenefits), 'ACTIVE')
            .map(mapToQuoteLifeBenefit)

    return mapBy(activeBenefits, b => b.id)
}

function mapToQuoteLifeBenefit(lifeBenefit: Draft<LifeBenefit>): QuoteBenefit {
    return {
        ...structuredClone(omit(lifeBenefit,
            'status',
            'endDate',
            'premium'
        )),
        id: lifeBenefit.id
    }
}

