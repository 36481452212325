import { AnEvent } from '@punnet/core-event-pure'


export class CancelSubscriptionRequest extends AnEvent {
    public accountId: string
    public subscriptionId: string

    public effectiveDate?: number
    public cancellationReason?: string

    constructor(from: CancelSubscriptionRequest) {
        super()
        Object.assign(this, from)
    }
}
