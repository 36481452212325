


export type AlterationAttribution = {
    actor: string,
    effectiveDate: number
    description: string
}


export type Alteration<T extends string> = AlterationAttribution & {
    id: string
    path: string[]
    type: T
    versionIdx: number
    from?: unknown
    to?: unknown
}


export function hasAlteration<T extends string>(alterationType: T, alterations: Alteration<T>[]) {
    return alterations.some(a => a.type = alterationType)
}


// helpers
export function prefixWith<const Prefix extends string, const Alterations extends readonly string[]>(
    prefix: Prefix,
    alterations: Alterations
): PrefixedAlteration<Prefix, Alterations[number]>[] {
    return alterations.map(alt => `${prefix}-${alt}` as PrefixedAlteration<Prefix, Alterations[number]>)
}


type PrefixedAlteration<Prefix extends string, Alteration extends string> = `${Prefix}-${Alteration}`


