import { AnEvent } from '@punnet/core-event-pure'


export class PolicyReposCreated extends AnEvent {

    policyId: string

    constructor(from: PolicyReposCreated) {
        super()
        Object.assign(this, from)
    }
}
