import {defineValidator, MessageProducer} from '../validation-core'
import {isEmail} from 'validator'

export default function IsEmail<T>(message?: string | MessageProducer<T>) {
    return defineValidator<T>({
        name: 'IsEmail',
        message:
            message ??
            (({property}) => `Property ${property.toString()} must be a valid email address`),
        validate({value}) {
            return value && isEmail(value)
        },
    })
}
