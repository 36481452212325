import {createEffect, onCleanup} from 'solid-js'

export function dynamicCssKeyboardModifierClasses() {


    createEffect(() => {

        const keyListener = (e: KeyboardEvent) => {
            if (e.shiftKey) {
                document.body.classList.add('shiftPressed')
            } else {
                document.body.classList.remove('shiftPressed')
            }
            if (e.altKey) {
                document.body.classList.add('altPressed')
            } else {
                document.body.classList.remove('altPressed')
            }
            if (e.ctrlKey) {
                document.body.classList.add('ctrlPressed')
            } else {
                document.body.classList.remove('ctrlPressed')
            }
            if (e.metaKey) {
                document.body.classList.add('metaPressed')
            } else {
                document.body.classList.remove('metaPressed')
            }
        }

        window.addEventListener('keydown', keyListener)
        window.addEventListener('keyup', keyListener)
        window.addEventListener('keypress', keyListener)

        onCleanup(() => {
            window.removeEventListener('keydown', keyListener)
            window.removeEventListener('keyup', keyListener)
            window.removeEventListener('keypress', keyListener)
        })
    })
}
