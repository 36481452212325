
export class InviteUserRequest {
    email: string
    firstName: string
    lastName: string
}

export class InviteUserResponse {
    id: string
}

export class InviteUserErrorResponse {
    message: string
}


export type CognitoUserAttributes<T = {}> = T & {
    email: string
    email_verified: boolean
    phone_number?: string
    phone_number_verified: boolean
    'custom:content_bucket_path'?: string
    given_name?: string
    family_name?: string
    sub: string
    'cognito:username': string
}


export type JwtClaims<T extends CognitoUserAttributes> = T & {
    aud: string
    auth_time: string
    event_id: string
    exp: string
    iat: string
    iss: string
    jti: string
    origin_jti: string
    token_use: 'id' | 'access'
}



export type UserProfile = {
    principalId: string
    principalType: string
    firstname: string
    lastname: string
    email: string
    phone?: string
    contentBucketPath?: string
    role?: string
}

export type DraftProfile<T extends UserProfile> = Omit<T, 'principalId'>


