import {DisburseResponse, Fund, Funds, groupReduceByNested, mapGroupDistinctBy} from '@peachy/utility-kit-pure'
import {Plan} from './Plan'
import {BenefitType, PlanYearId} from './types'
import {isNil, uniqBy} from 'lodash-es'
import {LifeId} from './Life'

type FundsKey = {
    lifeId: LifeId
    planYearId: PlanYearId
    benefitType: BenefitType
}

type FundsKeyFilter = Partial<FundsKey>

type TotalsSummary = {
    totalDisbursed: number
    totalRequested: number
    totalShortfall: number
}

type FundsByBenefit = Map<BenefitType, Funds>
type FundsByPlanYearBenefit = Map<PlanYearId, FundsByBenefit>
type FundsByLifePlanYearBenefit = Map<LifeId, FundsByPlanYearBenefit>

export type MemberFundsDisbursment = {
    fundsKey: FundsKey
    requestedAmount: number
    dateOfDisbursment: Date
    excess: DisburseResponse
    benefit: DisburseResponse
}

export class MemberFunds {

    protected readonly _history: MemberFundsDisbursment[] = []

    protected fundsByLifeIdPlanYearBenefit: FundsByLifePlanYearBenefit = new Map()

    private constructor(plans: Plan[]) {

        plans.forEach(plan => {

            // excess is shared across applicable benefits in a plan year, so initiate one excess fund per plan year...
            const initialExcessFundsByPlanYearId = mapGroupDistinctBy(
                plan.planYears,
                planYear => [planYear.id, {excess: planYear.excess, fund: new Fund(planYear.excess?.amountInPence ?? 0, {type: 'excess'})}]
            )

            const flattenedPlanYearBenefits = plan.planYears.flatMap(planYear => 
                planYear.cashLimitedBenefits.map(benefit => ({planYear, benefit}))
            )

            // benefit funds are per benefit per plan year
            const initialFundsByPlanYearBenefit = groupReduceByNested(
                flattenedPlanYearBenefits,
                it => [it.planYear.id, it.benefit.type],
                (_, {planYear, benefit}) => {
                    // share the excess funds per plan year benefit if it applies to the benefit
                    const excessFundForPlanYear = initialExcessFundsByPlanYearId[planYear.id]
                    const excessFund = excessFundForPlanYear.excess?.appliesTo(benefit.type) ? excessFundForPlanYear.fund : undefined
                    const benefitFund = new Fund(benefit.limitValue, {type: 'benefit'})
                    return Funds.wrapExisting([excessFund, benefitFund].filter(it => !!it))
            }) as Map<LifeId, Map<BenefitType, Funds>>

            this.fundsByLifeIdPlanYearBenefit.set(plan.life.id, initialFundsByPlanYearBenefit)
        })
    }

    public static initialFundsFor(plans: Plan[]) {
        return new MemberFunds(plans)
    }

    public static readableKey(key: FundsKeyFilter) {
        return [key.lifeId, key.planYearId, key.benefitType].filter(it => !!it).join('/')
    }

    public static keyMatchesFilter(filter: FundsKeyFilter, fundsKey: FundsKey) {
        const lifeFilterMatch = !filter?.lifeId || filter.lifeId === fundsKey.lifeId
        const planYearFilterMatch = !filter?.planYearId || filter.planYearId === fundsKey.planYearId
        const benefitFilterMatch = !filter?.benefitType || filter.benefitType === fundsKey.benefitType
        return lifeFilterMatch && planYearFilterMatch && benefitFilterMatch
    }

    public disburseFor(fundsKey: FundsKey, amountInPence: number, dateOfDisbursment = new Date()) {
        if (isNil(amountInPence)) {
            return {excess: undefined, benefit: undefined}
        }
        const funds = this.getFunds(fundsKey)
        const responses = funds?.disburseAmountFromEvery(amountInPence, dateOfDisbursment) ?? []
        const excess = responses.find(it => it.fundType === 'excess')
        const benefit = responses.find(it => it.fundType === 'benefit')
        this._history.push({fundsKey, dateOfDisbursment, requestedAmount: amountInPence, excess, benefit})
        return {excess, benefit}
    }

    public getFunds({lifeId, planYearId, benefitType}: FundsKey) {
        return this.fundsByLifeIdPlanYearBenefit.get(lifeId)?.get(planYearId)?.get(benefitType)
    }

    /**
     * @returns excess and benefit fund TotalsSummary per life and benefit type, ie. totals across all plan years per life benefit.
     */
    public getLifeBenefitTotals() {
        const lifeAndBenefitKeys = this.keys().map(({planYearId: _omit, ...lifeAndBenefitKeys}) => lifeAndBenefitKeys)
        return this.calculateTotals(lifeAndBenefitKeys)
    }

    /**
     * @returns excess and benefit fund TotalsSummary per life and plan year, ie. totals across all benefits per life plan year.
     */
    public getLifePlanYearTotals() {
        const lifeAndPlanYearKeys = this.keys().map(({benefitType: _omit, ...lifeAndPlanYearKeys}) => lifeAndPlanYearKeys)
        return this.calculateTotals(lifeAndPlanYearKeys)
    }

    /**
     * @returns excess and benefit fund TotalsSummary per life, ie. totals across all benefits and all plan years per life.
     */
    public getLifeTotals() {
        const lifeKeys = this.keys().map(({benefitType: _omit, planYearId: _alsoOmit, ...lifeKeys}) => lifeKeys)
        return this.calculateTotals(lifeKeys)
    }

    private reduceToTotals(disbursments: DisburseResponse[]) {
        return disbursments.reduce((totals, disbursement) => {
            totals.totalRequested += disbursement.requestedAmount
            totals.totalDisbursed += disbursement.disbursed
            totals.totalShortfall += disbursement.shortfall
            return totals
        }, {totalDisbursed: 0, totalRequested: 0, totalShortfall: 0} as TotalsSummary)
    }

    private calculateTotals<K extends FundsKeyFilter>(keysFilters: K[]) {
        const distinctKeys = uniqBy(keysFilters, it => JSON.stringify(it))

        return distinctKeys.reduce((collection, keysFilter) => {
            const historyForKeysFilter = this.getHistory(keysFilter)
            collection.push([keysFilter, {
                excess: this.reduceToTotals(historyForKeysFilter.map(it => it.excess).filter(it => !!it)),
                benefit: this.reduceToTotals(historyForKeysFilter.map(it => it.benefit))
            }] as const)
            return collection

        }, [] as unknown as [K, {excess: TotalsSummary, benefit: TotalsSummary}][])
    }

    public keys(filter?: FundsKeyFilter): FundsKey[] {
        const history = this.getHistory(filter)
        return history.map(it => it.fundsKey)
    }

    public getHistory(filter?: FundsKeyFilter) {
        const anyFilters = !!(filter?.lifeId || filter?.planYearId || filter?.benefitType)
        return anyFilters ? this._history.filter(it => MemberFunds.keyMatchesFilter(filter, it.fundsKey)) : this._history
    }

}
