
import { IamController } from '@punnet/iam-client'
import { SubscriptionController } from '@punnet/subscription-web-client'
import { KeyboardController, dynamicCssKeyboardModifierClasses, dynamicCssPageScrollTop } from '@punnet/web-client-kit-library'
import { Router } from "@solidjs/router"
import { getConfigurableRoutes } from './AppRoutes'
import { MainLayout } from './layouts/main-layout/MainLayout'
import { AccountProvider } from './services/AccountProvider'
import { AuthenticationController } from './services/authentication/AuthenticationController'
import { PermissionsController } from './services/permissions/PermissionsController'


export function App() {
    dynamicCssKeyboardModifierClasses()
    dynamicCssPageScrollTop()
    return (
        <KeyboardController>
            <IamController iamConfigType={'PortalIamConfig'}>
                <SubscriptionController>
                    <AuthenticationController>
                        <PermissionsController>
                            <AccountProvider>
                                <Router root={MainLayout}>
                                    {getConfigurableRoutes()}
                                </Router>
                            </AccountProvider>
                        </PermissionsController>
                    </AuthenticationController>
                </SubscriptionController>
            </IamController>
        </KeyboardController>
    )
}