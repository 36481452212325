import {Switchboard} from './Switchboard'
import {Call, OnCallEnd} from './Call'
import {CallBegin, CallPacket, isCallBegin} from './call-kit'
import {newUUID} from '@peachy/utility-kit-pure'
import {ofType, Socket} from '../index'
import {filter, Subscription} from 'rxjs'
import {AsyncDisposableResource} from '@peachy/utility-kit-disposable'

export type CallReceiver<M> = (call: Call<M>) => void


export class CallCenter<M> implements AsyncDisposableResource {

    private readonly switchBoard: Switchboard<M>
    private incomingSubscription: Subscription

     constructor(
        private socket: Socket<CallPacket<M>>,
        private callReceiver: CallReceiver<M>,
        socketSendTimeoutMillis: number,
        socketReceiptTimeoutMillis: number,
        private onCallEnd?: OnCallEnd
    ) {
        this.switchBoard = new Switchboard<M>(
            socket,
            socketSendTimeoutMillis,
            socketReceiptTimeoutMillis,
        )

        this.incomingSubscription = this.switchBoard.incomingMessages().pipe(
            filter(isCallBegin),
        ).subscribe((callBegin) => {
            const newCall = new Call(callBegin.callId, this.switchBoard, onCallEnd)
            callReceiver(newCall)
        })
    }

    public async makeCall(): Promise<Call<M>> {
        const callId = newUUID()
        await this.switchBoard.sendCallMessage(ofType<CallBegin>({
            callId,
            messageId: newUUID(),
            type: 'CallBegin'
        }))
        return new Call(callId, this.switchBoard, this.onCallEnd)
    }

    public async asyncDispose(): Promise<void> {
        this.incomingSubscription.unsubscribe()
        await this.switchBoard.asyncDispose()
    }

    public async [Symbol.asyncDispose]() {
        await this.asyncDispose()
    }
}
