import { Auth } from '@aws-amplify/auth'
import { useIamService } from '@punnet/iam-client'
import { Match, Switch, createContext, createEffect, createResource, useContext, type ParentComponent } from 'solid-js'
import { Permissions } from './Permissions'


const PermissionsContext = createContext<Permissions>()

async function load(): Promise<Permissions> {
    const token = (await Auth.currentSession()).getIdToken().decodePayload()
    // console.log('token', token)
    return new Permissions(token)
}

export const PermissionsController: ParentComponent = (props) => {
    const iamService = useIamService()

    const [permissions, { mutate: setPermissions }] = createResource(() => iamService.isSignedIn(), load)

    createEffect(() => {
        if (!iamService.isSignedIn()) {
            setPermissions()
        }
    })

    return (
        <Switch>
            <Match when={!iamService.isSignedIn()}>
                {props.children}
            </Match>
            <Match when={iamService.isSignedIn() && permissions()}>
                <PermissionsContext.Provider value={permissions()}>
                    {props.children}
                </PermissionsContext.Provider>
            </Match>
        </Switch>
    )
}

export const getPermissions = () => useContext(PermissionsContext)
