{
    "bounds": {
        "startDate": {
            "startDays": 0,
            "endDays": 90
        },
        "policies": {
            "total": {
                "min": 1,
                "max": 5000
            },
            "plan": {
                "min": 0,
                "max": 100
            }
        }
    },
    "validation": {
        "lives": {
            "age": {
                "primary": {
                    "min": 16
                },
                "secondary": {
                    "min": 18,
                    "max": 75
                },
                "dependant": {
                    "min": 0,
                    "max": 18
                }
            }
        }
    }
}