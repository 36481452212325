import 'reflect-metadata'
import {Address} from './Address'
import {joinWhereDefined} from '@peachy/utility-kit-pure'
import {Type} from 'class-transformer'

export class RegisteredGp {

    @Type(() => Address)
    readonly address: Address

    constructor(readonly id: string | undefined,
                readonly practiceName: string,
                address: Address,
                readonly nhsOrganisationId?: string) {
            this.address = address
    }

    toFormattedString(separator: string = '\n') {
        return joinWhereDefined(separator,
            this.practiceName,
            this.address.toFormattedString(separator)
        )
    }
}