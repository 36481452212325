import { keys } from './object-kit'
import type { KeyMapped } from './utility-types'

export type EnvironmentVariableSet = KeyMapped<string>
export type EnvironmentVariables<T extends EnvironmentVariableSet> = { [_k in keyof T]: string }


export function useEnvironmentVariables<const T extends EnvironmentVariableSet>(varSet: T): EnvironmentVariables<T> {

    
    
    const properties = keys(varSet)

    return Object.fromEntries(properties.map(property => {
        const name = varSet[property]
        const value = process.env[name]

        console.log(`resolving environment variable ${property} (${name}) = ${value}`)

        if (value === undefined) {
            throw new Error(`Environment variable ${property} is not defined. Set this to null if it is not required.`)
        }
        return [property, value]
    })) as EnvironmentVariables<T>
}


export function defineEnvironmentVariables<const T extends EnvironmentVariableSet>(varSet: T, values: EnvironmentVariables<T>): EnvironmentVariables<T> {
   
    const properties = keys(varSet)
    
    return Object.fromEntries(properties.map(property => {
        const name = varSet[property]
        const value = values[property]
        if (value === undefined) {
            throw new Error(`Environment variable ${property} (${name}) is not defined. Set this to null if it is not required.`)
        }
        return [name, value]
    })) as EnvironmentVariables<T>
}


export function setEnvironmentVariables<const T extends EnvironmentVariableSet>(varSet: T, values: EnvironmentVariables<T>) {
    const properties = keys(varSet)
    properties.forEach(property => {
        const name = varSet[property]
        const value = values[property]
        process.env[name] = value
    })
}