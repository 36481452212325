import 'reflect-metadata'
import {DecisionType, DecisionTypes} from './types'
import {Type} from 'class-transformer'
import {ApprovedClaimCosts} from './assessment/ApprovedClaimCosts'

export class Decision {

    @Type(() => Date)
    readonly date: Date

    constructor(date: Date,
                readonly type: DecisionType,
                readonly notes?: string,
                readonly approvedCosts?: ApprovedClaimCosts) {
        this.date = date
    }

    /**
     *  @deprecated it doesn't really make sense to show a total approved cost across (potentially) multiple plan years and benefits.  you should be more specific in what plan year / benefit cost you are after
     */
    get amountInPence() {
        return this.approvedCosts?.overallTotalApprovedInPence
    }

    isApprove() {
        return this.type === DecisionTypes.APPROVE
    }
}
