import {filter, Subject, Subscription} from 'rxjs'
import {CallEnd, CallId, CallUpdate, mapToIncomingUpdate$} from './call-kit'
import {ofType, Socket} from '../index'
import {Switchboard} from './Switchboard'
import {newUUID} from '@peachy/utility-kit-pure'

export type OnCallEnd = (callId: CallId, error?: any) => void


export class Call<M> implements Socket<M> {

    public in = new Subject<M>()

    private incomingSubscription: Subscription

    constructor(
        public readonly callId: CallId,
        private switchBoard: Switchboard<M>,
        private onCallEnd?: OnCallEnd,
    ) {
        switchBoard.incomingMessages().pipe(
            filter(p => p.callId === callId),
            mapToIncomingUpdate$(),
        ).subscribe(this.in)

        this.incomingSubscription = this.in.subscribe({
            complete: () => {
                setTimeout(() => {
                    onCallEnd?.(callId)
                }, 1) // tune this if necessary?
            },
            error: (err) => {
                setTimeout(() => {
                    onCallEnd?.(callId, err)
                }, 1) // tune this if necessary?
            }
        })
    }

    async out(message: M, timeout?: number) {
        await this.switchBoard.sendCallMessage(
            ofType<CallUpdate<M>>({
                type: 'CallUpdate',
                callId: this.callId,
                messageId: newUUID(),
                message
            }),
            timeout
        )
    }

    async callEnd(timeout?: number) {
        await this.switchBoard.sendCallMessage(
            ofType<CallEnd>({
                type: 'CallEnd',
                callId: this.callId,
                messageId: newUUID()
            }),
            timeout
        )
        this.in.complete()
        await this.asyncDispose()
    }


    async asyncDispose(): Promise<void> {
        this.incomingSubscription.unsubscribe()
    }

    async [Symbol.asyncDispose]() {
        await this.asyncDispose()
    }
}
