import styles from './CompanyCard.module.css'
import { Title } from './Title'
import { Item } from './Item'
import { getCurrentPolicies, getCurrentPolicyNumber, getCurrentSubscriptionStartDate } from '../../../app/services/AccountProvider'
import { readableDate } from '@punnet/pure-utility-kit'

export const CompanySchemeCard = () => {
    
    const policies = getCurrentPolicies()
    const reference = getCurrentPolicyNumber()
    const startDate = getCurrentSubscriptionStartDate()
    const readableStartDate = readableDate(startDate)

    return <div class={styles.CompanyCard}>
        <Title>Company reference {reference}</Title>
        <Item>Your scheme started on <b>{readableStartDate}</b></Item>
        <Item>You currently have <b>{policies.length} paid members</b> covered on your scheme</Item>
    </div>
}