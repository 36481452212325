import {concatMap, delay, Observable, of} from 'rxjs'


export function delayEachBy<T>(millis: number) {
    return (o: Observable<T>): Observable<T> => {
        return o.pipe(
            concatMap(e => of(e).pipe(delay(millis)))
        )
    }
}

