export type SubscribeFunction<R> = (listener: (request: R) => void) => () => void

export const createSubscription = <T,R>(
    subscribe: (listener: (state: T, prevState: T) => void) => () => void,
    shouldQuote: (state: T, prevState: T) => boolean,
    mapTo: (state: T) => R
): SubscribeFunction<R> => {

    const subscribeToQuotes: SubscribeFunction<R> = (listener: (request: R) => void) => {
        const unsubscribe = subscribe((state, prevState) => {
            if (shouldQuote(state, prevState)) {
                listener(mapTo(state))
            }
        })
        return unsubscribe
    }

    return subscribeToQuotes
}