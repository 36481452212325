import { v4 as uuidV4 } from 'uuid'
import {hashFor} from './hash-kit'


export function newUUID(...fromString: string[]): string {
    if (fromString.length) {
        const hash = hashFor(fromString.join('-'))
        return `${hash.slice(0, 8)}-${hash.slice(8, 12)}-4${hash.slice(12, 15)}-${hash.slice(15, 19)}-${hash.slice(19, 31)}`
    } else {
        return uuidV4()
    }
}

export function displayableId(uuid: string) {
    return uuid?.slice(0, 7)?.toUpperCase()
}
