import { createToggleSignal, DateBox } from '@punnet/web-client-kit-library'
import { type Component } from 'solid-js'
import { useManageLifeValidation, useManageTooltipMessages, useManageStoreActions, useManageStartDate } from '../../../../app/store/quotes/manageEmployeesStore'
import type { LifeRowCellProps } from '../ManageEmployeesTable'

export const BirthDateCell: Component<LifeRowCellProps> = (props) => {
    const { updateLife } = useManageStoreActions()
    const { dateOfBirth } = useManageLifeValidation()
    const { dateOfBirthTooltip } = useManageTooltipMessages()
    const isValid = () => dateOfBirth(props.life, useManageStartDate())
    const [isFocus, setIsFocus] = createToggleSignal(false)

    return <td title={isValid() ? '' : dateOfBirthTooltip(props.life)}>
        <DateBox placeholder={'dd/mm/yyyy'}
            selectedDate={props.life.dateOfBirth}
            revertOnBlurIfInvalid
            onDateEntered={dateOfBirth => updateLife(props.policy.id, { ...props.life, dateOfBirth })}
            onFocus={setIsFocus}
            onBlur={setIsFocus}
            inputClass={isFocus() || isValid() ? '' : props.errorStyles}
        />
    </td>
}
