import { createToggleSignal, TextBox } from '@punnet/web-client-kit-library'
import type { Component } from 'solid-js'
import { useManageLifeValidation, useManageStoreActions } from '../../../../app/store/quotes/manageEmployeesStore'
import type { LifeRowCellProps } from '../ManageEmployeesTable'
import tableStyles from '../ManageEmployeesTable.module.css'

export const LastNameCell: Component<LifeRowCellProps> = (props) => {
    const { updateLife } = useManageStoreActions()
    const { lastName } = useManageLifeValidation()

    const isValid = () => lastName(props.life)
    const [isFocus, setIsFocus] = createToggleSignal(false)

    return <td class={tableStyles.name}>
        <TextBox value={props.life.lastname ?? ''}
            onInput={lastname => updateLife(props.policy.id, { ...props.life, lastname: lastname.trim() })}
            placeholder={'Last name'}
            onFocus={setIsFocus}
            onBlur={setIsFocus}
            inputClass={isFocus() || isValid() ? '' : props.errorStyles}
        />
    </td>
}
