import { defineRelationships, type Principal, type Relationship, type Resource } from '@punnet/heirarchy-pure'

import { PrincipalTypeList, RelationshipTypeList, ResourceTypes } from './common'

export const SubscriptionRelationshipDefinition = defineRelationships({
    redisKeySpace: 'subscription',
    resourceTypes: [ResourceTypes.SUBSCRIPTION],
    relationshipTypes: RelationshipTypeList,
    principalTypes: PrincipalTypeList
})

export type SubscriptionRelationshipDefinitionType = typeof SubscriptionRelationshipDefinition
export type SubscriptionRelationship = Relationship<SubscriptionRelationshipDefinitionType>

export type SubscriptionResource = Resource<SubscriptionRelationshipDefinitionType>
export type SubscriptionRelationshipType = SubscriptionRelationshipDefinitionType['relationshipTypes'][number]
export type SubscriptionPrincipal = Principal<SubscriptionRelationshipDefinitionType>

