import {defineValidator, MessageProducer} from '../validation-core'

import type {CountryCode} from 'libphonenumber-js'
import * as libPhoneNumber from 'libphonenumber-js'

const {isValidPhoneNumber} = libPhoneNumber

export default function IsPhoneNumber<T>(
    countryCode: CountryCode = 'GB',
    message?: string | MessageProducer<T>
) {
    return defineValidator<T>({
        name: 'IsPhoneNumber',
        message:
            message ??
            (({property}) => `Property ${property.toString()} must be valid phone number`),
        validate({value}) {
            return value ? isValidPhoneNumber(value, countryCode) : true
        },
    })
}
