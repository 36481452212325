import {Subscription} from './Subscription'
import {Account} from './Account'

export type SubscriptionDto = Subscription

export type AccountDto = Omit<Account, 'subscriptions'>

export class AccountSubscriptionDto {
    public account: AccountDto
    public subscription: SubscriptionDto
}
