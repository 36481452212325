import {defaultEqualityFunction, defaultIdentityFunction, EqualityFunction, IdentityFunction} from '../diff/diff-kit'


export type LongestCommonSubsequenceOptions = {
    identityFunction?: IdentityFunction,
    equalityFunction?: EqualityFunction,
}

export type LongestCommonSequenceIndexes = [number[], number[]]
export type LongestCommonSequenceTable = number[][]



export function longestCommonSubsequence(
    seqA: unknown[],
    seqB: unknown[],
    options?: LongestCommonSubsequenceOptions
): [unknown[], number[][]] {

    const table = longestCommonSubsequenceTable(seqA, seqB, options)

    const lcs = []
    const indexes: number[][] = []

    let ai = seqA.length
    let bi = seqB.length
    let countdown = table[ai][bi] - 1

    while (countdown >= 0) {
        const itemA = seqA[ai - 1]
        const itemB = seqB[bi - 1]

        if (areEqual(itemA, itemB, options)) {
            lcs[countdown] = itemA
            bi--
            ai--
            indexes[countdown--] = [ai, bi]
        } else {
            const lengthA = table[ai - 1][bi]
            const lengthB = table[ai][bi - 1]

            if (lengthA > lengthB) {
                ai--
            } else {
                bi--
            }
        }
    }

    return [lcs, indexes]
}

export function longestCommonSubsequence3d(
    seqA: unknown[],
    seqB: unknown[],
    seqC: unknown[],
    options?: LongestCommonSubsequenceOptions
): [unknown[], number[][]] {
    const table = longestCommonSubsequenceTable3d(seqA, seqB, seqC, options)

    const lcs = []
    const indexes = []

    let ai = seqA.length
    let bi = seqB.length
    let ci = seqC.length
    let countdown = table[ai][bi][ci] - 1

    while (countdown >= 0) {
        const itemA = seqA[ai - 1]
        const itemB = seqB[bi - 1]
        const itemC = seqC[ci - 1]

        if (areEqual(itemA, itemB, options) && areEqual(itemA, itemC, options)) {
            lcs[countdown] = itemA
            ai--
            bi--
            ci--
            indexes[countdown--] = [ai, bi, ci]
        } else {

            const lengthA = table[ai - 1][bi][ci]
            const lengthB = table[ai][bi - 1][ci]
            const lengthC = table[ai][bi][ci - 1]

            if (lengthA < lengthB) {
                if (lengthB < lengthC) {
                    ci--
                } else {
                    bi--
                }
            } else {
                if (lengthA < lengthC) {
                    ci--
                } else {
                    ai--
                }
            }
        }
    }
    return [lcs, indexes]
}


export function longestCommonSubsequenceTable(
    seqA: unknown[],
    seqB: unknown[],
    options?: LongestCommonSubsequenceOptions
): LongestCommonSequenceTable {
    const table: number[][] = [new Array(seqB.length + 1).fill(0)]
    seqA.forEach((_) => table.push([0]))

    let ai: number
    let bi: number

    for (ai = 1; ai <= seqA.length; ai++) {
        for (bi = 1; bi <= seqB.length; bi++) {
            const itemA = seqA[ai - 1]
            const itemB = seqB[bi - 1]

            if (areEqual(itemA, itemB, options)) {
                table[ai][bi] = 1 + table[ai - 1][bi - 1]
            } else {
                table[ai][bi] = Math.max(table[ai - 1][bi], table[ai][bi - 1])
            }
        }
    }
    return table
}

export function longestCommonSubsequenceTable3d(
    seqA: unknown[],
    seqB: unknown[],
    seqC: unknown[],
    options?: LongestCommonSubsequenceOptions
) {
    const table: number[][][] = createUniformMatrix3d(
        seqA.length + 1, seqB.length + 1, seqC.length + 1,
        0
    )

    let ai: number
    let bi: number
    let ci: number

    for (ai = 1; ai <= seqA.length; ai++) {
        for (bi = 1; bi <= seqB.length; bi++) {
            for (ci = 1; ci <= seqC.length; ci++) {
                const itemA = seqA[ai - 1]
                const itemB = seqB[bi - 1]
                const itemC = seqC[ci - 1]

                if (areEqual(itemA, itemB, options) && areEqual(itemA, itemC, options)) {
                    table[ai][bi][ci] = 1 + table[ai - 1][bi - 1][ci - 1]
                } else {
                    table[ai][bi][ci] = Math.max(
                        table[ai - 1][bi][ci],
                        table[ai][bi - 1][ci],
                        table[ai][bi][ci - 1]
                    )
                }
            }
        }
    }
    return table
}

export function longestCommonSubsequenceBreakdown(
    a: unknown[],
    b: unknown[],
    options?: LongestCommonSubsequenceOptions
) {
    const [_, lcsIndexes] = longestCommonSubsequence(a, b, options)

    let i, j = 0

    const breakdown = []
    let lcsBuffer = []

    for (const [ai, bi] of lcsIndexes) {
        const sliceA = a.slice(i, ai)
        const sliceB = b.slice(j, bi)

        if (sliceA.length || sliceB.length) {
            breakdown.push([lcsBuffer])
            breakdown.push([sliceA, sliceB])
            lcsBuffer = []
        }
        [i, j] = [ai + 1, bi + 1]
        lcsBuffer.push(a[ai])
    }

    if (lcsBuffer.length) {
        breakdown.push([lcsBuffer])
    }
    return breakdown
}

export function longestCommonSubsequenceBreakdown3d(
    a: unknown[],
    b: unknown[],
    c: unknown[],
    options?: LongestCommonSubsequenceOptions
) {
    const [_, lcsIndexes] = longestCommonSubsequence3d(a, b, c, options)

    let i, j, k = 0
    const breakdown = []
    let lcsBuffer = []

    for (const [ai, bi, ci] of lcsIndexes) {
        const sliceA = a.slice(i, ai)
        const sliceB = b.slice(j, bi)
        const sliceC = c.slice(k, ci)

        if (sliceA.length || sliceB.length || sliceC.length) {
            breakdown.push([lcsBuffer])
            breakdown.push([sliceA, sliceB, sliceC])
            lcsBuffer = []
        }
        [i, j, k] = [ai + 1, bi + 1, ci + 1]
        lcsBuffer.push(a[ai])
    }

    if (lcsBuffer.length) {
        breakdown.push([lcsBuffer])
    }
    return breakdown
}


function areEqual(a: unknown, b: unknown, options?: LongestCommonSubsequenceOptions) {
    const identityFunction = options?.identityFunction ?? defaultIdentityFunction
    const equalityFunction = options?.equalityFunction ?? defaultEqualityFunction
    return equalityFunction(identityFunction(a), identityFunction(b))
}


function createUniformMatrix3d(xd: number, yd: number, zd: number, fill: number) {
    const matX: number[][][] = []
    for (let x = 0; x < xd; x++) {
        const matY: number[][] = []
        for (let y = 0; y < yd; y++) {
            const matZ: number[] = []
            for (let z = 0; z < zd; z++) {
                matZ.push(fill)
            }
            matY.push(matZ)
        }
        matX.push(matY)
    }
    return matX
}
