import type { Life, LifecycleStatus, Policy } from "@punnet/subscription-pure"
import type { Draft } from "@punnet/pure-utility-kit"

// TODO: sort this out (maybe just rename)

export type MemberPolicyAction = 'IDLE' | 'ADD' | 'REMOVE' | 'CHANGE_COVER' | 'CANCEL'

export type MemberPolicy = {
    id: string
    status: LifecycleStatus
    startDate: number
    action: MemberPolicyAction
    // TODO refactor not to use life and create model on data we need
    primary: Draft<Life>
    secondary?: Draft<Life>
}


export const mapToMemberPolicies = (policies: Draft<Policy>[], action: MemberPolicyAction) => {
    return policies.map(p => {
        const lives = Object.values(p.lives)
        const primaryLife = lives.find(p => p.type === 'PRIMARY')
        const secondaryLife = lives.find(p => p.type === 'SECONDARY')

        return {
            id: p.id,
            action,
            primary: primaryLife,
            secondary: secondaryLife ? secondaryLife : undefined
        } as MemberPolicy
    })
}

export const mapToDraftPolicies = (members: MemberPolicy[]): Draft<Policy>[] => {
    // Primary life only
    return members.map(p => {
        return {
            id: p.id,
            status: p.status,
            lives: {
                [p.primary.id]: p.primary,
            }
        } as Draft<Policy>
    })
}

export const getAddedMemberPolicies = (memberPolicies: MemberPolicy[]) => {
    return memberPolicies.filter(p => p.action === 'ADD')
}

export const getRemovedMemberPolicies = (memberPolicies: MemberPolicy[]) => {
    return memberPolicies.filter(p => p.action === 'REMOVE')
}

export const getChangedMemberPolicies = (memberPolicies: MemberPolicy[]) => {
    return memberPolicies.filter(p => p.action === 'CHANGE_COVER')
}

export const getAlteredMemberPolicies = (memberPolicies: MemberPolicy[]) => {
    return memberPolicies.filter(p => p.action !== 'IDLE')
}
