import type { Pence } from '@peachy/core-domain-pure'

export class PlanBenefit {
    id: string
    limit?: Pence
    excess?: Pence
    benefitSpecificExcess?: boolean
}




