import {defineValidator, MessageProducer} from '../validation-core'
import cardValidator from 'card-validator'

export default function IsPaymentCardNumber<T>(
    message?: string | MessageProducer<T>
) {
    return defineValidator<T>({
        name: 'IsPaymentCardNumber',
        message: 'Please enter a valid payment card number',
        validate({value}) {
            const result = cardValidator.number(value)
            return result.isValid
        },
    })
}
