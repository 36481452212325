
export async function fetchServiceConfig<T = any>(service: string, servicePatchUri: string): Promise<T> {
    const serviceConfigUrl = `${servicePatchUri}/${service}.json`

    const response = await fetch(serviceConfigUrl, {
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Expires': '0'
        }}
    )

    return parseJson(response)
}

async function parseJson(response: Response) {
    const text = await response.text()
    try {
        return JSON.parse(text)
    } catch (e) {
        console.error(`Error parsing response: ${text}`)
        throw e
    }
}
