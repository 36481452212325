import { type Draft } from '@punnet/pure-utility-kit'
import { Life, Plan, Policy } from '@punnet/subscription-pure'
import type { Accessor, Setter } from 'solid-js'
import { createWithStore } from 'solid-zustand'
import { validateLifeField } from '../../services/validation/validateLifeField'
import { createDraftPolicy } from './createPolicy'
import { findDuplicateEmails } from './findDuplicateEmails'
import { mapCsvToPolicies, type ProgressMessage } from './mapCsvToPolicies'
import { updateLife } from './updateLife'
import { validateDob } from '../../services/validation/validateDob'
import { AGE_BOUNDS } from '../../services/validation/validateLives'

export const MANAGE_EMPLOYEES_LIFE_VALIDATION: LifeValidation = {
    firstName: (life) => validateLifeField(life, 'firstname'),
    lastName: (life) => validateLifeField(life, 'lastname'),
    dateOfBirth: (life, startDate) => validateLifeField(life, 'dateOfBirth') && validateDob(startDate, life, AGE_BOUNDS),
    address: (life) => validateLifeField(life, 'address') && validateLifeField(life, 'postcode'),
    email: (life, duplicates) => validateLifeField(life, 'email') && !duplicates.includes(life.email)
}

export const MANAGE_EMPLOYEES_TOOLTIPS: Tooltips = {
    dateOfBirthTooltip: (life) => {
        switch (life.type) {
            case 'PRIMARY':
                return `Must be at least ${AGE_BOUNDS[life.type].minAge} on plan start date`
            case 'SECONDARY':
                return `Must be between ${AGE_BOUNDS[life.type].minAge} and ${AGE_BOUNDS[life.type].maxAge} on plan start date`
            case 'DEPENDANT':
                return `Children must be under ${AGE_BOUNDS[life.type].maxAge} on plan start date`
        }
    },
    addressTooltip: () => 'Address not recognised',
    emailTooltip: (life, duplicates) => {
        if (!validateLifeField(life, 'email')) {
            return 'Please enter a valid email address'
        } else if (duplicates.includes(life.email)) {
            return 'Please enter unique email addresses for all members'
        }

        return ''
    }
}

interface ManageEmployeesStoreInitProps {
    plans: Plan[]
    policies: Draft<Policy>[]
    startDate: number
    minPolicyCount: number
    lifeValidation: LifeValidation
    toolTips: Tooltips
}

interface ManageEmployeesStoreProps extends ManageEmployeesStoreInitProps {
    showModal: boolean
    duplicateEmails: string[]
    expectedCsvFields: string[]
}

export interface ManageEmployeesStoreActions {
    updateLife: (policyId: string, life: Draft<Life>) => void
    addPolicy: () => void
    removePolicy: (policy: Draft<Policy>) => void
    canRemovePolicy: () => boolean
    parseAddPolicies: (text: string, canMap: Accessor<boolean>, setMessage?: Setter<ProgressMessage>) => Promise<void>
    trackDuplicateEmails: () => void
    toggleModal: () => void
    init: (state: ManageEmployeesStoreInitProps) => void
}

export type LifeValidation = {
    firstName: (life: Draft<Life>) => boolean,
    lastName: (life: Draft<Life>) => boolean,
    dateOfBirth: (life: Draft<Life>, startDate: number) => boolean,
    address: (life: Draft<Life>) => boolean,
    email: (life: Draft<Life>, duplicates: string[]) => boolean,
}

export type Tooltips = {
    dateOfBirthTooltip: (life: Draft<Life>) => string,
    addressTooltip: () => string,
    emailTooltip: (life: Draft<Life>, duplicates: string[]) => string,
}

type ManageEmployeesStoreState = ManageEmployeesStoreProps & {
    actions: ManageEmployeesStoreActions
}

const EXPECTED_FIELDS = [
    'firstname',
    'lastname',
    'dateOfBirth',
    'address',
    'email',
    'planType'
]

const useManageStore = createWithStore<ManageEmployeesStoreState>((set, state) => ({
    showModal: false,
    plans: [],
    policies: [],
    startDate: Date.now(),
    minPolicyCount: 0,
    expectedCsvFields: EXPECTED_FIELDS,
    lifeValidation: {
        firstName: () => false,
        lastName: () => false,
        dateOfBirth: () => false,
        address: () => false,
        email: () => false,
    },
    toolTips: {
        dateOfBirthTooltip: () => '',
        addressTooltip: () => '',
        emailTooltip: () => '',
    },
    duplicateEmails: [],
    actions: {
        updateLife: (policyId: string, life: Draft<Life>) => set(state => ({ policies: updateLife(state.policies, policyId, life) })),
        addPolicy: () => set(state => ({
            policies: [...state.policies, createDraftPolicy(state.startDate, state.plans[0]?.id ?? undefined)]
        })),
        removePolicy: (policy: Draft<Policy>) => {
            set(state => ({ policies: state.policies.filter(p => p.id !== policy.id) }))

            // track only if the removed policy had a email
            if (Object.values(policy.lives).find(p => p.type === 'PRIMARY').email) {
                state().actions.trackDuplicateEmails()
            }

        },
        canRemovePolicy: () => canRemovePolicy(),
        //TODO Write a test and break this down into more function. Getting complex
        parseAddPolicies: async (text, canMap, setMessage) => {
            const bulkPolicies: Draft<Policy>[] = await mapCsvToPolicies({
                plans: state().plans,
                startDate: state().startDate,
                expectedCsvFields: state().expectedCsvFields,
                text,
                canMap: canMap,
                setMessage
            })

            const nonEmptyPolicies = state().policies.filter(p => {
                const life = Object.values(p.lives).find(l => l.type === 'PRIMARY')
                return life.firstname || life.lastname || life.dateOfBirth || life.address || life.email
            })

            const totalPoliciesCount = bulkPolicies.length + nonEmptyPolicies.length
            const emptyPolicies: Draft<Policy>[] = []

            if (totalPoliciesCount < state().minPolicyCount) {
                for (let i = 0; i < state().minPolicyCount - totalPoliciesCount; i++) {
                    emptyPolicies.push(createDraftPolicy(state().startDate, state().plans[0]?.id ?? undefined))
                }
            }

            set({ policies: [...nonEmptyPolicies, ...bulkPolicies, ...emptyPolicies] })
            // track after bulk update
            state().actions.trackDuplicateEmails()
        },
        trackDuplicateEmails: () => set(state => ({ duplicateEmails: findDuplicateEmails(state.policies) })),
        toggleModal: () => set(state => ({ showModal: !state.showModal })),
        init: (state: ManageEmployeesStoreInitProps) => set({
            ...state,
            duplicateEmails: findDuplicateEmails(state.policies)
        })
    }
}))

/* external state - exposed to the UI */
// export individually (better performance) - see https://tkdodo.eu/blog/working-with-zustand#prefer-atomic-selectors
export const useManagePlans = () => useManageStore(state => state.plans)
export const useManagePolicies = () => useManageStore(state => state.policies)
export const useManageMinPolicyCount = () => useManageStore().minPolicyCount
export const useManageStartDate = () => useManageStore().startDate
export const useManageDuplicateEmails = () => useManageStore(state => state.duplicateEmails)
export const useManageExpectedCsvFields = () => useManageStore(state => state.expectedCsvFields)
export const useManageLifeValidation = () => useManageStore(state => state.lifeValidation)
export const useManageTooltipMessages = () => useManageStore(state => state.toolTips)
export const useManageStoreActions = () => useManageStore<ManageEmployeesStoreActions>(state => state.actions)

// Tracked values
export const useManageStoreShowModal = () => useManageStore().showModal

const canRemovePolicy = (): boolean => useManagePolicies().length > useManageMinPolicyCount()