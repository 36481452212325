import { createSignal, onCleanup, onMount, type Accessor } from "solid-js"


export function createHasFocusSignal(ref:Accessor<HTMLElement>) {
    const [hasFocus, setHasFocus] = createSignal(false)
    onMount(() => {
        const onFocus = () => setHasFocus(true)
        const onBlur = () => setHasFocus(false)
        ref().addEventListener('focus', onFocus)
        ref().addEventListener('blur', onBlur)
        onCleanup(() => {
            ref().removeEventListener('focus', onFocus)
            ref().removeEventListener('blur', onBlur)
        })
    })
    return hasFocus
}
