import { ApiGatewayClient } from '@peachy/core-domain-client'
import { Company, defineApiGateway } from '@peachy/core-domain-pure'
import { BeneficialOwner, LookupCompanyRequest, LookupCompanyResponse } from './model/company-types'

export const CompanyLookupApiDefinition = defineApiGateway('CompanyLookupApi').withRoutes({
    lookupCompany: {
        method: 'POST',
        path: '/company/lookup',
        isPublic: true,
        requestType: LookupCompanyRequest,
        responseType: LookupCompanyResponse
    },
    lookupCharity: {
        method: 'POST',
        path: '/charity/lookup',
        isPublic: true,
        requestType: LookupCompanyRequest,
        responseType: LookupCompanyResponse
    },
    fetchCompany: {
        method: 'GET',
        path: '/company/{companyId}',
        isPublic: true,
        responseType: Company
    },
    fetchCompanyOwners: {
        method: 'GET',
        path: '/company/{companyId}/owners',
        isPublic: true,
        responseType: Array<BeneficialOwner>
    },
    fetchCharity: {
        method: 'GET',
        path: '/charity/{charityId}',
        isPublic: true,
        responseType: Company
    }
})

export type CompanyLookupApi = ApiGatewayClient<typeof CompanyLookupApiDefinition>