import styles from './CompanyCard.module.css'
import { Title } from './Title'
import { Item } from './Item'
import { getCurrentTotalMonthlyPremium } from '../../../app/services/AccountProvider'
import { Currency } from '../../PlanBuilder/controls/Currency'

export const CompanyPaymentCard = () => {

    const nextPayment = getCurrentTotalMonthlyPremium()
    const accountNumber = '****5678'
    const sortCode = '12-34-56'

    return <div class={styles.CompanyCard}>
        <Title>Your next payment will be <Currency value={nextPayment} /></Title>
        <Item>You pay by Direct Debit on the <b>1st</b> of every month</Item>
        <Item>Account number <b>{accountNumber}</b></Item>
        <Item>Sort code <b>{sortCode}</b></Item>
    </div>
}