import 'reflect-metadata'
import {NhsOrganisation} from './NhsOrganisation'
import {Type} from 'class-transformer'

export class NhsOrganisationLookupResult {

    @Type(() => NhsOrganisation)
    readonly organisations: NhsOrganisation[]

    constructor(readonly searchTerm: string,
                organisations: NhsOrganisation[]) {
        this.organisations = organisations
    }

    get isEmpty() {
        return this.organisations.length === 0
    }
}