import { batchOnConsecutive } from './pipeline-ops/batchOnConsecutive'
import { runSideEffect, withSideEffect } from './pipeline-ops/withSideEffect'
import { dumpStream } from './pipeline-ops/dumpStream'
import { firstOrTimeout } from './pipeline-ops/firstOrTimeout'
import { collectFrom } from './pipeline-ops/collectFrom'
import { drain } from './pipeline-ops/drain'
import { bufferJson } from './pipeline-ops/json-buffer/bufferJson'
import { filterAsync } from './pipeline-ops/filterAsync'
import { collect } from './pipeline-ops/collect'
import { tapLog } from './pipeline-ops/tapLog'
import { zipFilter } from './pipeline-ops/zipFilter'
export const RxOps = {
    batchOnConsecutive,
    withSideEffect,
    runSideEffect,
    drain,
    dumpStream,
    filterAsync,
    firstOrTimeout,
    collectFrom,
    collect,
    bufferJson,
    tapLog,
    zipFilter,

} as const
