import { createToggleSignal, TextBox } from '@punnet/web-client-kit-library'
import { type Component } from 'solid-js'
import { useManageDuplicateEmails, useManageLifeValidation, useManageTooltipMessages, useManageStoreActions } from '../../../../app/store/quotes/manageEmployeesStore'
import type { LifeRowCellProps } from '../ManageEmployeesTable'

export const EmailCell: Component<LifeRowCellProps> = (props) => {
    const [isFocus, setIsFocus] = createToggleSignal(false)

    const { updateLife, trackDuplicateEmails } = useManageStoreActions()
    const { email } = useManageLifeValidation()
    const { emailTooltip } = useManageTooltipMessages()
    const isValid = () => email(props.life, useManageDuplicateEmails())
    const onBlur = () => {
        setIsFocus()
        trackDuplicateEmails()
    }

    return (
        <td title={isValid() ? '' : emailTooltip(props.life, useManageDuplicateEmails())}>
            <TextBox value={props.life?.email}
                onInput={(email) => updateLife(props.policy.id, { ...props.life, email })}
                placeholder={'Email'}
                onFocus={setIsFocus}
                onBlur={onBlur}
                inputClass={isFocus() || isValid() ? '' : props.errorStyles}
            />
        </td>
    )
}
