export type ValidKey = string | number | symbol
export type Path = ValidKey[]

export function tracePath<const T>(o?: T): PathStep<T, []> {
    const path: ValidKey[] = []
    const proxyCallable = () => {
    }

    const proxy = new Proxy(proxyCallable, {
        get(_: unknown, p: ValidKey): any {
            if (p === '$') {
                return () => path
            } else {
                path.push(p)
                return proxy
            }
        },
    })
    return proxy as PathStep<T, []>
}


type PathStep<C, P extends Path = []> = {
    [k in keyof C]: PathStep<C[k], [...P, k]>
} & {
    $: () => P
}

export type TypeAtPath<O, P extends any[]> =
    P extends []
        ? O
        : P extends [infer K extends keyof O]
            ? O[K]
            : P extends [infer K extends keyof O, ...infer R extends Path]
                ? TypeAtPath<O[K], R>
                : never


export function valueAtPath<O, const P extends any[]>(o: O, p: P): TypeAtPath<O, P> {
    const a = p.reduce((a, c) => a?.[c], o)
    return a as TypeAtPath<O, P>
}
