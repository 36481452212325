import type { QuotePrincipal } from '../relationships/QuoteRelationshipDefinition'

export type QuoteStatus = 'DRAFT' | 'SHARED' | 'READY-TO-ACTIVATE'


export type QuoteSummaryRecord = {
    quoteId: string
    policyHolder: string
    postcode: string
    startDate: number
    expiryDate: number
    quoteDate: number
    members: number
    premium: number
}



export type QuoteSummary = QuoteSummaryRecord & {
    ownedBy: QuotePrincipal[]
    sharedWith: QuotePrincipal[]
}


export class QuoteSummaryList extends Array<QuoteSummary> {}
