import {LRUCache} from 'mnemonist'
import {Page} from '../../primatives/page-primatives'
import {isRepoHash, RepoHash, StringHash} from '../../primatives/hash-primatives'

export class PageCache {

    cache: LRUCache<StringHash, Page>

    constructor(cacheSize: number) {
        this.cache = new LRUCache<StringHash, Page>(cacheSize)
    }

    set(pageKey: RepoHash, page: Page){
        this.cache.set(pageKey.toString(), page)
    }

    get(pageKey: RepoHash) {
        return this.cache.get(pageKey.toString())
    }
    has (pageKey: RepoHash): boolean {
        return this.cache.has(pageKey.toString())
    }

    gatherUncachedKeys(pageKey: RepoHash, uncached: RepoHash[] = []): RepoHash[] {
        if (this.has(pageKey)) {
            const page = this.get(pageKey)
            const childPageKeys = getChildPagesKeys(page)
            for (const childKey of childPageKeys) {
                this.gatherUncachedKeys(childKey, uncached)
            }
        } else {
            uncached.push(pageKey)
        }
        return uncached
    }
}

function getChildPagesKeys(page: Page): RepoHash[] {
    return page ? Object.values(page).filter(isRepoHash) : []
}
