import { createEffect, createSignal, Show } from 'solid-js'
import { classList } from '../../functions/classList'
import { scrollToElement } from '../../functions/scrollToElement'
import { withViewTransition } from '../../functions/withViewTransition'
import { createLookupSignal, type LookupSignalOptions } from '../../signals/createLookupSignal'
import { Modal } from '../Modal/Modal'
import { Spinner } from '../Spinner/Spinner'
import styles from './Lookup.module.css'


export type LookupProps<T> = LookupSignalOptions<T> & {
    onSelect: (result: T) => void
    onError?: (error: Error) => void
    onFocus?: (value: string) => void
    onBlur?: (value: string) => void
    elementEquals?: (a: T, b: T) => boolean
    initialValue?: string
    placeholder?: string
    lookupInputClass?: string
    lookupListClass?: string
    spinnerClass?: string
    showHideTransitionMarkerClass?: string
    updateTransitionMarkerClass?: string
    enableTransitions?: boolean
    embedded?: boolean
}


export function Lookup<T>(props: LookupProps<T>) {
    const initialValue = props.initialValue ?? ''

    const lookupModel = createLookupSignal(props)

    const [deferredResults, setDeferredResults] = createSignal<T[]>()


    const shouldShowModal = () => {
        return !lookupModel.isStale() && !!deferredResults()?.length
    }

    let lookupListRef: HTMLUListElement
    const [lookupInputRef, setLookupInputRef] = createSignal<HTMLInputElement>()

    const triggerQuery = () => {
        lookupModel.setQuery(lookupModel.value())
    }
    const handleKey = (e: KeyboardEvent) => {
        ifKey(e, 'ArrowDown', () => {
            lookupModel.incrementCursorIndex()
            scrollToCursor()
        })
        ifKey(e, 'ArrowUp', () => {
            lookupModel.decrementCursorIndex()
            scrollToCursor()
        })
        ifKey(e, 'Enter', () => lookupModel.selectAtCursor() || triggerQuery())
        ifKey(e, 'Escape', () => lookupModel.resetSelection())
    }

    const scrollToCursor = () => scrollToElement(lookupListRef.querySelector('[data-highlighted]'))

    createEffect(() => {
        const selection = lookupModel.selection()
        props.onSelect?.(selection)
    })

    createEffect(() => {
        if (lookupModel.results.error) {
            props.onError?.(lookupModel.results.error)
        }
    })

    createEffect(() => {
        if (lookupModel.results()?.length) {
            withViewTransition({
                transitionMarkerClass: props.updateTransitionMarkerClass,
                condition: props.enableTransitions,
                updateDom: () => setDeferredResults(lookupModel.results())
            })
        } else {
            lookupModel.setIsStale(true)
        }
    })


    const lookupContent = (
        <ul ref={lookupListRef} onKeyDown={handleKey} class={classList(styles.lookupList, props.lookupListClass)}>
            {deferredResults()?.map((result, index) =>
                <li
                    data-highlighted={isHighlighted(index, lookupModel.cursorIndex())}
                    data-selected={isSelected(result, lookupModel.selection(), props)}
                    onClick={() => lookupModel.selectAtIndex(index)}
                    onMouseMove={() => lookupModel.setCursorIndex(index)}
                >{displayValue(result, props)}</li>
            )}
        </ul>
    )

    const displayInputValue = () => lookupModel.value().length ? lookupModel.value() : initialValue

    return (<>
        <input
            placeholder={props.placeholder}
            type="text"
            value={displayInputValue()}
            onFocus={e => props.onFocus?.(e.target.value)}
            onBlur={e => props.onBlur?.(e.target.value)}
            onInput={(e) => lookupModel.setQuery(e.target.value)}
            onKeyDown={handleKey}
            class={classList(styles.lookupInput, props.lookupInputClass)}
            onDblClick={triggerQuery}
            ref={setLookupInputRef}
        />

        <Show when={props.spinnerClass && lookupModel.results.loading}>
            <Spinner class={props.spinnerClass} />
        </Show>

        <Show when={!props.embedded} fallback={shouldShowModal() && lookupContent}>
            <Modal
                isOpen={shouldShowModal()}
                onDismiss={() => lookupModel.setIsStale(true)}
                trapFocus={false}
                locatorElement={lookupInputRef()}
                transitionMarkerClass={props.showHideTransitionMarkerClass}
                enableTransitions={props.enableTransitions}
            >
                {lookupContent}
            </Modal>
        </Show>
    </>)
}

function displayValue<T>(result: T, props: LookupProps<T>) {
    return props.elementDisplay?.(result) ?? result?.toString()
}

function isSelected<T>(result: T, selection: T, props: LookupProps<T>) {
    const equalityFunctiion = props.elementEquals ?? ((a: T, b: T) => a === b)
    return trueOrUndefined(equalityFunctiion(result, selection))
}
function isHighlighted<T>(index: number, cursorIndex: number) {
    return trueOrUndefined(index === cursorIndex)
}

export function trueOrUndefined(value: boolean): boolean | undefined {
    return value ? true : undefined
}


function ifKey(e: KeyboardEvent, key: KeyboardEvent['key'], thenDo: () => void) {
    if (e.key === key) {
        e.preventDefault()
        thenDo()
    }
}
