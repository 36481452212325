
export class Excess {

    constructor(
        public readonly id: string,
        public readonly amountInPence: number,
        public readonly benefitTypes: string[]
    ) {}

    public appliesTo?(givenBenefitType: string) {
        return this.benefitTypes.includes(givenBenefitType)
    }
}
