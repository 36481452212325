import {asDoc, hashFor, isObject, isString} from '@punnet/pure-utility-kit'

export const EmptyObject = {} as const
export const EmptyArray = [] as const
export const EmptyString = ''

export const EMPTY_OBJECT_HASH = hashFor(EmptyObject)
export const EMPTY_ARRAY_HASH = hashFor(EmptyArray)
export const EMPTY_STRING_HASH = hashFor(EmptyString)
export const NULL_HASH = hashFor(null)

export const HASH_LENGTH = 40

export type StringHash = string // regex type would be nice

export const HashConstants = {
    [EMPTY_ARRAY_HASH]: EmptyArray,
    [EMPTY_OBJECT_HASH]: EmptyObject,
    [NULL_HASH]: null as null,
    [EMPTY_STRING_HASH]: EmptyString,
}


export function possibleHashConstant(hash: StringHash | RepoHash): unknown {
    return HashConstants[hash?.toString()]
}

export function isHashConstant(hash: StringHash | RepoHash) {
    return hash?.toString() in HashConstants
}



export class RepoHash {

    hash: StringHash

    private constructor(key: StringHash) {
        this.hash = key.toString()
    }

    static from(thing: unknown) {
        return new RepoHash(hashFor(thing))
    }

    static for(hashString: StringHash) {
        return new RepoHash(hashString)
    }

    equals(hash: RepoHash) {
        return hash?.toString() === this.hash
    }

    toString(): string {return this.hash}
    toJSON(): string {return this.hash}
}


export function repoHashJsonReviver(k: unknown, v: unknown) {
    return isStringHash(v) ? RepoHash.for(v) : v
}


export const REPO_HASH_NULL = RepoHash.for(NULL_HASH)


export function isRepoHash(h: unknown): h is RepoHash {
    return h instanceof RepoHash && isStringHash(h.toString())
}

export function isStringHash(h: unknown): h is StringHash {
    return isString(h) && h.length === HASH_LENGTH && isHexString(h)
}


const HEX_REGEX = /^[0-9a-fA-F]+$/
export function isHexString(s: string) {
    return HEX_REGEX.test(s)
}


export function isEmptyObject(o: unknown) {
    return isObject(o)
}



if (!(
    EMPTY_ARRAY_HASH === '989db2448f309bfdd99b513f37c84b8f5794d2b5' &&
    EMPTY_OBJECT_HASH === '535c3001aaae9307daae192e1467b64126937576' &&
    EMPTY_STRING_HASH === '67cbf865735ab20856e1e6649eab1c4073bc341e' &&
    NULL_HASH === '109085beaaa80ac89858b283a64f7c75d7e5bb12'
)) {

    const hashes = {
        EmptyObjectHash: {
            expected: 'EmptyObjectHash',
            actual: EMPTY_OBJECT_HASH,
        },
        EmptyArrayHash: {
            expected: 'EmptyArrayHash',
            actual: EMPTY_ARRAY_HASH,
        },
        EmptyStringHash: {
            expected: 'EmptyStringHash',
            actual: EMPTY_STRING_HASH,
        },
        NullHash: {
            expected: 'NullHash',
            actual: NULL_HASH,
        },
    }

    const error = ['Invalid hash algorithm detected: ', asDoc(hashes)].join('')
    throw new Error(error)
}
