import {Dictionary} from './utility-types'
import {stringifyIfItCanBe, toObjectIfPossible} from './json-kit'
import {HttpMethod} from './http-kit'
import { ConsoleLogger, Logger } from './debug-kit'

export const STANDARD_JSON_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
}

const NO_CACHE_HEADERS = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0'
}

export type Authentication = {
    type: string,
    token: string
}

export type OptionalRequestConfig = {
    auth?: Authentication,
    headers?: Dictionary<string>
}

export class NupaHttpClient {

    private timeoutMillis = 5000
    private debugEnabled = false
    private headers = {}
    private fetchProvider = () => fetch
    private abortControllerProvider = () => new AbortController()

    constructor(private logger: Logger = new ConsoleLogger()) {
    }

    withFetchProvider(provider: () => any) {
        this.fetchProvider = provider
        return this
    }

    withAbortControllerProvider(provider: () => any) {
        this.abortControllerProvider = provider
        return this
    }

    withDebugging(value: boolean) {
        this.debugEnabled = value
        return this
    }

    withTimeout(millis: number) {
        this.timeoutMillis = millis
        return this
    }

    withNoTimeout() {
        this.timeoutMillis = undefined
        return this
    }

    withHeaders(headers: Dictionary<string>) {
        this.headers = headers
        return this
    }

    async POST(endpoint: string, body: object = {}, config?: OptionalRequestConfig) {
        return this.request('POST', endpoint, config, body)
    }

    async PUT(endpoint: string, body: object = {}, config?: OptionalRequestConfig) {
        return this.request('PUT', endpoint, config, body)
    }

    async PATCH(endpoint: string, body: object, config?: OptionalRequestConfig) {
        return this.request('PATCH', endpoint, config, body)
    }

    async GET(endpoint: string, config?: OptionalRequestConfig) {
        return this.request('GET', endpoint, config)
    }

    async DELETE(endpoint: string, config?: OptionalRequestConfig) {
        return this.request('DELETE', endpoint, config)
    }

    async request(method: HttpMethod, endpoint: string, config?: OptionalRequestConfig, body?: any) {
        return this.fetchWithTimeout(method, endpoint, config, body ? JSON.stringify(body) : undefined)
    }

    //todo auto retry on error? https://markmichon.com/automatic-retries-with-fetch
    async fetchWithTimeout(method: HttpMethod, endpoint: string, config?: OptionalRequestConfig, body?: any) {

        const headers = {...NO_CACHE_HEADERS, ...(config?.headers || this.headers || {})}
        const auth = config?.auth
        const timeout = this.timeoutMillis
        const controller = timeout && this.abortControllerProvider()
        const timeoutId = timeout && setTimeout(() => {
            this.debug(`Timing out request: ${method} ${endpoint}`, 'after', timeout, 'seconds')
            controller?.abort()
        }, timeout)

        let json
        try {
            const response = await this.fetchProvider()(endpoint, {
                method,
                // @ts-ignore
                timeout,
                signal: controller?.signal,
                headers: {...headers, ...(auth ? authorizationHeader(auth): {})},
                body
            })
            timeoutId && clearTimeout(timeoutId)
            this.debug(method, endpoint, body || '', stringifyIfItCanBe({headers}), 'RESP:', response.status, 'OK:', response.ok)
            //TODO: no clear indication to caller what happens on error - error type should be specified as a return type
            json = response.ok ? response.json().catch(e => undefined) : {status: response.status, ok: response.ok, error: toObjectIfPossible(await response.text())}
        } catch (e) {
            this.logger.error(e)
            this.debug('ERROR REQUESTING', method, endpoint, body || '', stringifyIfItCanBe({headers}), toObjectIfPossible(e))
            json = {error: e}
        }
        this.debug('RAW REQUEST', {method, endpoint, body, headers})
        this.debug('RAW RESPONSE', JSON.stringify(await json, null, 2), '\n\n######################################\n\n\n')
        return json
    }

    protected debug(...stuff: any[]) {
        this.debugEnabled && this.logger.debug(...stuff)
    }

}

function authorizationHeader(auth: Authentication) {
    return {Authorization: auth.type + ' ' + auth.token}
}
