import {createComputed, createSignal, ParentProps} from 'solid-js'
import {TextBox} from '../TextBox/TextBox'
import {formatDmyDate, parseDmyDate} from '@punnet/pure-utility-kit'
import {isEqual as areDatesEqual, isValid as isValidDate} from 'date-fns'

export type DateBoxProps = ParentProps & {
    selectedDate: number
    onDateEntered: (date: number) => void
    onChange?: (value: string) => void
    onBlur?: () => void
    onFocus?: () => void
    revertOnBlurIfInvalid?: boolean
    placeholder?: string
    inputClass?: string
    readonly?: boolean
    selectAllOnFocus?: boolean
}

export const DATEBOX_TEXT_LENGTH = 10
const MAX_SLASHES = 2
const MAX_TENS_MONTHS_DIGIT = 1
const MAX_TENS_DAYS_DIGIT = 3

export function DateBox(props: DateBoxProps) {

    const [rawValue, setRawValue] = createSignal('')

    const displaySelectedDate = () => {
        const display = formatDmyDate(props.selectedDate)
        setRawValue(display)
    }

    createComputed(() => {
        if (props.selectedDate) {
            displaySelectedDate()
        } else {
            setRawValue('')
        }
    })

    const onChange = (value: string) => {
        const prevValue = rawValue()
        setRawValue(value)

        value = value.replace(/[^\d\/]/g, '')
        const slashCount = value.replace(/[^/]/g, '').length

        if (slashCount > MAX_SLASHES || value.length > DATEBOX_TEXT_LENGTH || prevValue === value) {
            setRawValue(prevValue)
            return prevValue
        }

        setRawValue(prevValue.length > value.length ? value : formatDateText(value))

        if (!rawValue()) {
            props.onDateEntered(null)
        } else if (isValidDateString(rawValue()) && !areDatesEqual(parseDmyDate(rawValue()), new Date(props.selectedDate))) {
            props.onDateEntered(parseDmyDate(rawValue()).getTime())
        }
        props.onChange?.(rawValue())
    }

    const onBlur = () => {
        if (props.revertOnBlurIfInvalid) {
            displaySelectedDate()
        }
        props.onBlur?.()
    }


    return <TextBox
        placeholder={props.placeholder}
        value={rawValue()}
        onBlur={onBlur}
        onFocus={props.onFocus}
        onInput={onChange}
        inputClass={props.inputClass}
        readonly={props.readonly}
        selectAllOnFocus={props.selectAllOnFocus}
    >{props.children}</TextBox>
}


const ukDateFormat = /^\d\d\/\d\d\/\d\d\d\d$/

function isValidDateString(dateString: string) {
    return ukDateFormat.test(dateString) && isValidDate(parseDmyDate(dateString))
}

/**
 * Keep the text format to dd/mm/yyyy as close as possible
 * @param  {string}  value        Raw value of date text
 * @return {string}               Return a string in dd/mm/yyyy format
 */
function formatDateText(value: string) {

    const firstDigit = value.length === 1
    const withinMaxTensDigit = (digit: string, max: number) => parseInt(digit) <= max
    const isDigit = (rawDigit: string) => Number.isInteger(parseInt(rawDigit))
    const upToSecondLastChar = () => value.slice(0, -1)

    if (firstDigit) {
        return isDigit(value) ? withinMaxTensDigit(value, MAX_TENS_DAYS_DIGIT) ? value : `0${value}/` : ''
    }

    const lastChar = value.slice(-1)
    if (!isDigit(lastChar)) {
        return upToSecondLastChar()
    }

    const upToDayOrMonthSecondDigit = value.length === 2 || value.length === 5
    if (upToDayOrMonthSecondDigit) {
        return `${value}/`
    }

    const upToMonthSecondDigit = value.length === 4
    if (upToMonthSecondDigit) {
        return withinMaxTensDigit(lastChar, MAX_TENS_MONTHS_DIGIT) ? value : upToSecondLastChar() + `0${lastChar}/`
    }

    // edge cases where user may remove slashes
    const upToFirstSlash = value.length === 3
    if(upToFirstSlash) {
        const upToFirstSlashText = `${upToSecondLastChar()}/`
        return upToFirstSlashText + (withinMaxTensDigit(lastChar, MAX_TENS_MONTHS_DIGIT) ? lastChar : `0${lastChar}`)
    }

    const upToSecondSlash = value.length === 6
    if (upToSecondSlash) {
        return `${upToSecondLastChar()}/${lastChar}`
    }

    return value
}
