import {asPoundsAndPence, numberWithCommas} from '@punnet/pure-utility-kit'
import type {Component} from 'solid-js'

export const Price: Component<{ value: number }> = (props) => {

    const poundsDisplay = () => {
        const pounds = asPoundsAndPence(props.value)[0]
        return numberWithCommas(pounds)
    }

    const penceDisplay = () => {
        const pence = asPoundsAndPence(props.value)[1]
        return pence.toString().padStart(2, '0')
    }

    return (
        <>
            <span>£{poundsDisplay()}</span>
            <span>.{penceDisplay()}</span>
        </>
    )
}
