export { UtilityKit } from './src/UtilityKit'


// the following exports need to be reorganised or split out into their own modules


export * from './src/to-be-organised/any-kit'
export * from './src/to-be-organised/array-kit'
export * from './src/to-be-organised/byte-kit'
export * from './src/to-be-organised/object-kit'
export * from './src/to-be-organised/calendar-kit'
export * from './src/to-be-organised/collection-kit'
export * from './src/to-be-organised/currency-kit'
export * from './src/to-be-organised/date-util-kit'
export * from './src/to-be-organised/debug-kit'
export * from './src/to-be-organised/enum-kit'
export * from './src/to-be-organised/geometry-kit'
export * from './src/to-be-organised/hash-kit'
export * from './src/to-be-organised/stream-kit'
export * from './src/to-be-organised/json-kit'
export * from './src/to-be-organised/map-kit'
export * from './src/to-be-organised/markdown-kit'
export * from './src/to-be-organised/math-kit'
export * from './src/to-be-organised/mime-kit'
export * from './src/to-be-organised/promise-kit'
export * from './src/to-be-organised/string-kit'
export * from './src/to-be-organised/uuid-kit'
export * from './src/to-be-organised/bookmark-kit'
export * from './src/to-be-organised/Fund'
export * from './src/to-be-organised/service-provider'
export * from './src/to-be-organised/time-machine'
export * from './src/to-be-organised/envar-kit'
export * from './src/to-be-organised/NupaHttpClient'
export * from './src/to-be-organised/ClientCredentialsAuthProvider'
export * from './src/to-be-organised/http-kit'
export * from './src/to-be-organised/fluent-builder'
export * from './src/to-be-organised/tuple-kit'
export * from './src/to-be-organised/utility-types'
export * from './src/to-be-organised/type-check-kit'

        import * as me from '@punnet/pure-utility-kit'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/charlie-gould/git/peachy-repo/punnet/modules/utility-kit/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@punnet/pure-utility-kit'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    