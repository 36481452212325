export function groupDistinctBy<T, K extends string | number>(items: T[], groupFn: (item: T) => K) {
    return items.reduce((collector, item) => {
        const key = groupFn(item)
        collector[key] = item
        return collector
    }, {} as Record<K, T>)
}

export const mapBy = groupDistinctBy


