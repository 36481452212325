import { useSubscriptionService } from '@punnet/subscription-web-client'
import { RouteTransitionLink } from '@punnet/web-client-kit-library'
import { Suspense, createResource, type ParentComponent } from 'solid-js'
import { GreyButton } from '../../../components/Button/Buttons'
import { Pulse } from '../../../components/Pulse/Pulse'
import { QuotesList } from '../../../components/Quote/List/QuotesList'
import { useStoreActions } from '../../store/quotes/quoteAndBuyStore'
import { toClass } from '@punnet/model-validation-pure'
import { PageTitle } from '../PageTitle'
import { SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import styles from './Quotes.module.css'

export const Quotes: ParentComponent = () => {
    const subscriptionService = useSubscriptionService()

    const [quotes] = createResource(async () => subscriptionService.fetchQuoteSummaries())

    const onNewQuote = () => {
        useStoreActions.clear()
    }

    return (
        <div class={styles.Quotes}>
            <div>
                <PageTitle>Quotes</PageTitle>
            </div>

            <div>
                <RouteTransitionLink href="/quotes/new">
                    <GreyButton onClick={onNewQuote}>New quote</GreyButton>
                </RouteTransitionLink>
            </div>

            <div>
                <Suspense fallback={<Pulse isShown={true} />}>
                    <QuotesList quotes={quotes()} />
                </Suspense>
            </div>
        </div>
    )
}

