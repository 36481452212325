import type { ParentComponent } from 'solid-js'
import { classList } from '@punnet/web-client-kit-library'
import styles from './Footer.module.css'

export type FooterProps = {
    class?: string
}

export const Footer: ParentComponent<FooterProps> = (props) => {

    return (
        <div class={classList(styles.Footer, props.class)}>
            {props.children}
        </div>
    )
}