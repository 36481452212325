import { ApiGatewayClient } from '@peachy/core-domain-client'
import { Address, defineApiGateway } from '@peachy/core-domain-pure'
import { LookupAddressRequest, LookupAddressResponse } from './address-types'


export const AddressLookupApiDefinition = defineApiGateway('AddressLookupApi').withRoutes({
    lookupAddress: {
        method: 'POST',
        path: '/address/lookup',
        isPublic: true,
        requestType: LookupAddressRequest,
        responseType: LookupAddressResponse
    },
    fetchAddress: {
        method: 'GET',
        path: '/address/{addressId}',
        isPublic: true,
        responseType: Address
    },
})

export type AddressLookupApi = ApiGatewayClient<typeof AddressLookupApiDefinition>