import {render} from 'solid-js/web'

import {App} from './app/App'

import './css-global/index.css'

render(
    () => <App />,
    document.getElementById('app-root')
)
