import { mapToSubscriptionRequest } from '../../store/quotes/mapToSubscriptionRequest'
import { SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import { useStoreActions } from '../../store/quotes/quoteAndBuyStore'
import { useQuoteResponse } from '../../pages/Quotes/QuoteController/QuoteController'
import { useSavedSubscriptionQuote } from '../../pages/Quotes/PurchaseQuote/SubscriptionQuoteLoader'

export function getQuoteSubscriptionRequest(): SubscriptionQuoteResponse {
    const state = useStoreActions.getState()
    const quoteResponse = useQuoteResponse()
    return mapToSubscriptionRequest(state, quoteResponse())
}

export function getQuoteSubscriptionRequestWithSavedQuote(): SubscriptionQuoteResponse {
    const state = useStoreActions.getState()
    const quoteResponse = useQuoteResponse()
    const savedQuote = useSavedSubscriptionQuote()
    return mapToSubscriptionRequest(state, quoteResponse() ?? savedQuote)
}
