import {Policy, Subscription} from '@punnet/subscription-pure'
import {toClass, toPlain} from '@punnet/model-validation-pure'

import {ensureCancellationStatus} from '../reconciliation/valid-alterations/cancellation'
import {reconcilePolicyModelAlteration} from '../reconciliation/reconcilePolicyModelAlteration'
import {LifeAlterationAgent} from './LifeAlterationAgent'
import { AlterationAttribution } from '@punnet/alterations-pure'
export class PolicyAlterationAgent {

    private alteredPolicy: Policy

    constructor(
        private currentPolicy: Policy,
        private subscription: Subscription,
        private attribution: AlterationAttribution
    ) {
        this.subscription = toClass(subscription, Subscription)
        this.alteredPolicy = toPlain(currentPolicy)
    }

    public withLifeAlterationAgent(lifeId: string, alter: (lifeAlterationAgent:LifeAlterationAgent) => void) {
        const life = toClass(this.alteredPolicy, Policy).lives[lifeId]
        if (!life) throw `Life ${lifeId} does not exist on subscription ${this.subscription.id}`
        const lifeAlterationAgent = new LifeAlterationAgent(life, this.subscription, this.attribution)
        alter(lifeAlterationAgent)
        this.subscription.policies[this.alteredPolicy.id].lives[life.id] = lifeAlterationAgent.getAlteredLife()
        this.reconcile()
    }

    public alterPolicy(alteredPolicy: Policy) {
        this.alteredPolicy = alteredPolicy
        this.reconcile()
    }

    public cancelPolicy(cancellationReason: string) {
        ensureCancellationStatus(this.alteredPolicy, this.attribution.effectiveDate, cancellationReason)
        this.reconcile()
    }

    private reconcile() {
        this.alteredPolicy = reconcilePolicyModelAlteration(
            this.currentPolicy,
            this.alteredPolicy,
            this.subscription.plans,
            this.attribution.effectiveDate
        )
    }

    public getAlteredPolicy() {
        return this.alteredPolicy
    }


}
