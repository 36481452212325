import { A, useNavigate, type AnchorProps } from '@solidjs/router'
import type { Component } from 'solid-js'
import { withViewTransition } from '../../functions/withViewTransition'

export type RouteTransitionLinkProps = AnchorProps



export function RouteTransitionLink(props: RouteTransitionLinkProps) {
    const navigate = useNavigate()

    const handleNavigation = (event: Event) => {
        event.preventDefault()
        withViewTransition({
            transitionMarkerClass: 'routeTransitionLink',
            updateDom: () => {
                navigate(props.href)
            }
        })
    }
    return <A href={props.href} onClick={handleNavigation}>{props.children}</A>
}