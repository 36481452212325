import { Toggle } from '@punnet/web-client-kit-library'
import { useNavigate } from '@solidjs/router'
import { DirectDebitLogo } from '@sovereign/assets'
import type { Component } from 'solid-js'
import { Show } from 'solid-js'
import { createStore } from 'solid-js/store'
import { PrimaryButton } from '../../../../../components/Button/Buttons'
import { nextPage } from '../../../../../components/PageRouter/PageRouter'
import { getPermissions } from '../../../../services/permissions/PermissionsController'
import { useStoreActions } from '../../../../store/quotes/quoteAndBuyStore'
import { Label, Title } from '../../Quote'
import quoteStyles from '../../Quote.module.css'
import { FormSection, TextSection } from '../../Section'
import { CompanySchemeHeader } from '../CompanySchemeHeader'
import { NavigationButtons } from '../NavigationButtons'
import { useQuotePersister } from '../PurchaseQuote'
import styles from './DirectDebitDetails.module.css'


// TODO: this is used by both admin and finance users - refactor!
export const DirectDebitDetails = () => {
    const permissions = getPermissions()
    const navigate = useNavigate()
    const state = useStoreActions.getState()
    const quotePersister = useQuotePersister()

    const [fields, update] = createStore({
        accountName: state.directDebitDetails?.accountName ?? '',
        sortCode: state.directDebitDetails?.sortCode ?? '',
        accountNumber: state.directDebitDetails?.accountNumber ?? '',
        collectionDate: state.directDebitDetails?.collectionDate ?? 1,
        agreeTerms: state.directDebitDetails?.agreeTerms ?? false
    })
    
    const isValid = () => {
        return fields.accountName && 
            fields.sortCode && 
            fields.accountNumber && 
            fields.collectionDate && 
            fields.agreeTerms
    }

    const onNext = async () => {
        useStoreActions.setDirectDebitDetails({ 
            accountName: fields.accountName, 
            sortCode: fields.sortCode, 
            accountNumber: fields.accountNumber, 
            collectionDate: fields.collectionDate, 
            agreeTerms: fields.agreeTerms 
        })
        await quotePersister.persist()

        if (permissions.isFinanceUser()) {
            navigate('/quotes/dd-only-success')
        } else {
            nextPage()
        }
    }

    return <>
        <TextSection>
            <CompanySchemeHeader />
            <p>Please add direct debit details and agree to the Direct Debit guarantee, and hit Submit once you are ready</p>
        </TextSection>

        <FormSection>
            <Title>Payment:</Title>
            <div class={styles.DirectDebitLabel}>
                <Label>Direct Debit details</Label>
                <div class={styles.DirectDebitLogo}><DirectDebitLogo /></div>
            </div>

            <div class={quoteStyles.Section}>
                <p>You have chosen to set up a Variable Direct Debit.</p>
                <p>Subject to your rights under The Direct Debit Guarantee, this will automatically debit payments due on your account from your bank or building society account.</p>
                <p>If you are a joint signatory on the account, a paper direct debit instruction can be downloaded for completion.</p>
            </div>

            <div class={quoteStyles.Section}>
                <DirectDebitForm fields={fields} update={update} />
            </div>

            <div class={quoteStyles.Section}>
                <label>
                    <input required type="checkbox" name="agree" checked={fields.agreeTerms} onChange={(e) => update('agreeTerms', e.target.checked)} />
                    I understand and agree to the Terms and Conditions
                </label>
            </div>

            <div class={quoteStyles.Section}>
                <DirectDebitCollectionDate fields={fields} update={update}  />
            </div>
        </FormSection>

        <TextSection>
            <Show when={!permissions.isFinanceUser()}>
                <NavigationButtons nextButtonDisabled={!isValid()} onNext={onNext} />
            </Show>
            {/* TODO: this is a bit of a hack to get the buttons to work for both admin and finance users */}
            <Show when={permissions.isFinanceUser()}>
                <NavigationButtons>
                    <PrimaryButton disabled={!isValid()} onClick={onNext}>Submit</PrimaryButton>
                </NavigationButtons>
            </Show>
        </TextSection>
    </>
}

const DirectDebitForm: Component<{ fields: any, update: any }> = (props) => {
    const store = props.fields, updateField = props.update

    const formatSortCode = (sortCode: string) => {
        const digits = sortCode.replace(/-/g, '')
        return digits.replace(/(\d{2})(?=\d)/g, '$1-')
    }
    
    const handleSortCodeInput = (e: any) => {
        e.target.value = formatSortCode(e.target.value)
        updateField('sortCode', e.target.checkValidity() ? e.target.value : '')
    }

    const handleAccountNameInput = (e: any) => updateField('accountName', e.target.checkValidity() ? e.target.value : '')
    const handleAccountNumberInput = (e: any) => updateField('accountNumber', e.target.checkValidity() ? e.target.value : '')
    const handleAccountNameChange = (e: any) => updateField('accountName', store.accountName)
    const handleSortCodeChange = (e: any) => updateField('sortCode', store.sortCode)
    const handleAccountNumberChange = (e: any) => updateField('accountNumber', store.accountNumber)

    return (
        <>
            <div class={styles.Form}>
                <input 
                    name="accountName" 
                    class={styles.AccountName} 
                    type="text" 
                    placeholder="Account name" 
                    required 
                    value={store.accountName} 
                    onInput={handleAccountNameInput} 
                    onChange={handleAccountNameChange} 
                    autocomplete="off" 
                />
                
                <input 
                    name="sortCode" 
                    class={styles.SortCode} 
                    type="text" 
                    placeholder="Sort code" 
                    required 
                    minLength={8} 
                    maxLength={8} 
                    value={store.sortCode} 
                    onInput={handleSortCodeInput} 
                    onChange={handleSortCodeChange} 
                    autocomplete="off" 
                />
                
                <input 
                    name="accountNumber" 
                    class={styles.AccountNumber} 
                    type="text" 
                    placeholder="Account number" 
                    required 
                    minLength={8} 
                    maxLength={8} 
                    value={store.accountNumber} 
                    onInput={handleAccountNumberInput} 
                    onChange={handleAccountNumberChange} 
                    autocomplete="off" 
                />
            </div>
            <div class={styles.BillingAddress}>
                <span>Different billing address to company address?</span>
                <Toggle isOn={false} onToggle={() => { }} />
            </div>
        </>
    )
}

const DirectDebitCollectionDate: Component<{ fields: any, update: any }> = (props) => {
    const store = props.fields, updateField = props.update

    return <>
        <Label>Direct Debit collection date</Label>
        <p>Your Direct Debit collection date will default to your company scheme start date, however you can choose a different billing date below</p>
        <div class={styles.CollectionDate}>
            <select name="collectionDate" required value={store.collectionDate} onChange={(e) => updateField('collectionDate', parseInt(e.target.value))}>
                <option value="1">1st of the month</option>
                <option value="2">2nd of the month</option>
                <option value="3">3rd of the month</option>
                <option value="4">4th of the month</option>
                <option value="5">5th of the month</option>
                <option value="6">6th of the month</option>
                <option value="7">7th of the month</option>
                <option value="8">8th of the month</option>
                <option value="9">9th of the month</option>
                <option value="10">10th of the month</option>
                <option value="11">11th of the month</option>
                <option value="12">12th of the month</option>
                <option value="13">13th of the month</option>
                <option value="14">14th of the month</option>
                <option value="15">15th of the month</option>
                <option value="16">16th of the month</option>
                <option value="17">17th of the month</option>
                <option value="18">18th of the month</option>
                <option value="19">19th of the month</option>
                <option value="20">20th of the month</option>
                <option value="21">21st of the month</option>
                <option value="22">22nd of the month</option>
                <option value="23">23rd of the month</option>
                <option value="24">24th of the month</option>
                <option value="25">25th of the month</option>
                <option value="26">26th of the month</option>
                <option value="27">27th of the month</option>
                <option value="28">28th of the month</option>
                <option value="29">29th of the month</option>
                <option value="30">30th of the month</option>
                <option value="31">31st of the month</option>
            </select>
        </div>
    </>
}