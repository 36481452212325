import { Show, type ParentComponent } from 'solid-js'
import { hasNextPage, nextPage } from './PageRouter'

export interface NextProps {
    onClick?: (event: any) => Promise<void>
}

export const Next: ParentComponent<NextProps> = (props) => {
    const onClick = async (event: any) => {
        props.onClick && await props.onClick?.(event)
        nextPage()
    }

    return <Show when={hasNextPage()} fallback={<span />}>
        <div onClick={onClick}>{props.children}</div>
    </Show>
}
