import {createSignal, onCleanup, onMount} from 'solid-js'
import type { KeyboardKey } from './keyboardKeys'



export function useKeyUpSignal(key?: KeyboardKey) {
    const [keyup, setKeyup] = createSignal<KeyboardEvent>()

    onMount(() => {
        const handler = (e: KeyboardEvent) => {
            if (key && e.key === key) {
                console.log('SET!', e.key, key)
                setKeyup(e)
                setKeyup(undefined)
            } else {
                console.log('UNSET!', e.key, key)
                setKeyup(undefined)
            }
        }
        window.addEventListener('keyup', handler)
        onCleanup(() => {
            window.removeEventListener('keyup', handler)
        })
    })
    return keyup
}
