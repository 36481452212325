import { createSignal } from 'solid-js'
import { useStoreActions } from '../../../../store/quotes/quoteAndBuyStore'
import { Label, Title } from '../../Quote'
import styles from '../../Quote.module.css'
import { FormSection, TextSection } from '../../Section'
import { CompanySchemeHeader } from '../CompanySchemeHeader'
import { NavigationButtons } from '../NavigationButtons'
import { useQuotePersister } from '../PurchaseQuote'

export const Upgrades = () => {
    const state = useStoreActions.getState()
    const quotePersister = useQuotePersister()

    const [upgrade, setUpgrade] = createSignal<boolean | undefined>(state.allowUpgradePlanLevels)
    const [addAdult, setAddAdult] = createSignal<boolean | undefined>(state.allowAddAdultToPlan)

    const isValid = () => upgrade() !== undefined && addAdult() !== undefined

    const onNext = async () => {
        useStoreActions.setAllowUpgradePlanLevels(upgrade())
        useStoreActions.setAllowAddAdultToPlan(addAdult())
        await quotePersister.persist()
    }

    return (
        <>
            <TextSection>
                <CompanySchemeHeader />
                <p>Set your employee upgrades, and notification preferences for allowed upgrades.</p>
                <p>You can also do this later in the employer admin portal.</p>
            </TextSection>

            <FormSection>
                <Title>Employee upgrades:</Title>

                <div class={styles.Section}>
                    <Label>Upgrade plan levels</Label>
                    <p>Allow employees to select a higher level of cover, if available</p>
                    <p>
                        Charges will apply to your payment method and appear on your invoice.
                        These charges can be recovered from the employee via payroll deduction
                    </p>

                    <label><input required type="radio" name="upgrade" value={'yes'} checked={upgrade() === true} onChange={() => setUpgrade(true)}/>Yes</label>
                    <label><input required type="radio" name="upgrade" value={'no'} checked={upgrade() === false} onChange={() => setUpgrade(false)}/>No</label>
                </div>

                <div class={styles.Section}>
                    <Label>Add an adult to their plan</Label>
                    <p>Allow employees to add one adult to their plan at their chosen cover level</p>
                    <p>
                        Charges will apply to your payment method and appear on your invoice. 
                        These charges can be recovered from the employee via payroll deduction
                    </p>
                    <label><input required type="radio" name="addAdult" value={'yes'} checked={addAdult() === true} onChange={() => setAddAdult(true)}/>Yes</label>
                    <label><input required type="radio" name="addAdult" value={'no'} checked={addAdult() === false} onChange={() => setAddAdult(false)}/>No</label>
                </div>
            </FormSection>

            <TextSection>
                <NavigationButtons nextButtonDisabled={!isValid()} onNext={onNext} />
            </TextSection>
        </>
    )
}