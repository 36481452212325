import {KeyMapped, mapById, values} from '@punnet/pure-utility-kit'
import { toClass } from '@punnet/model-validation-pure'
import type { Account } from '../models/Account'
import type { Subscription } from '../models/Subscription'
import type { Plan } from '../models/Plan'
import type { Policy } from '../models/Policy'
import type { Life } from '../models/Life'

import { 
    Account as LegacyAccount, 
    Subscription as LegacySubscription, 
    Plan as LegacyPlan, 
    PlanBenefit as LegacyPlanBenefit, 
    type BenefitType,
    Policy as LegacyPolicy,
    Life as LegacyLife,
    LifeBenefit as LegacyLifeBenefit
} from '@peachy/core-domain-pure'


export function mapToLegacyAccount(account: Account): LegacyAccount {

    return toClass({
        id: account.id,
        type: account.type,
        status: account.status,
        createdAt: account.startDate,
        name: account.name,
        company: account.company,
        user: account.user,
        contactName: account.contactName,
        contactEmail: account.contactEmail,
        stripeCustomerId: account.paymentProviderCustomerId,
        subscriptions: mapById(values(account.subscriptions).map(mapToLegacySubscription))
    }, LegacyAccount)
}

export function mapToLegacySubscription(subscription: Subscription): LegacySubscription {
    return toClass({
        id: subscription.id,
        startDate: subscription.startDate,
        cancellationReason: 'ON-REQUEST',
        cancelledAt: subscription.endDate,
        contactEmail: subscription.contactEmail,
        contactName: subscription.contactName,
        name: subscription.name,
        paymentDetails: subscription.paymentDetails,
        planIds: values(subscription.plans).map(p => p.id),
        policyIds: values(subscription.policies).map(p => p.id),
        status: subscription.status,
        stripeProductId: subscription.paymentProvider.productId,
        stripeSubscriptionId: subscription.paymentProvider.subscriptionId,
        totalMonthlyPremium: subscription.totalMonthlyPremium,
    }, LegacySubscription)
}

export function mapToLegacyPlan(plan: Plan): LegacyPlan {
    return toClass({
        id: plan.id,
        name: plan.name,
        excess: {
            amountInPence: plan.excess,
        },
        benefits: values(plan.benefits).map(benefit => {
            const legacyPlanBenefit: LegacyPlanBenefit = {
                limit: benefit.limit,
                type: benefit.id as BenefitType,
            }
            return legacyPlanBenefit
        }),
    }, LegacyPlan)
}


export function mapToLegacyPlans(plans: KeyMapped<Plan>): KeyMapped<LegacyPlan> {
    return mapById(values(plans).map(mapToLegacyPlan))
}



export function mapToLegacyPolicy(policy: Policy): LegacyPolicy {
    return toClass({
        id: policy.id,
        cancellationReason: '',
        cancelledAt: policy.endDate,
        status: policy.status,
        startDate: policy.startDate,
        totalMonthlyPremium: policy.totalMonthlyPremium,
        lives: mapById(
            values(policy.lives).map(mapToLegacyLife)
        ),
    }, LegacyPolicy)
}



export function mapToLegacyLife(life: Life): LegacyLife {
    return toClass({
        id: life.id,
        cognitoUserId: life.cognitoUserId,
        intercomUserId: life.intercomUserId,
        endDate: life.endDate,
        startDate: life.startDate,
        planId: life.planId,
        firstname: life.firstname,
        lastname: life.lastname,
        email: life.email,
        dateOfBirth: life.dateOfBirth,
        gender: life.gender,

        phone: life.phone,
        address: life.address,
        postcode: life.postcode,

        idVerificationStatus: undefined,
        livenessCheckStatus: undefined,
        sanctionsCheckStatus: undefined,

        marketingAccepted: false,
        smsMarketingAccepted: false,

        totalMonthlyPremium: life.totalMonthlyPremium,
        type: life.type,
        benefits: values(life.benefits).map(benefit => {
            const legacyBenefit: LegacyLifeBenefit = {
                type: benefit.id as BenefitType,
                premium: benefit.premium,
                startDate: benefit.startDate,
                utilisation: 0
            }
            return legacyBenefit
        })
    }, LegacyLife)
}
