import * as Timing from './timing'
import * as Objects from './objects'
import * as TypeCheck from './type-check'
import * as Mapping from './mapping'


export const UtilityKit = {
    timing: {
        ...Timing,
    },
    objects: {
        ...Objects,
    },
    typeCheck: {
        ...TypeCheck,
    },
    mapping: {
        ...Mapping,
    },


}
