import { createContext, useContext, type Accessor, type ParentComponent } from 'solid-js'
import { _determineAuthenticatedState, type AuthenticatedState } from './_determineAuthenticatedState'

type AuthenticationState = {
    authenticatedState: Accessor<AuthenticatedState>
}

const AuthenticationContext = createContext<AuthenticationState>()

export const AuthenticationController: ParentComponent = (props) => {
    const authenticatedState = _determineAuthenticatedState()

    return <AuthenticationContext.Provider value={{ authenticatedState }}>
        {props.children}
    </AuthenticationContext.Provider>
}

export function useAuthenticationState(): Accessor<AuthenticatedState> {
    return useContext(AuthenticationContext)?.authenticatedState
}