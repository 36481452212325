import { Properties } from '@punnet/pure-utility-kit'
import { AnEvent } from '@punnet/core-event-pure'

import { CancellationEventData, LifeEventData } from './notification-types'
import { CancellationType } from '../../domain/models/CancellationType'
import { AccountType } from '../../domain/models/Account'

export class LifeCancelledNotification extends AnEvent implements LifeEventData, CancellationEventData {
    constructor(from: Properties<LifeCancelledNotification>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    subscriptionId: string
    policyId: string
    lifeId: string
    cognitoId: string
    cancellationReason: string
    cancellationType: CancellationType
}
