export type LoremProps = {
    words?: number
    paragraphs?: number
}


export function Lorem(props: LoremProps) {
    const content = []

    for (let i = 0; i < (props.paragraphs || 3); i++) {
        content.push(<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut purus eget sapien. Nulla facilisi. Sed ut purus eget sapien. Nulla facilisi. Sed ut purus eget sapien. Nulla facilisi.</p>)
    }

    return <div>{content}</div>
}
