import { Show, createContext, useContext, type ParentProps } from 'solid-js'
import { createResource } from 'solid-js'
import { createIamService, type IamService } from '../IamService'
import { createIamApi, type IamConfigType } from '../IamApi'
import { withViewTransition } from '@punnet/web-client-kit-library'

const IamControllerContext = createContext<IamService>()

export type IamControllerProps = ParentProps & {
    iamConfigType: IamConfigType
}

export function IamController(props: IamControllerProps) {

    const [iamService] = createResource(async () => {
        const api = await createIamApi(props.iamConfigType)
        const iamService = createIamService(api, (updateDom) => withViewTransition({
            types: ['coming'],
            condition: true,
            updateDom
        }))
        iamService.trySignBackIn()
        return iamService
    })

    return (
        <Show when={!iamService.loading}>
            <IamControllerContext.Provider value={iamService()}>
                {props.children}
            </IamControllerContext.Provider>
        </Show>
    )
}


export function useIamService() {
    return useContext(IamControllerContext)
}

