import { createSignal } from 'solid-js'

export const ON: unique symbol = Symbol('ON')
export const INITIAL: unique symbol = Symbol('INITIAL')
export const TAGS: unique symbol = Symbol('TAGS')
const _STATE_PATH: unique symbol = Symbol('STATE_PATH')
const _PARENT: unique symbol = Symbol('PARENT')

export type NoStrings = {
    [_: string]: never
}

export type StateDef = {
    [ON]?: {
        [k: string]: () => StateDef
    }
    [k: string]: StateDef | NoStrings
    [INITIAL]?: StateDef
    [TAGS]?: string | string[]
    [_STATE_PATH]?: string
    [_PARENT]?: StateDef
}


type Join<A extends string, B extends string> = A extends undefined ? B : `${A}.${B}`


export type StatePaths<SN extends StateDef,
    Prev extends string = undefined,
    Path extends string = undefined,
> = {
    [K in keyof SN & string]: SN[K] extends NoStrings
    ? Prev | Path | Join<Path, K>
    : StatePaths<SN[K], Prev | Path, Join<Path, K>>
}[keyof SN & string];


export type EventKeys<SD> = SD extends StateDef
    ? (
        (SD extends { [ON]: infer ONProp } ? keyof ONProp : never) |
        { [K in keyof SD]: K extends symbol ? never : EventKeys<SD[K]> }[keyof SD]
    )
    : never;


export type StateEvents<SN extends StateDef> = {
    [K in keyof SN]: K extends symbol
    ? keyof SN[K]
    : StateEvents<SN[K]>
}[keyof SN & (string | symbol)]



export type EventAction = () => void
export type EventTrigger = (handler?: EventAction) => boolean


export type Machine<T extends StateDef> = {
    currentState: () => StatePaths<T>
    isInState: (...s: StatePaths<T>[]) => boolean
    hasTag: (t: string) => boolean
    canHandle: (e: StateEvents<T>) => boolean
    fire: {
        [_ in EventKeys<T>]: EventTrigger
    }
}


export type TransitionInterceptor = (fn: () => void) => void



function gatherEvents<SD extends StateDef>(
    stateDef: SD,
    events: string[] = [],
    path: string = ''
): EventKeys<SD>[] {
    stateDef[_STATE_PATH] = path
    const eventDef = stateDef[ON] ?? {}
    events.push(...Object.keys(eventDef))

    Object.keys(stateDef).forEach(subStateName => {
        const subState: StateDef = stateDef[subStateName]
        subState[_PARENT] = stateDef
        if (!stateDef[INITIAL]) {
            stateDef[INITIAL] = subState
        }
        const morePath = path ? `${path}.${subStateName}` : subStateName
        gatherEvents(subState, events, morePath)
    })
    return events as EventKeys<SD>[]
}



function initialDeepState(state: StateDef) {
    let deepState = state
    while (deepState[INITIAL]) {
        deepState = deepState[INITIAL]
    }
    return deepState
}


export function createStateMachine<const SD extends StateDef>(
    stateDef: SD,
    transitionInterceptor: TransitionInterceptor = (fn) => fn()
): Machine<SD> {

    const events = gatherEvents(stateDef)

    const [currentState, setCurrentState] = createSignal(initialDeepState(stateDef))


    const machine: Machine<SD> = {
        currentState() {
            return currentState()[_STATE_PATH]
        },
        isInState(...s: StatePaths<SD>[]) {
            // @ts-ignore
            return s.some(s => currentState()[_STATE_PATH] === s || currentState()[_STATE_PATH].startsWith(`${s}.`))
        },
        hasTag(s: string) {
            let testState = currentState()
            while (testState) {
                if (testState[TAGS] === s || testState[TAGS]?.includes(s)) {
                    return true
                }
                testState = testState[_PARENT]
            }
            return false
        },
        canHandle(e: StateEvents<SD>) {
            let testState = currentState()
            while (testState) {
                const eventDef = testState[ON]?.[e as string]
                if (eventDef) {
                    return true
                }
                testState = testState[_PARENT]
            }
            return false
        },
        fire: {} as Partial<Machine<SD>>['fire']
    }



    for (const event of events) {
        machine.fire[event] = (handler?: () => void) => {
            let testState = currentState()
            while (testState) {
                const eventDef = testState[ON]?.[event]
                if (eventDef) {
                    transitionInterceptor(() => {
                        setCurrentState(initialDeepState(eventDef()))
                        try {
                            handler?.()
                        } catch (e) {
                            // do nothing
                        }
                    })
                    return true
                }
                testState = testState[_PARENT]
            }
            return false
        }
    }
    return machine as Machine<SD>
}
