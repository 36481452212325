import { HasLifecycleStatus, Life, LifeBenefit, PlanBenefit } from '@punnet/subscription-pure'
import { AlterationDiff } from '@punnet/alterations-pure'
import { isNullish } from '@punnet/pure-utility-kit'


export const hasBeenCancelled = <T extends HasLifecycleStatus>(diff: AlterationDiff<T>) =>
    diff.left?.status === 'ACTIVE' && diff.right?.status === 'CANCELLED'
export const hasBeenAdded = <T extends HasLifecycleStatus>(diff: AlterationDiff<T>) =>
    isNullish(diff.left) && (diff.right?.status === 'ACTIVE') && !hasBeenTransferredIn(diff)
export const hasBeenReactivated = <T extends HasLifecycleStatus>(diff: AlterationDiff<T>) =>
    diff.left?.status === 'CANCELLED' && (diff.right?.status === 'ACTIVE')

export const hasBeenRenewed = <T extends (HasLifecycleStatus)>(diff: AlterationDiff<T>) =>
    (diff.left?.status === 'ACTIVE' && diff.right?.status === 'ACTIVE')
    && diff.left.nextRenewalDate !== diff.right.nextRenewalDate
    && (
        'effectiveDate' in diff.left && 'effectiveDate' in diff.right
            ? diff.left.effectiveDate < diff.right.effectiveDate
            : true
    )

export const hasBeenTransferredIn =<T extends (HasLifecycleStatus)>(diff: AlterationDiff<T>) =>
    isNullish(diff.left) && (diff.right?.status === 'ACTIVE') && (diff.right?.transfer?.in)

export const hasBeenTransferredOut =<T extends (HasLifecycleStatus)>(diff: AlterationDiff<T>) =>
    !isNullish(diff.left) && (diff.right?.status === 'ACTIVE') && (diff.right?.transfer?.out)



export const hasBenefitLimitIncreased = (diff: AlterationDiff<LifeBenefit> | AlterationDiff<PlanBenefit>) =>
    (!isNullish(diff.left?.limit) && !isNullish(diff.right?.limit))
    && diff.left.limit < diff.right.limit

export const hasBenefitLimitDecreased = (diff: AlterationDiff<LifeBenefit> | AlterationDiff<PlanBenefit>) =>
    (!isNullish(diff.left?.limit) && !isNullish(diff.right?.limit))
    && diff.left.limit > diff.right.limit


export const hasBenefitExcessIncreased = (diff: AlterationDiff<LifeBenefit> | AlterationDiff<PlanBenefit>) =>
    (!isNullish(diff.left?.excess) && !isNullish(diff.right?.excess))
    && diff.left.excess < diff.right.excess

export const hasBenefitExcessDecreased = (diff: AlterationDiff<LifeBenefit> | AlterationDiff<PlanBenefit>) =>
    (!isNullish(diff.left?.excess) && !isNullish(diff.right?.excess))
    && diff.left.excess > diff.right.excess

export const hasLifeAddressChanged = (diff: AlterationDiff<Life>) =>
    diff.left?.address && diff.right?.address


export const hasLifeBirthdateChanged = (diff: AlterationDiff<Life>) =>
    diff.left?.dateOfBirth && diff.right?.dateOfBirth


export const hasLifeSwitchedPlan = (diff: AlterationDiff<Life>) =>
    diff.left?.planId && diff.right?.planId
    && diff.left.planId !== diff.right.planId
