import { createEffect } from 'solid-js'
import { DatePickerHeader } from './DatePickerHeader/DatePickerHeader'
import { DatePickerStore } from './DatePickerStore'
import { MonthTable } from './MonthTable/MonthTable'

export type DatePickerProps = {
    minDate: Date
    maxDate: Date
    selectedDate: Date
    appearance?: DatePickerAppearance
    headerText: string,
    submitText: string,
    onSelect: (selected: Date) => void
}

export function DatePicker(props: DatePickerProps) {

    const store = new DatePickerStore(props.minDate, props.maxDate, props.selectedDate)

    const onSelect = () => {
        props.onSelect(store.selectedDate)
    }

    createEffect(() => {
        store.minDate = props.minDate
        store.maxDate = props.maxDate
    })

    return (<>
        <DatePickerHeader headerText={props.headerText} store={store} appearance={props.appearance}/>
        <MonthTable store={store} appearance={props.appearance}/>
        <button onClick={onSelect} class={props.appearance?.submit}>{props.submitText}</button>
    </>
    )
}


export type DatePickerAppearance = {
    header?: string
    nudgeSelectors?: string
    nudgeSelector?: string
    nudgeSelectorText?: string
    nudgeSelectorModal?: string
    nudgeSelectorList?: string
    nudgeSelectorListItem?: string
    nudgeSelectorListItemSelected?: string
    nudgeLeft?: string
    nudgeRight?: string
    monthTable?: string
    weekNames?: string
    allDays?: string
    inMonth?: string
    outMonth?: string
    weekday?: string
    weekend?: string
    today?: string
    selected?: string
    submit?: string
    disabled?: string
}



