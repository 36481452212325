import {DatePickerStore} from '../DatePickerStore'
import {CalenderMonthArray, classList} from '@punnet/pure-utility-kit'

import styles from './DatePickerHeader.module.css'
import {DatePickerAppearance} from '../DatePicker'
import {listMonthsForYear, listYearsBetween} from '../date-kit'
import {NudgeSelector} from '../NudgeSelector/NudgeSelector'

type DatePickerHeaderProps = {
    headerText: string
    store: DatePickerStore
    appearance?: DatePickerAppearance
}

export function DatePickerHeader(props: DatePickerHeaderProps) {

    const onSelectYear = (year: string) => props.store.year = +year
    const onSelectMonth = (month: string) => props.store.month = CalenderMonthArray.findIndex(m => m === month) + 1
    const availableYears = () => listYearsBetween(props.store.minDate, props.store.maxDate)
    const availableMonths = () => listMonthsForYear(props.store.year, props.store.minDate, props.store.maxDate)

    const headerClasses = classList(styles.DatePickerHeader, props.appearance?.header)

    return (
        <header class={headerClasses}>
            <h3>{props.headerText}</h3>
            <div class={props.appearance?.nudgeSelectors}>
                <NudgeSelector
                    options={availableYears()}
                    selection={`${props.store.year}`}
                    onSelect={onSelectYear}
                    datePickerStore={props.store}
                    appearance={props.appearance}
                    onNudgeLeft={() => props.store.nudgeYearEarlier()}
                    onNudgeRight={() => props.store.nudgeYearLater()}
                />
                <NudgeSelector
                    options={availableMonths()}
                    selection={CalenderMonthArray[props.store.month - 1]}
                    onSelect={onSelectMonth}
                    datePickerStore={props.store}
                    appearance={props.appearance}
                    onNudgeLeft={() => props.store.nudgeMonthEarlier()}
                    onNudgeRight={() => props.store.nudgeMonthLater()}
                />
            </div>
        </header>
    )
}
