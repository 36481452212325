import { type Component, createSignal, For } from 'solid-js'
import { useManagePlans, useManageStoreActions } from '../../../../app/store/quotes/manageEmployeesStore'
import type { LifeRowCellProps } from '../ManageEmployeesTable'
import tableStyles from '../ManageEmployeesTable.module.css'

export const PlanSelector: Component<LifeRowCellProps> = (props) => {
    const plans = useManagePlans()
    const { updateLife } = useManageStoreActions()

    const plansInSelector = plans.map(p => p.name)
    const initialPlan = plans.find(p => p.id === props.life.planId)
    const initlalPlanSelect = initialPlan ? plansInSelector.find(p => p === initialPlan.name) : plansInSelector[0]

    // TODO-MN maybe drive by plan id incase duplicate names
    const [currentPlan, setCurrentPlan] = createSignal(initlalPlanSelect)

    const onPlanSelect = (event: SelectEvent) => {
        const selectedPlan = event.currentTarget.value
        const plan = plans.find(p => p.name === selectedPlan)

        setCurrentPlan(plan.name)

        updateLife(props.policy.id, { ...props.life, planId: plan.id })
    }

    // TODO-MN popup list component eventually. Need custom icon on the side

    return (
        <td class={tableStyles.plan}>
            <select onChange={onPlanSelect}>
                <For each={plansInSelector}>{(plan: string, index) => <option value={plan} selected={plan === currentPlan()}>{plan}</option>}</For>
            </select>
        </td>
    )
}



type SelectEvent = Event & {
    currentTarget: HTMLSelectElement
}