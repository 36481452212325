import {Type} from 'class-transformer'
import 'reflect-metadata'
import {QuestionOption} from './QuestionOption'

export class QuestionOptionsDefinition {
    @Type(() => QuestionOption)
    readonly embeddedOptions?: QuestionOption[]
    readonly optionsId?: string
    readonly acceptNonOptionAnswers: boolean

    constructor(props: {
        embeddedOptions?: QuestionOption[],
        optionsId?: string,
        acceptNonOptionAnswers?: boolean
    }) {
        this.embeddedOptions = props?.embeddedOptions
        this.optionsId = props?.optionsId
        this.acceptNonOptionAnswers = props?.acceptNonOptionAnswers || false
    }
}