export class BankDetails {

    constructor(readonly accountNumber: string, readonly sortCode: string) {
    }

    static isBankDetails(thing?: any): thing is BankDetails {
        return thing && 'accountNumber' in thing && 'sortCode' in thing
    }

    toString() {
        const hyphenatedSortCode = `${this.sortCode.substr(0, 2)}-${this.sortCode.substr(2, 2)}-${this.sortCode.substr(4, 2)}`
        return hyphenatedSortCode + '\n' + this.accountNumber
    }
}