import { CompanyDetailsCard } from '../../../components/Overview/Card/CompanyDetailsCard'
import { CompanySchemeCard } from '../../../components/Overview/Card/CompanySchemeCard'
import { CompanyPaymentCard } from '../../../components/Overview/Card/CompanyPaymentCard'
import { PageTitle } from '../PageTitle'
import { CompanyPlanViewer } from '../../../components/Overview/Plans/CompanyPlanViewer'
import styles from './Overview.module.css'

import { getActiveSubscription } from '../../services/AccountProvider'

export function Overview() {


    // const accountData = getActiveSubscription()

    return (
        <div class={styles.Overview}>
            <PageTitle>Your Company Scheme</PageTitle>

            <div class={styles.Content}>
                <div class={styles.Cards}>
                    <CompanySchemeCard />
                    <CompanyPaymentCard />
                    <CompanyDetailsCard />
                </div>

                <div class={styles.Plans}>
                    <CompanyPlanViewer />
                </div>
            </div>

        </div>
    )
}