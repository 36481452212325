import type { SubscriptionQuoteRequest } from '@punnet/subscription-pure'
import { useSubscriptionService } from '@punnet/subscription-web-client'
import { useContext, type ParentComponent } from 'solid-js'
import { createSubscription } from '../../../services/quote/createSubscription'
import { persistQuote } from '../../../services/quote/persistQuote'
import { subscribeToStore, useStoreActions, type QuoteAndBuyState } from '../../../store/quotes/quoteAndBuyStore'
import { Quote } from '../Quote'
import styles from '../Section.module.css'
import { createContext } from 'solid-js'
import type { SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import { useSavedSubscriptionQuote } from './SubscriptionQuoteLoader'
import { mapToSubscriptionRequest } from '../../../store/quotes/mapToSubscriptionRequest'
import { useQuoteResponse } from '../QuoteController/QuoteController'

type QuotePersister = {
    persist: () => Promise<SubscriptionQuoteResponse>
}

const Context = createContext<QuotePersister>()

export const PurchaseQuote: ParentComponent = (props) => {
    const subscriptionService = useSubscriptionService()
    const quoteResponse = useQuoteResponse()
    const savedQuote = useSavedSubscriptionQuote()
    const getQuoteRequest = () => mapToSubscriptionRequest(useStoreActions.getState(), quoteResponse() ?? savedQuote)

    const quotePersister: QuotePersister = {
        persist: async () => persistQuote(subscriptionService, getQuoteRequest())
    }

    const quoteSubscription = createSubscription<QuoteAndBuyState, SubscriptionQuoteRequest>(
        subscribeToStore,
        (state, prevState) => state.quoteRequestId !== prevState.quoteRequestId,
        (state) => getQuoteRequest() as SubscriptionQuoteRequest
    )

    const handleQuoteResponse = async () => {
        await quotePersister.persist()
    }

    return (
        <div class={styles.StripeySections}>
            <Context.Provider value={quotePersister}>
                <Quote onQuoteResponse={handleQuoteResponse} subscribe={quoteSubscription}>
                    {props.children}
                </Quote>
            </Context.Provider>
        </div>
    )
}

export const useQuotePersister = () => {
    return useContext(Context)
}