import {filter, map} from 'rxjs'
import {PageSyncState} from '../../key-interfaces/IPageIo'
import {RepoSyncAgent} from '../repo/RepoSyncAgent'
import {IRepoSyncApi} from '../../sync-api-definition/sync-api-definition'
import {InstallationId} from '../../primatives/repo-primatives'
import {RxOps} from '@punnet/rxjs-utility-kit'


export async function syncPages(
    localRepo: RepoSyncAgent,
    remoteRepo: IRepoSyncApi,
    remoteInstallationId: InstallationId
): Promise<void> {


    const _localBookmark = await localRepo.repoContext.pageIo.fetchBookmark()

    const localPageSyncState = await localRepo.fetchPageSyncState(remoteInstallationId)
    const remotePageSyncState = await remoteRepo.fetchPageSyncState(localRepo.repoContext.installationId)

    const updatedLocalPageSyncState: PageSyncState = {
        ...localPageSyncState
    }

    const updatedRemotePageSyncState: PageSyncState = {
        ...remotePageSyncState
    }

    // download remote pages
    const remotePageKey$ =
        remoteRepo.fetchPageKeysSince(localPageSyncState?.remoteBookmark).pipe(
            map((page) => {
                updatedLocalPageSyncState.remoteBookmark = page.bookmark
                return page.repoHash
            }),
        )

    const storedLocalPages = await RxOps.collectFrom(
        localRepo.commitPageInfoStream(
            remoteRepo.fetchPageInfoStream(
                localRepo.filterForUnknownPageHashes(remotePageKey$)
            )
        )
    )

    const storedLocalKeys = new Set(storedLocalPages.map(p => p.repoHash.toString()))

    const uploadPage$ =
        localRepo.fetchPageKeysSince(localPageSyncState.localBookmark).pipe(
            map(bm => bm.repoHash),
            filter(repoHash => !storedLocalKeys.has(repoHash.hash)),
            (repoHash$) => remoteRepo.filterForUnknownPageHashes(repoHash$),
            (keys) => localRepo.fetchPageInfoStream(keys),
            (pages) => remoteRepo.commitPageInfoStream(pages),
        )

    await RxOps.drain(uploadPage$)

    await localRepo.commitPageSyncState({
        remoteInstallationId: remoteInstallationId,
        pageSyncState: updatedLocalPageSyncState,
        expectedState: localPageSyncState
    })

    updatedRemotePageSyncState.localBookmark = updatedLocalPageSyncState.remoteBookmark
    updatedRemotePageSyncState.remoteBookmark = updatedLocalPageSyncState.localBookmark

    await remoteRepo.commitPageSyncState({
        remoteInstallationId: localRepo.repoContext.installationId,
        pageSyncState: updatedRemotePageSyncState,
        expectedState: remotePageSyncState
    })
}
