import {QuoteBenefit, type Premium} from '@punnet/quote-pure'

import {HasLifecycleStatus, LifecycleStatus, TransferDetails} from './LifecycleStatus'


export class LifeBenefit implements QuoteBenefit, HasLifecycleStatus {

    id: string

    quoteModelVersion?: string
    renewalPeriodMonths?: number

    status: LifecycleStatus
    startDate?: number
    endDate?: number
    timezone?: string

    cancellationReason?:string
    lastRenewalDate?: number
    nextRenewalDate?: number

    transfer?: TransferDetails

    rateSet?: string
    groupSize?: number
    moriDate?: number
    effectiveDate?: number
    annualBilling?: boolean
    excess?: number
    benefitSpecificExcess?: boolean


    premium?: Premium
    limit?: number
}
