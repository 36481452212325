import type { SubscriptionQuoteRequest, SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import { useSubscriptionService } from '@punnet/subscription-web-client'
import { debounce } from '@solid-primitives/scheduled'
import { createSignal, onCleanup, onMount, type ParentComponent } from 'solid-js'
import type { SubscribeFunction } from '../../../services/quote/createSubscription'

const MAX_QUOTE_RATE = 500

const [quoteResponse, setQuoteResponse] = createSignal<SubscriptionQuoteResponse>()
const [isQuoting, setIsQuoting] = createSignal(false)

type QuoteControllerProps = {
    subscribe: SubscribeFunction<SubscriptionQuoteRequest>
    onQuoteResponse?: (response: SubscriptionQuoteResponse) => void
}

export const QuoteController: ParentComponent<QuoteControllerProps> = (props) => {
    const subscriptionService = useSubscriptionService()

    onMount(() => {
        setQuoteResponse(undefined)
        setIsQuoting(false)
    })

    const requestQuote = debounce(async (quoteRequest: SubscriptionQuoteRequest) => {
        console.log('requesting quote', quoteRequest)
        const response = await subscriptionService.getSmeQuote(quoteRequest)
        console.log('received quote', response)
        setQuoteResponse(response)
        props.onQuoteResponse?.(response)
        setIsQuoting(false)
    }, MAX_QUOTE_RATE)


    const unsubscribe = props.subscribe((request: SubscriptionQuoteRequest) => {
        setIsQuoting(true)
        requestQuote(request)
    })

    onCleanup(() => {
        unsubscribe()
        setQuoteResponse(undefined)
        setIsQuoting(false)
    })

    return (
        <>
            {props.children}
        </>
    )
}

export function useQuoteResponse() {
    return quoteResponse
}

export function useIsQuoting() {
    return isQuoting
}

export function getTotalMonthlyPremium() {
    const quoteResponse = useQuoteResponse()
    const isQuoting = useIsQuoting()
    return !isQuoting() && quoteResponse()?.totalMonthlyPremium
}