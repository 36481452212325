import { useIamService } from '@punnet/iam-client'
import { Navigate, type RouteDefinition } from '@solidjs/router'
import { createSignal, type JSXElement } from 'solid-js'
import { Home } from './pages/Home'
import { MembersRoutes } from './pages/Members/Routes'
import { Overview } from './pages/Overview/Overview'
import { ContactDetails } from './pages/Quotes/NewQuote/ContactDetails'
import { EditQuote } from './pages/Quotes/NewQuote/EditQuote'
import { NewQuote } from './pages/Quotes/NewQuote/NewQuote'
import { PlanDetails } from './pages/Quotes/NewQuote/PlanDetails'
import { SaveAndClose } from './pages/Quotes/NewQuote/SaveAndClose'
import { PreviousButton } from './pages/Quotes/PurchaseQuote/NavigationButtons'
import { Welcome } from './pages/Quotes/PurchaseQuote/Welcome'
import { DirectDebit } from './pages/Quotes/PurchaseQuote/non-stp/DirectDebit'
import { DirectDebitSuccess } from './pages/Quotes/PurchaseQuote/non-stp/DirectDebitSuccess'
import { BackToDirectDebitAuthority } from './pages/Quotes/PurchaseQuote/non-stp/InviteFinanceContact'
import { PurchaseQuoteInviteFinanceRoutes } from './pages/Quotes/PurchaseQuote/non-stp/Routes'
import { PurchaseQuoteRoutes } from './pages/Quotes/PurchaseQuote/stp/Routes'
import { Quotes } from './pages/Quotes/Quotes'
import { hasActiveSubscription, hasLatestQuote } from './services/AccountProvider'
import { getPermissions } from './services/permissions/PermissionsController'

// extend the solid js router to add additional functionality for determining if 
// i) a route is accessible
// ii) a route has it's own navigation / actions
type PermissionedRouteDefinition = RouteDefinition & {
    canAccess: () => boolean
    navigation?: {
        primary?: JSXElement
        secondary?: JSXElement
    }
    regex?: string
}

const isSignedIn = () => useIamService().isSignedIn()
const canShareQuote = () => getPermissions().canShareQuote()
const canPurchaseQuote = () => getPermissions().canPurchaseQuote()
const isFinanceUser = () => getPermissions().isFinanceUser()

const editQuoteRoutes = [
    {
        path: '/',
        component: PlanDetails
    },
    {
        path: '/contact',
        component: ContactDetails,
        canAccess: () => isSignedIn() && canShareQuote(),
        navigation: {
            secondary:  SaveAndClose
        }
    }
]

const allRoutes = [
    {
        path: '/',
        component: Home,
        canAccess: () => true
    },
    {
        path: '/quotes',
        component: Quotes,
        canAccess: () => isSignedIn() && canShareQuote()
    },
    {
        path: '/quotes/new',
        component: NewQuote,
        canAccess: () => isSignedIn() && canShareQuote(),
        navigation: {
            secondary: SaveAndClose
        },
        children: editQuoteRoutes
    },
    {
        path: '/quotes/edit/:quoteId',
        component: EditQuote,
        canAccess: () => isSignedIn() && canShareQuote(),
        navigation: {
            secondary:  SaveAndClose
        },
        children: editQuoteRoutes,
        regex: '^/quotes/edit/[\\w-]+(\\/.*)?'
    },
    // purchase quote: finance user routes
    {
        path: '/quotes/dd-only/:quoteId',
        component: DirectDebit,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasLatestQuote() && isFinanceUser()
    },
    {
        path: '/quotes/dd-only-success',
        component: DirectDebitSuccess,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasLatestQuote() && isFinanceUser()
    },
    // purchase quote: non-stp routes
    {
        path: '/quotes/dd/:quoteId/*',
        component: PurchaseQuoteInviteFinanceRoutes,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasLatestQuote() && !isFinanceUser(),
        navigation: {
            primary: BackToDirectDebitAuthority
        },
        regex: '^/quotes/dd/[\\w-]+$'
    },
    // purchase quote: stp routes 
    {
        path: '/quotes/:quoteId/*',
        component: PurchaseQuoteRoutes,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasLatestQuote() && !isFinanceUser(),
        navigation: {
            primary: <PreviousButton/>
        },
        regex: '^/quotes/[\\w-]+(\\/.*)?'
    },
    {
        path: '/quotes/purchased',
        component: Welcome,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasLatestQuote() && !isFinanceUser()
    },
    {
        path: '/overview',
        component: Overview,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasActiveSubscription()
    },
    {
        path: '/members/*',
        component: MembersRoutes,
        canAccess: () => isSignedIn() && canPurchaseQuote() && hasActiveSubscription(),
        regex: '^/members(/summary)?$'
    },
    {
        path: '*',
        component: () => <Navigate href="/"/>,
        canAccess: () => true
    }
] as PermissionedRouteDefinition[]


const matchExactPath = (path: string, route: PermissionedRouteDefinition) => {
    return route.path === path
}

const matchRegex = (path: string, route: PermissionedRouteDefinition) => {
    if (route.regex) {
        const regex = new RegExp(route.regex)
        return regex.test(path)
    }
    return false
}

function matchPath (path: string, route: PermissionedRouteDefinition) {
    return matchExactPath(path, route) || matchRegex(path, route)
}

// flatten the routes so we can match against the full path of both root routes and child routes
const flattenedRoutes = allRoutes.flatMap(route => {
    const childrenWithFullPath = (route.children ?? []).map((child: PermissionedRouteDefinition) => ({
        ...child,
        path: `${route.path}${child.path}`
    }))
    return [route, ...childrenWithFullPath]
})

// used to store the accessible routes - this is used for the router configuration
const [accessibleRoutes, setAccessibleRoutes] = createSignal<PermissionedRouteDefinition[]>([])


// used by the router configuration - this gets called when one of the signals changes
// we therefore can store the routes the user has access to globally and use it to determine if a route is accessible
export function getConfigurableRoutes() {
    const filteredRoutes = () => allRoutes.filter((route: PermissionedRouteDefinition) => route.canAccess())
    setAccessibleRoutes(filteredRoutes())
    console.log(filteredRoutes().map(route => route.path))
    return filteredRoutes()
}

export function isRouteAccessible(path: string) {
    return accessibleRoutes().some(route => matchPath(path, route))
}

function findMatchingRoute(path: string) {
    let route = flattenedRoutes.find(route => matchExactPath(path, route)) //prefer exact match
    route = route ?? flattenedRoutes.find(route => matchRegex(path, route)) //then regex match
    return route
}

export function getRouteNavigationPrimary(path: string): JSXElement | undefined {
    const route = findMatchingRoute(path)
    return route?.navigation?.primary
}

export function getRouteNavigationSecondary(path: string): JSXElement | undefined {
    const route = findMatchingRoute(path)
    return route?.navigation?.secondary
}
