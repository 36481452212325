import { type Properties } from '@punnet/pure-utility-kit'
import { SanctionsCheckEvent } from './SanctionsCheckEvent'

export class SanctionsCheckPassed extends SanctionsCheckEvent {

    constructor(from: Properties<SanctionsCheckPassed>) {
        super(from)
        this.status = 'PASSED'
        this.type = this.constructor.name
    }
}