export class NhsOrganisation {
    constructor(
        readonly OrganisationID: string,
        readonly OrganisationName: string,
        readonly Address1: string,
        readonly Address2: string,
        readonly Address3: string,
        readonly City: string,
        readonly County: string,
        readonly Postcode: string,
        readonly Latitude: string,
        readonly Longitude: string,
    ) { }
}