import {Draft, isArray, isObject, Keyed, keys} from '@punnet/pure-utility-kit'
import {defaultEqualityFunction, EqualityFunction, IdentityFunction, ResolverFunction} from './diff-kit'
import {longestCommonSubsequence} from '../merge/longest-common-subsequence'

export type Diff<L, R = L> = {
    left: L extends Keyed ? Draft<L> : L,
    right: R extends Keyed ? Draft<R> : R,
}


export type DiffOptions = {
    equalityFunction?: EqualityFunction
    resolverFunction?: ResolverFunction
    identityFunction?: IdentityFunction
}



export async function difference<L, R>(left: L, right: R, options?: DiffOptions): Promise<Diff<L, R>>
export async function difference(left: unknown, right: unknown, options?: DiffOptions) {
    const equalityFunction = options?.equalityFunction ?? defaultEqualityFunction

    switch (true) {
        case equalityFunction(left, right):
            return null

        case isArray(left) && isArray(right):
            return diffArrays(left, right, options)

        case isObject(left) && isObject(right):
            return diffObjects(left, right, options)

        default:
            return {left, right}
    }
}


function mapCommonIndexes(lcsIndexes: number[][]) {
    const lr = new Map<number, number>
    const rl = new Map<number, number>

    for (const [l, r] of lcsIndexes) {
        lr.set(l, r)
        rl.set(r, l)
    }
    return {
        leftToRight(leftIndex: number) {
            return lr.get(leftIndex)
        },
        rightToLeft(rightIndex: number) {
            return rl.get(rightIndex)
        },
        sharesLeft: (leftIndex: number) => lr.has(leftIndex),
        sharesRight: (rightIndex: number) => rl.has(rightIndex)
    }
}


async function diffArrays<L, R>(left: L[], right: R[], options?: DiffOptions): Promise<Diff<L[], R[]>> {

    const [_lcs, lcsIndexes] = longestCommonSubsequence(left, right, options)

    const indexMap = mapCommonIndexes(lcsIndexes)

    const leftDiff: L[] = []
    const rightDiff: R[] = []

    const commonRightValueDiffs: Diff<L, R>[] = []

    for (let leftIndex = 0; leftIndex < left.length; leftIndex++) {
        if (indexMap.sharesLeft(leftIndex)) {
            const rightIndex = indexMap.leftToRight(leftIndex)
            const valueDiff = await difference(left[leftIndex], right[rightIndex], options)
            if (valueDiff) {
                commonRightValueDiffs[rightIndex] = valueDiff
                leftDiff[leftIndex] = valueDiff.left as L
            }

        } else {
            leftDiff[leftIndex] = left[leftIndex]
        }
    }

    for (let rightIndex = 0; rightIndex < right.length; rightIndex++) {
        if (indexMap.sharesRight(rightIndex)) {
            const valueDiff = commonRightValueDiffs[rightIndex]
            if (valueDiff) {
                rightDiff[rightIndex] = valueDiff.right as R
            }
        } else {
            rightDiff[rightIndex] = right[rightIndex]
        }
    }

    const diff = {
        left: leftDiff.length ? leftDiff: null,
        right: rightDiff.length ? rightDiff: null
    }

    return diff.left || diff.right
        ? diff
        : null
}

async function diffObjects(left: Keyed, right: Keyed, options?: DiffOptions): Promise<Diff<Keyed, Keyed>> {

    const leftKeys = keys(left)
    const rightKeys = keys(right)

    const leftDiff: Keyed = {}
    const rightDiff: Keyed = {}

    const valueDiffs: Keyed<string, Diff<any, any>> = {}

    for (const leftKey of leftKeys) {


        if (leftKey in right) {
            const valueDiff = await difference(left[leftKey], right[leftKey], options)
            if (valueDiff) {
                valueDiffs[leftKey] = valueDiff
                leftDiff[leftKey] = valueDiff.left
            }
        } else {

            leftDiff[leftKey] = left[leftKey]
        }
    }

    for (const rightKey of rightKeys) {
        if (rightKey in left) {
            const valueDiff = valueDiffs[rightKey]
            if (valueDiff) {
                rightDiff[rightKey] = valueDiff.right
            }
        } else {
            rightDiff[rightKey] = right[rightKey]
        }
    }
    const diff = {
        left: keys(leftDiff).length ? leftDiff :null,
        right: keys(rightDiff).length ? rightDiff :null,
    }

    return diff.left || diff.right
        ? diff
        : null

}
