import { newUUID, values, type Draft, type KeyMapped } from '@punnet/pure-utility-kit'
import type { AccountSubscriptionDto, Policy, SubscriptionQuoteRequest } from '@punnet/subscription-pure'
import { getAddedMemberPolicies, getChangedMemberPolicies, getRemovedMemberPolicies, mapToDraftPolicies, type MemberPolicy } from './mapToMemberPolicies'
import { untrack } from '@punnet/web-client-kit-library'


// TODO: this is a mess, need to refactor!
function mapToPolicies(currentPolicies: KeyMapped<Policy>, memberPolicies: MemberPolicy[]): Draft<Policy>[] {

    // find all REMOVED policies and remove them from currentPolicies
    const removedPolicyIds = getRemovedMemberPolicies(memberPolicies).map(p => p.id)

    removedPolicyIds.forEach(policyId => {
        delete currentPolicies[policyId]
    })

    
    // find all CHANGED policies and update them in currentPolicies
    const changedPolicies = getChangedMemberPolicies(memberPolicies)

    changedPolicies.forEach(memberPolicy => {
        const currentPolicy = currentPolicies[memberPolicy.id]

        currentPolicy.lives[memberPolicy.primary.id].planId = memberPolicy.primary.planId

        if (memberPolicy.secondary) {
            currentPolicy.lives[memberPolicy.secondary.id].planId = memberPolicy.secondary.planId
        }
    })

    const policies: Draft<Policy>[] = values(currentPolicies)

    // add all ADDED policies

    const addedPolicies = getAddedMemberPolicies(memberPolicies)
    policies.push(...mapToDraftPolicies(addedPolicies))

    return policies
}


export function mapToSubscriptionRequest(activeAccount: AccountSubscriptionDto, memberPolicies: MemberPolicy[]): SubscriptionQuoteRequest {
    // create a copy of the current policies otherwise we're editing the existing object
    const currentPolicies = untrack(activeAccount.subscription.policies)

    const quoteRequest = {
        quoteRequestId: newUUID(),
        account: activeAccount.account,
        subscription: activeAccount.subscription,
        policies: mapToPolicies(currentPolicies, memberPolicies),
        plans: values(activeAccount.subscription.plans),
    } as SubscriptionQuoteRequest

    return quoteRequest
}
