import { UtilityKit, hashFor } from '@punnet/pure-utility-kit'
import type { Relationship, RelationshipDefinition } from './relationship-types'


export function defineRelationships<const RD extends RelationshipDefinition>(rd: RD) {
    return rd
}

export function redisRelationshipKey(redisKeySpace: string, relationshipId: string) {
    return `${redisKeySpace}/relationships/${relationshipId}`
}


export function redisRelationshipIndexKey(redisKeySpace: string) {
    return `${redisKeySpace}/idx/relationships`
}

export function idForRelationship<RD extends RelationshipDefinition>(relationship: Relationship<RD>) {
    return hashFor(cleanRelationship(relationship))
}

export function cleanRelationship<RD extends RelationshipDefinition>(relationship: Relationship<RD>) {
    return UtilityKit.objects.prune(relationship, UtilityKit.objects.isEmpty)
}

