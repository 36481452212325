import { dump } from '@punnet/pure-utility-kit'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { drain } from './drain'

export async function dumpStream<T>(stream: Observable<T>) {
    await drain(stream.pipe(
        tap(item => {
            dump(item)
        })
    ))
}