//should replace this whole file with Darren's marketplace types
import {WithId} from './types'

type DayOfWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'
export const everyDayOfTheWeek: DayOfWeek[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

export type PracticeHoursResult = {
    "secretaries": number[],
    "hours": string
    "phoneNumber": string
    "day": string
}
export type PracticeResultWithId = WithId<PracticeResult>

export interface MarketplaceResult {
    externalId: string;
    phoneNumber?: any;
    website?: string;
    peachyId: string;
    primarySpeciality: string;
    hashKey: number;
    geoJson: string;
    gmcId: string;
    otherSpecialities: string[];
    fullName: string;
    secretaries: MedicalSecretaryResult[];
    gender: string;
    lastname: string;
    languages: Language[];
    paediatric: boolean;
    rangeKey: string;
    practices: PracticeResult[];
    geohash: number;
    firstname: string;
    areasOfInterest: string[];
    middleName: string;
    title: string;
    distance: number;
}
export interface PracticeResult {
    emails: string[];
    secretaries: any[];
    address: string[];
    practiceHours: PracticeHoursResult[];
    cqcId: string;
    name: string;
    postcode: string;
    location: Location;
    websites: string[];
    type: string;
    phoneNumbers: string[];
    distance: number;
}
interface Location {
    lng: number;
    lat: number;
}
interface Language {
    level: string;
    language: string;
}
interface MedicalSecretaryResult {
    name: string;
    index: number;
    location: string[];
    email: string;
    phoneNumbers: string[];
}