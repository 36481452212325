import { useSubscriptionService } from '@punnet/subscription-web-client'
import { useNavigate, useParams } from '@solidjs/router'
import { createSignal } from 'solid-js'
import { nextPage } from '../../../../../components/PageRouter/PageRouter'
import { getQuoteSubscriptionRequestWithSavedQuote } from '../../../../services/quote/getQuoteSubscriptionRequest'
import { hasDirectDebitDetails, useFinanceContactEmail, useStoreActions } from '../../../../store/quotes/quoteAndBuyStore'
import { Title } from '../../Quote'
import quoteStyles from '../../Quote.module.css'
import { FormSection, TextSection } from '../../Section'
import { CompanySchemeHeader } from '../CompanySchemeHeader'
import { NavigationButtons, PreviousButtonOnly } from '../NavigationButtons'


export const InviteFinanceContact = () => {
    const { setFinanceContactEmail } = useStoreActions
    const subscriptionService = useSubscriptionService()

    // TODO: this is a bit of a hack to avoid entering in another email address for the finance user
    if (hasDirectDebitDetails()) {
        console.log('has direct debit details, skipping invite')
        nextPage()
    }

    const [email, setEmail] = createSignal(useFinanceContactEmail() ?? '')

    const isValid = () => email().length > 0

    const handleEmailInput = (e: any) => setEmail(e.target.checkValidity() ? e.target.value : '')
    const handleEmailChange = (e: any) => setFinanceContactEmail(email())

    const quoteRequest = getQuoteSubscriptionRequestWithSavedQuote()

    const inviteUser = async () => {
        const inviteUserRequest = {
            email: email(),
            quoteId: quoteRequest.subscription.id,
            accountId: quoteRequest.account.id,
            firstName: '',
            lastName: ''
        }

        console.log('inviting user', inviteUserRequest)

        //TODO: what to do if the user is already invited? or if the user goes back and re-invites?
        const response = await subscriptionService.inviteCompanyFinanceUser(inviteUserRequest)
        console.log('invite response', response)
    }

    return <>
        <TextSection>
            <CompanySchemeHeader />
            <p>Add your company direct debit details.</p>
            <p>You can add details for a finance contact to add Direct Debit details if you do not have direct debit authority.</p>
        </TextSection>

        <FormSection>
            <Title>Finance Contact:</Title>

            <div class={quoteStyles.Section}>
                <p>Please enter the email of someone that is authorised to add your company Direct Debit details</p>
                <input 
                    name="email" 
                    type="email" 
                    placeholder="Email" 
                    required 
                    value={useFinanceContactEmail() ?? ''} 
                    onInput={handleEmailInput} 
                    onChange={handleEmailChange} 
                    autocomplete="off" 
                />
            </div>
        </FormSection>

        <TextSection>
           <NavigationButtons onNext={inviteUser} nextButtonDisabled={!isValid()} nextButtonText="Send invite" />
        </TextSection>
    </>
}

export const BackToDirectDebitAuthority = () => {
    const navigate = useNavigate()
    const params = useParams()  

    return <span onClick={() => navigate(`/quotes/${params.quoteId}/dd-authority`)}>
        <PreviousButtonOnly />
    </span>
}