import { AnEvent } from '@punnet/core-event-pure'

import { Properties } from '@punnet/pure-utility-kit'

import type { AccountType } from '../../../domain/models/Account'
import type { CancellationType } from '../../../domain/models/CancellationType'
import type { CancellationEventData, LifeEventData } from '../../notifications/notification-types'


export class MemberCancellationEmailSendRequest extends AnEvent implements LifeEventData, CancellationEventData {
    constructor(from: Properties<MemberCancellationEmailSendRequest>) {
        super()
        Object.assign(this, from)
    }

    accountId: string
    accountType: AccountType
    subscriptionId: string
    lifeId: string
    cognitoId: string
    policyId: string
    cancellationReason: string
    cancellationType: CancellationType
}
