
import { type Component } from 'solid-js'
import styles from './DirectDebitSuccess.module.css'

export const DirectDebitSuccess: Component = () => {

    return (
        <div class={styles.Success}>
            <h1 class={styles.Header}>All done!</h1>
            <div class={styles.Text}>
                <p>
                    You have successfully added the direct debit details for your company scheme.
                </p>
                <p>
                    We've emailed your colleague to let them know.
                </p>
            </div>
           
        </div>
    )
}