import { AnEvent } from '@punnet/core-event-pure'

import {Properties} from '@punnet/pure-utility-kit'


export class CheckComplianceRequest extends AnEvent {
    clientRef?: string
    email: string
    firstname: string
    lastname: string
    birthdate: string

    constructor(from: Properties<CheckComplianceRequest>) {
        super()
        Object.assign(this, from)
    }
}

