export {AddressLookup} from './src/AddressLookup'
export type {AddressLookupProps} from './src/AddressLookup'
export { firstMatchingAddressFetcher } from './src/findMatchingAddress'

        import * as me from '@punnet/address-lookup-web-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/charlie-gould/git/peachy-repo/punnet/stacks/address-lookup/web-client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@punnet/address-lookup-web-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    