import { CompletionObserver, concatMap, ErrorObserver, NextObserver, Observable, Subscription } from 'rxjs'
import { isArray, isNumber, isObject, keys } from '@punnet/pure-utility-kit'
import { RepoHash, StringHash } from './hash-primatives'

import { Page, PageValue } from './page-primatives'
import { AsyncPredicate } from '@punnet/pure-utility-kit'

export type ProxyKey = string | symbol

export type Key = string | number

export type KeyPath = Key[]
export type KeyPathString = string

export function keyPathString(keyPath: KeyPath): KeyPathString {
    return keyPath.map(k => isNumber(k) ? `[${k}]` : `.${k}`).join('')
}


export type Consumer<T> = (x: T) => void

export type NodeObserver<T, ReadOnly extends boolean> =
    NextObserver<RepoNode<T, ReadOnly>>
    | ErrorObserver<RepoNode<T, ReadOnly>>
    | CompletionObserver<RepoNode<T, ReadOnly>>
    | Consumer<RepoNode<T, ReadOnly>>


type GetNode<T> = (...args: []) => Promise<T>
type SetNode<T> = (x: T, commitMessage?: string) => Promise<StringHash>
type ObserveNode<T, ReadOnly extends boolean> = {
    $stream: <A extends NodeObserver<T, ReadOnly>[]>(...args: A) =>
        A extends [NodeObserver<T, ReadOnly>]
        ? Subscription :
        A extends [] ? Observable<RepoNode<T, ReadOnly>> : never
}

type CommitMessage = string
export type Transaction<_T, ReadOnly extends boolean> =
    (node: RepoNode<_T, ReadOnly>) =>
        ReadOnly extends true ? Promise<void> : Promise<CommitMessage>


type FilterNode<T, ReadOnly extends boolean> =
    T extends any[] ? {
        $streamQuery: (predicate: AsyncPredicate<RepoNode<ElementOf<T>, ReadOnly>>) => Observable<RepoNode<ElementOf<T>, ReadOnly>[]>
        $query: (predicate: AsyncPredicate<RepoNode<ElementOf<T>, ReadOnly>>) => Promise<RepoNode<ElementOf<T>, ReadOnly>[]>
    } : {}


type TransactNode<T, ReadOnly extends boolean> = {
    $transact: (transaction: Transaction<T, ReadOnly>) => Promise<void>
}


type KeysNode<T> =
    T extends object ? {
        $keys: () => Promise<Extract<keyof T, string>[]>
    } : {}


export type RepoNode<T, ReadOnly extends boolean = false> =
    (ReadOnly extends false ? SetNode<T> : {}) &
    GetNode<T> &
    FilterNode<T, ReadOnly> &
    TransactNode<T, ReadOnly> &
    KeysNode<T> &
    ObserveNode<T, ReadOnly> & (
        T extends object
        ? {
            [K in keyof T]: RepoNode<T[K], ReadOnly>
        }
        : {
            [_K in keyof T]: never
        }
    )


export type NodeActor<ReadOnly extends boolean> = {
    getNodeValue<T = unknown>(path: KeyPath): Promise<T | null>
    getNodePage(path: KeyPath): Promise<Page | PageValue>
    setNodeValue(path: KeyPath, value: unknown, commitMessage: string): Promise<RepoHash | null>
    observeNode<T>(path: KeyPath): Observable<RepoNode<T, ReadOnly>>
    transactNode<T>(path: KeyPath, transaction: Transaction<T, ReadOnly>): Promise<void>
}


export function graphNode<T, Readonly extends boolean>(actor: NodeActor<Readonly>, path: KeyPath = []): RepoNode<T, Readonly> {
    const proxyCallable = () => {
    }

    const proxy: RepoNode<T, Readonly> = new Proxy(proxyCallable, {
        get(target: any, key: ProxyKey): any {

            switch (key) {
                case 'then':
                    return undefined
                case 'toJSON':
                    return () => ({ warning: path })

                case '$keys':
                    return keysFunction(actor, path)
                case '$stream':
                    return streamFunction(actor, path)
                case '$streamQuery':
                    return streamQueryFunction(actor, path)
                case '$query':
                    return queryFunction(actor, path)
                case '$transact':
                    return transactFunction(actor, path)

                default:
                    return typeof key === 'string'
                        ? graphNode(actor, [...path, Number.isInteger(+key) ? +key : key])
                        : undefined
            }
        },
        apply(target: any, thisArg: any, argArray: any[]): any {
            if (argArray.length) {
                return actor?.setNodeValue?.(path, argArray[0], argArray[1])
            } else {
                return actor?.getNodeValue?.(path)
            }
        }
    })
    return proxy
}


type ElementOf<A extends any[]> = A extends Array<infer E> ? E : never

function streamQueryFunction<A extends unknown[], Readonly extends boolean>(actor: NodeActor<Readonly>, path: KeyPath) {
    return (predicate: AsyncPredicate<RepoNode<ElementOf<A>, Readonly>>) => {
        const a = actor.observeNode<A>(path).pipe(
            concatMap(async (array) => {
                const pageArray = await actor.getNodePage(path)
                if (isArray(pageArray)) {
                    const pageElements = await Promise.all(pageArray.map((value, index) => graphNode<ElementOf<A>, Readonly>(actor, [...path, index])))
                    const filteredElements = [] as A
                    for (let i = 0; i < pageElements.length; i++) {
                        const element = pageElements[i]
                        if (await predicate(element, i)) {
                            filteredElements.push(element)
                        }
                    }
                    return filteredElements
                } else {
                    return []
                }
            }),
        )
        return a
    }
}

function queryFunction<A extends unknown[], Readonly extends boolean>(actor: NodeActor<Readonly>, path: KeyPath) {
    return async (predicate: AsyncPredicate<RepoNode<ElementOf<A>, Readonly>>): Promise<A> => {

        const pageArray = await actor.getNodePage(path)

        if (isArray(pageArray)) {
            const pageElements = await Promise.all(pageArray.map((value, index) => graphNode<ElementOf<A>, Readonly>(actor, [...path, index])))
            const filteredElements = [] as A
            for (let i = 0; i < pageElements.length; i++) {
                const element = pageElements[i]
                if (await predicate(element, i)) {
                    filteredElements.push(element)
                }
            }
            return filteredElements
        } else {
            return [] as A
        }
    }
}

function transactFunction<T, ReadOnly extends boolean>(actor: NodeActor<ReadOnly>, path: KeyPath) {
    return (transaction: Transaction<T, ReadOnly>) => {
        return actor.transactNode(path, transaction)
    }
}


function streamFunction<T, Readonly extends boolean>(actor: NodeActor<Readonly>, path: KeyPath) {
    return (observer?: NodeObserver<T, Readonly>): any => {
        const observable = actor.observeNode<T>(path)
        if (observer) {
            return observable.subscribe(observer)
        } else {
            return observable
        }
    }
}


function keysFunction<T, Readonly extends boolean>(actor: NodeActor<Readonly>, path: KeyPath) {
    return async () => {
        const page = await actor.getNodePage(path)
        return isObject(page)
            ? keys<T>(page as T)
            : null

    }
}
