import {concatMap, EMPTY, Observable, toArray} from 'rxjs'

export function collect<T>(then: (arr: T[]) => void | Observable<unknown>) {
    return (o: Observable<T>) => {
        return o.pipe(
            toArray(),
            concatMap(arr => {
                const r = then(arr)
                if (r) {
                    return r
                } else {
                    return EMPTY
                }
            })
        )
    }
}
