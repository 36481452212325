import {defineValidator, MessageProducer} from '../validation-core'

export default function IsNotEqual<T>(
    bannedValue: any,
    message?: string | MessageProducer<T>
) {
    return defineValidator<T>({
        name: 'IsNotEqual',
        messageParams: {bannedValue},
        message:
            message ??
            (({property, bannedValue}) =>
                `Property ${property.toString()} must not equal '${bannedValue}'`),
        validate({value}) {
            return value !== bannedValue
        },
    })
}
