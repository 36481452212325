import { AnEvent } from '@punnet/core-event-pure'

import { LifeEventData } from './notification-types'

export function lifeEventDataFrom<E extends AnEvent & LifeEventData>(event: E): LifeEventData {
    return {
        accountId: event.accountId,
        accountType: event.accountType,
        cognitoId: event.cognitoId,
        lifeId: event.lifeId,
        policyId: event.policyId,
        subscriptionId: event.subscriptionId
    }
}

export function isAdultLife(lifeEventData: LifeEventData) {
    return !!lifeEventData.cognitoId
}
