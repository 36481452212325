import type { Component, JSXElement } from 'solid-js'
import type { Benefit } from '@punnet/product-client'
import styles from './BenefitLabel.module.css'

type BenefitLabelProps = {
    benefit: Benefit
    description: JSXElement
    icon: JSXElement
    onClick?: () => void
}

export const BenefitLabel: Component<BenefitLabelProps> = (props) => (
    <div class={styles.label} onClick={props.onClick}>
        <span class={styles.icon}>{props.icon}</span>
        <span class={styles.text}>
            <span class={styles.name}>{props.benefit.name}</span>
            <span class={styles.description}>{props.description}</span>
        </span>
        <span class={styles.info}><i class="fa-regular fa-circle-info"></i></span>
    </div>
)