{
    
    "plans": [
        {
            "id": "sovereign-level1",
            "name": "Level 1",
            "availableBenefits": {
                "OPTICAL": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 7000
                },
                "DENTAL": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 7000
                },
                "PRESCRIPTION": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 1200
                },
                "PHYSIO": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 15000
                },
                "CHIROPODY": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 5000 
                },
                "ACUPUNCTURE": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 5000
                },
                "HEALTH_SCREENING": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 10000
                },
                "CONSULTATIONS_DIAGNOSTICS": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 12500
                },
                "HOSPITAL_IN_PATIENT": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 1000
                },
                "BIRTH_ADOPTION": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 10000
                },
                "GP_SERVICE": {
                    "include": true,
                    "isEditable": false
                },
                "SOVEREIGN_PERKS": {
                    "include": true,
                    "isEditable": false
                },
                "HEALTH_WELLBEING": {
                    "include": true,
                    "isEditable": false
                },
                "EAP": {}
            }
        },
        {
            "id": "sovereign-level2",
            "name": "Level 2",
            "availableBenefits": {
                "OPTICAL": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 10000
                },
                "DENTAL": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 10000
                },
                "PRESCRIPTION": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 1500
                },
                "PHYSIO": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 20000
                },
                "CHIROPODY": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 7500 
                },
                "ACUPUNCTURE": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 7500
                },
                "HEALTH_SCREENING": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 12500
                },
                "CONSULTATIONS_DIAGNOSTICS": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 17500
                },
                "HOSPITAL_IN_PATIENT": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 1500
                },
                "BIRTH_ADOPTION": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 12500
                },
                "GP_SERVICE": {
                    "include": true,
                    "isEditable": false
                },
                "SOVEREIGN_PERKS": {
                    "include": true,
                    "isEditable": false
                },
                "HEALTH_WELLBEING": {
                    "include": true,
                    "isEditable": false
                },
                "EAP": {}
            }
        },
        {
            "id": "sovereign-level3",
            "name": "Level 3",
            "availableBenefits": {
                "OPTICAL": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 15000
                },
                "DENTAL": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 15000
                },
                "PRESCRIPTION": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 2400
                },
                "PHYSIO": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 30000
                },
                "CHIROPODY": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 10000 
                },
                "ACUPUNCTURE": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 10000
                },
                "HEALTH_SCREENING": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 20000
                },
                "CONSULTATIONS_DIAGNOSTICS": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 25000
                },
                "HOSPITAL_IN_PATIENT": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 2000
                },
                "BIRTH_ADOPTION": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 15000
                },
                "GP_SERVICE": {
                    "include": true,
                    "isEditable": false
                },
                "SOVEREIGN_PERKS": {
                    "include": true,
                    "isEditable": false
                },
                "HEALTH_WELLBEING": {
                    "include": true,
                    "isEditable": false
                },
                "EAP": {}
            }
        },
        {
            "id": "sovereign-level4",
            "name": "Level 4",
            "availableBenefits": {
                "OPTICAL": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 21000
                },
                "DENTAL": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 21000
                },
                "PRESCRIPTION": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 3600
                },
                "PHYSIO": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 45000
                },
                "CHIROPODY": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 15000 
                },
                "ACUPUNCTURE": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 15000
                },
                "HEALTH_SCREENING": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 25000
                },
                "CONSULTATIONS_DIAGNOSTICS": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 37500
                },
                "HOSPITAL_IN_PATIENT": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 3000
                },
                "BIRTH_ADOPTION": {
                    "include": true,
                    "isEditable": false,
                    "defaultLimit": 20000
                },
                "GP_SERVICE": {
                    "include": true,
                    "isEditable": false
                },
                "SOVEREIGN_PERKS": {
                    "include": true,
                    "isEditable": false
                },
                "HEALTH_WELLBEING": {
                    "include": true,
                    "isEditable": false
                },
                "EAP": {}
            }
        }
    ],
    "benefits": [
        {
            "id": "OPTICAL",
            "name": "Optical",
            "benefitTypes": ["OPTICAL"]
        },
        {
            "id": "DENTAL",
            "name": "Dental",
            "benefitTypes": ["DENTAL"]
        },
        {
            "id": "PRESCRIPTION",
            "name": "Prescriptions / Vaccinations",
            "benefitTypes": ["PRESCRIPTION"]
        },
        {
            "id": "PHYSIO",
            "name": "Physiotherapy / Osteopathy / Chiropractic / Sports Massage",
            "benefitTypes": ["PHYSIO"]
        },
        {
            "id": "CHIROPODY",
            "name": "Chiropody / Podiatry",
            "benefitTypes": ["CHIROPODY"]
        },
        {
            "id": "ACUPUNCTURE",
            "name": "Acupuncture / Homeopathy / Reflexology / Earwax Removal",
            "benefitTypes": ["ACUPUNCTURE"]
        },
        {
            "id": "HEALTH_SCREENING",
            "name": "Health screening",
            "benefitTypes": ["HEALTH_SCREENING"]
        },
        {
            "id": "CONSULTATIONS_DIAGNOSTICS",
            "name": "Hospital consultations & diagnostics",
            "benefitTypes": ["CONSULTATIONS_DIAGNOSTICS"]
        },
        {
            "id": "HOSPITAL_IN_PATIENT",
            "name": "Hospital in-patient & day-case admission",
            "benefitTypes": ["HOSPITAL_IN_PATIENT"]
        },
        {
            "id": "BIRTH_ADOPTION",
            "name": "Birth / Adoption",
            "benefitTypes": ["BIRTH_ADOPTION"]
        },
        {
            "id": "GP_SERVICE",
            "name": "GP 24/7 service",
            "benefitTypes": ["GP_SERVICE"]
        },
        {
            "id": "SOVEREIGN_PERKS",
            "name": "Sovereign Perks",
            "benefitTypes": ["SOVEREIGN_PERKS"]
        },
        {
            "id": "HEALTH_WELLBEING",
            "name": "Health & wellbeing resources",
            "benefitTypes": ["HEALTH_WELLBEING"]
        },
        {
            "id": "EAP",
            "name": "Optional Employee Assistance Programme (EAP)",
            "benefitTypes": ["EAP"],
            "global": true,
            "isEditable": true,
            "include": false
        }
    ]
}
