import { sortBy } from 'lodash-es'
import { createSignal, For, type Component } from 'solid-js'
import type { MemberPolicy } from '../../../app/store/members/mapToMemberPolicies'
import { useMembersPolicies } from '../../../app/store/members/membersStore'
import { ListTable } from '../../ListTable/ListTable'
import styles from './MembersList.module.css'
import { MembersRow } from './MembersRow'

// TODO need header model to pass icon component
const headers = [
    'First name',
    'Surname',
    'Member type',
    'Start date',
    'Policy number',
    'Company funded level',
    'Company funded',
    'Upgrade',
    'Payroll deduction',
    'Total plan level',
    'Total premium',
    '',
    ''
]

const DEFAULT_SORT_ORDER = [
    (p: MemberPolicy) => {
        switch (p.action) {
            case 'ADD': return 1
            case 'CHANGE_COVER': return 2
            case 'REMOVE': return 3
            case 'IDLE': return 4
            case 'CANCEL': return 5
        }
    },
    // lastname
    (p: MemberPolicy) => p.primary.lastname
]

export const MembersList: Component = () => {

    const [sortOrder, setSortOrder] = createSignal(DEFAULT_SORT_ORDER)

    const memberPolicies = () => sortBy(useMembersPolicies(), sortOrder())

    return (
        <ListTable headers={headers} tableClass={styles.ListTable}>
            <For each={memberPolicies()}>{(memberPolicy) => {
                return (<>
                    <RowGap />
                    <MembersRow {...memberPolicy} />
                </>)
            }}</For>
        </ListTable>
    )
}

const RowGap: Component = () => <tr><td class={styles.RowGap} /></tr>