import {QuestionOption} from '../QuestionOption'
import {Media} from './Media'
import {BankDetails} from './BankDetails'
import {enumerate} from '@peachy/utility-kit-pure'

export const QuestionTypes = enumerate([
    'OPTION',
    'DATE_DAY_MONTH_YEAR',
    'DATE_MONTH_YEAR',
    'NUMERIC',
    'FREETEXT',
    'BANK_DETAILS',
    'VIDEO',
    'IMAGE',
    'SIGNATURE'
] as const)

export type QuestionType = keyof typeof QuestionTypes

export type QuestionAnswer = string | number | Date | QuestionOption | BankDetails | Media

export const QuestionPresentations = enumerate([
    'CHECKBOX',
    'TEXT',
    'PICKER',
    'APPOINTMENT_PICKER',
    'BUTTON',
    'CAMERA',
    'CAMERA_AND_FILE_PICKER',
    'DRAW'
] as const)
export type QuestionPresentation = keyof typeof QuestionPresentations
