import { type Draft } from '@punnet/pure-utility-kit'
import { Card, classList } from '@punnet/web-client-kit-library'
import { Plan, Policy } from '@punnet/subscription-pure'
import { For, Show, createSignal, type Component } from 'solid-js'
import styles from './PlanMembership.module.css'

type PlanMembershipProps = {
    plans: Plan[]
    policies: Draft<Policy>[]
    count: (planId: string) => number
    min?: number
    max?: number
    onAdd?: (planId: string, numberOfLives: number) => void
    onRemove?: (planId: string, numberOfLives: number) => void
    isPlanActive?: (planId: string) => boolean
    isEditable?: boolean
    planActiveClass?: string
    class?: string
}

const [propsStore, setPropsStore] = createSignal<PlanMembershipProps>()

const currentCount = (planId: string) => { 
    return propsStore().count(planId)
}

const add = (plan: Plan, numberToAdd: number) => {
    if ((currentCount(plan.id) + numberToAdd) <= propsStore().max) {
        propsStore().onAdd(plan.id, numberToAdd)
    }
}

const remove = (plan: Plan, numberToRemove: number) => {
    if ((currentCount(plan.id) - numberToRemove) >= propsStore().min) {
        propsStore().onRemove(plan.id, numberToRemove)
    }
}

const ReadonlyCount: Component<{plan: Plan}> = (props) => {
    return <>
        <span/>
        <span>{currentCount(props.plan.id)}</span>
        <span/>
    </>
}

const EditableCount: Component<{plan: Plan}> = (props) => {
    const count = () => currentCount(props.plan.id)

    const onChange = (e: Event) => {
        const inputElement = e.target as HTMLInputElement
        const isValid = inputElement.validity.valid

        const previousCount = count(), newCount = parseInt(inputElement.value), countDifference = (newCount - previousCount)

        // keep value the same if invalid or unchanged
        if (!isValid || (newCount == previousCount) || isNaN(newCount)) {
            inputElement.value = previousCount.toString()
            return
        }
        
        if (countDifference > 0) {
            add(props.plan, countDifference)
        } else {
            remove(props.plan, Math.abs(countDifference))
        }
    }

    return (
        <input 
            type="number" 
            min={propsStore().min} 
            max={propsStore().max} 
            onChange={onChange} 
            value={count()} 
            class={styles.LifeCount} 
        />
    )
}

const Button: Component<{ value: string, onClick: () => void, disabled?: boolean }> = (props) => {
    return <a class={classList(styles.Button, props.disabled ? styles.ButtonDisabled : '')} onClick={props.onClick}>{props.value}</a>
}

const AddButton: Component<{ plan: Plan }> = (props) => {
    const count = () => currentCount(props.plan.id)
    return <Button value="+" onClick={() => add(props.plan, 1)} disabled={count() >= propsStore().max} />
}

const RemoveButton: Component<{ plan: Plan }> = (props) => {
    const count = () => currentCount(props.plan.id)
    return <Button value="-" onClick={() => remove(props.plan, 1)} disabled={count() <= propsStore().min} />
}

const PlanMemberCard: Component<{plan: Plan}> = (props) => {
    const isPlanActive = () => propsStore().isPlanActive?.(props.plan.id) ?? false

    return (
        <Card class={isPlanActive() ? propsStore().planActiveClass : ''}>
            <div class={styles.PlanMemberCard}>
                <Show when={propsStore().isEditable} fallback={<ReadonlyCount plan={props.plan} />}>
                    <RemoveButton plan={props.plan} />
                    <EditableCount plan={props.plan} />
                    <AddButton plan={props.plan} />
                </Show>
            </div>
        </Card>
    )
}

export const PlanMembership: Component<PlanMembershipProps> = (props) => {
    setPropsStore(props)

    return (
        <div class={props.class}>
            <Card>{/* empty card */}</Card>
            <div class={styles.PlanMemberCards}>
                <For each={props.plans}>
                    {(plan) => (<PlanMemberCard plan={plan} />)}
                </For>
            </div>
        </div>
    )
}
