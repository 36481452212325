import { AnEvent } from '@punnet/core-event-pure'

import { Properties } from '@punnet/pure-utility-kit'

export class SendIndividualPolicyDocReIssueRequest extends AnEvent {
    constructor(props: Properties<SendIndividualPolicyDocReIssueRequest>) {
        super()
        Object.assign(this, props)
    }
    accountId: string
    lifeId: string
    policyId: string
    subscriptionId: string
}
