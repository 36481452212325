import {map, Observable, zip} from 'rxjs'
import {filterNull} from './filterNull'

export function zipFilter<T>(
    item$: Observable<T>,
    existence$: Observable<unknown>
) {
    return zip(item$, existence$).pipe(
        map(([item, exists]) => exists ? item : null),
        filterNull()
    )
}
