import { IdCheckEvent } from './IdCheckEvent'
import { Properties } from '@punnet/pure-utility-kit'

export class IdCheckPassed extends IdCheckEvent {

    constructor(from: Properties<IdCheckPassed>) {
        super(from)
        this.type = this.constructor.name
    }
}
