import { classList, currencyFromPence, displayableId, readableDate, type Draft } from '@punnet/pure-utility-kit'
import type { Life } from '@punnet/subscription-pure'
import { For, Show, createMemo, type Component } from 'solid-js'
import type { MemberPolicy, MemberPolicyAction } from '../../../app/store/members/mapToMemberPolicies'
import { useMembersPlans, useMembersStoreActions } from '../../../app/store/members/membersStore'
import { LevelSelector } from '../LevelSelector/LevelSelector'
import styles from './MembersList.module.css'


export const MembersRow: Component<MemberPolicy> = (props) => {

    const highlightClass = createMemo(() => {
        switch (props.action) {
            case 'IDLE': return ''
            case 'ADD': return styles.HighlightNew
            case 'CHANGE_COVER': return styles.HighlightUpdated
            case 'REMOVE': return styles.HighlightRemoved
        }
    })

    const lives = createMemo(() => [props.primary, props.secondary].filter(it => !!it))

    return (
        <>
            <PolicyRow
                policyId={props.id}
                lives={lives()}
                action={props.action}
                class={highlightClass()}
            />
        </>
    )

}

const PolicyRow: Component<{ policyId: string, lives: Draft<Life>[], action: MemberPolicyAction, class?: string }> = (props) => {
    const classes = (index: number) => {
        return classList(
            (props.lives.length === 1) ? styles.SingleLife : styles.MultiLife,
            (props.lives.length > 1 && index === 0) ? styles.FirstLife : '',
            (props.lives.length > 1 && index === props.lives.length - 1) ? styles.LastLife : '',
            props.class
        )
    }

    return (
        <>
            <For each={props.lives}>
                {(life, index) => (
                    <MemberRow
                        policyId={props.policyId}
                        life={life}
                        action={props.action}
                        class={classes(index())}
                    />
                )}
            </For>
        </>
    )
}

const MemberRow: Component<{
    policyId: string
    life: Draft<Life>
    action: MemberPolicyAction
    class?: string
}
> = (props) => {
    const { updateMemberAction, removeMember } = useMembersStoreActions()

    const memberType = (life: Draft<Life>) => {
        switch (life.type) {
            case 'PRIMARY': return 'Employee'
            case 'SECONDARY': return 'Additional adult'
            case 'DEPENDANT': return 'Additional child'
        }
    }

    return (
        <tr class={props.class}>
            <td>{props.life.firstname}</td>
            <td>{props.life.lastname}</td>
            <td>{memberType(props.life)}</td>
            <td>
                {readableDate(props.life.startDate, 'dd MMM yyyy')}
            </td>
            <td>
                {props.action === 'ADD' ? 'N/A' : displayableId(props.policyId)}
            </td>
            <td>
                <Show when={props.life.type === 'PRIMARY'} fallback={'N/A'}>
                    <LevelSelector
                        policyId={props.policyId}
                        plans={useMembersPlans()}
                        planId={props.life.planId}
                        action={props.action}
                        disabled={props.action === 'REMOVE'} />
                </Show>
            </td>
            <td></td>
            <td>{/* Uprade */}</td>
            <td>{/* Payroll */}</td>
            <td>{/* Total plan level */}</td>
            <td>
                {currencyFromPence(props.life.totalMonthlyPremium ?? 0)}
            </td>
            <td>
                <Show when={props.life.type === 'PRIMARY'}>
                    Edit
                </Show>
            </td>
            <td>
                {/* TODO Getting complex */}
                <Show when={props.life.type === 'PRIMARY'} >
                    <a
                        class={styles.RemovePolicyButton}
                        onClick={() => {
                            switch (props.action) {
                                case 'ADD': return removeMember(props.policyId)
                                case 'CHANGE_COVER': return updateMemberAction(props.policyId, 'REMOVE', true)
                                case 'REMOVE': return updateMemberAction(props.policyId, 'IDLE')
                                case 'IDLE': return updateMemberAction(props.policyId, 'REMOVE')
                            }
                        }}>
                        {props.action === 'REMOVE' ? 'Re-instate' : 'Remove'}
                    </a>
                </Show>
            </td>
        </tr >)
}

