import type { Observable, OperatorFunction } from 'rxjs'
import { map, share, zipWith } from 'rxjs/operators'



export type WithSideEffect<T, E> = { item: T, effect: E }

export function withSideEffect<T, E>(
    effect: OperatorFunction<T, E>
) {
    return (source: Observable<T>): Observable<WithSideEffect<T, E>> => {
        const shared = source.pipe(share())
        return shared.pipe(
            zipWith(shared.pipe(effect)),
            map(([item, effect]) => ({ item, effect }))
        )
    }
}

export function runSideEffect<T>(
    effect: OperatorFunction<T, any>
) {
    return (source: Observable<T>): Observable<T> => {
        return withSideEffect(effect)(source).pipe(
            map(({ item }) => item)
        )
    }
}
