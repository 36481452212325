import {defineValidator, MessageProducer, ValidateIf} from '../validation-core'
import {dateFromIso, isIsoDate, yearsSince} from '@punnet/pure-utility-kit'

export default function MinAge<T>(
    minAge: number,
    message?: string | MessageProducer<T>,
    validateIf?: ValidateIf<T>
) {
    return defineValidator<T>({
        name: 'MinAge',
        message:
            message ??
            (({property}) =>
                `Property ${property.toString()} must be at least ${minAge} years ago`),
        messageParams: {minAge},
        validateIf,
        validate({value}) {
            return isIsoDate(value) && yearsSince(dateFromIso(value)) >= minAge
        },
    })
}
