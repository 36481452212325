import config from './sovereign-config.json'

/**
 * This file just loads the config file and provides wrapper functions for the config values.
 * 
 * TODO: move this elsewhere and create a model for the config - something generic that is shared between apps and tenants
 */

export type MinMaxBounds = {
    min?: number
    max?: number
}

export type DateOffset = {
    startDays: number
    endDays: number
}


export const getTotalPolicyBounds = (): MinMaxBounds => config.bounds.policies.total
export const getPlanPolicyBounds = (): MinMaxBounds => config.bounds.policies.plan

export const getStartDateOffset = (): DateOffset => config.bounds.startDate

export const getPrimaryAgeBounds = (): MinMaxBounds => config.validation.lives.age.primary
export const getSecondaryAgeBounds = (): MinMaxBounds => config.validation.lives.age.secondary
export const getDependantAgeBounds = (): MinMaxBounds => config.validation.lives.age.dependant
