import {BenefitType} from './types'
import {assign} from 'lodash-es'

type ExcessConstructor = Pick<Excess, 'id' | 'amountInPence' | 'benefitTypes'>
export class Excess {

    public readonly id: string
    public readonly amountInPence: number
    public readonly benefitTypes: BenefitType[]

    constructor(props: ExcessConstructor) {
        return assign(this, props)
    }

    public withUsage(planYearId: string, usageInPence: number) {
        return new ExcessWithUsage({...this, planYearId, usageInPence})
    }

    public appliesTo(givenBenefitType: BenefitType) {
        return this.benefitTypes.includes(givenBenefitType)
    }
}

type ExcessWithUsageConstructor = ExcessConstructor & Pick<ExcessWithUsage, 'planYearId' | 'usageInPence'>
export class ExcessWithUsage extends Excess {

    public readonly planYearId: string
    public readonly usageInPence: number

    constructor(props: ExcessWithUsageConstructor) {
        super(props)
        return assign(this, props)
    }

    get amountOutstandingInPence() {
        return Math.max(this.amountInPence - this.usageInPence, 0)
    }

}
