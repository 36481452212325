import { Spinner } from '@punnet/web-client-kit-library'
import { Show, createMemo, type Component } from 'solid-js'
import { getTotalMonthlyPremium, useIsQuoting } from '../../../app/pages/Quotes/QuoteController/QuoteController'
import { getCurrentTotalMonthlyPremium } from '../../../app/services/AccountProvider'
import { QuoteFooterButton } from '../../Button/Buttons'
import { nextPage } from '../../PageRouter/PageRouter'
import { FormattedPrice } from '../../Quote/Price/FormattedPrice'
import styles from './QuoteFooter.module.css'


export const QuoteFooter = () => {
    const currentPremium = getCurrentTotalMonthlyPremium()
    const newPremium = createMemo(() => getTotalMonthlyPremium())
    const isQuoting = useIsQuoting()

    return (
        <section class={styles.Footer}>
            <CurrentPrice value={currentPremium} />
            <NewPrice value={newPremium()} isLoading={isQuoting()} />
        </section>
    )
}

const CurrentPrice: Component<{ value: number }> = (props) => (
    <div class={styles.CurrentPrice}>
        <Price label="Current" value={props.value} />
    </div>
)

const NewPrice: Component<{ isLoading: boolean, value: number }> = (props) => (
    <div class={styles.NewPrice}>
        <Show when={!props.isLoading && props.value} fallback={<Spinner/>}>
            <Price label="New" value={props.value} />
            <QuoteFooterButton onClick={nextPage}>Let's go →</QuoteFooterButton>
        </Show>
    </div>
)

const Price: Component<{ value: number, label: string }> = (props) => (
    <span>
        <label>{props.label}:</label> <FormattedPrice price={props.value} />
    </span>
)
