import { useIamService } from '@punnet/iam-client'
import { Header } from './Header/Header'
import { Show, createEffect, type ParentProps } from 'solid-js'
import { useNavigate } from '@solidjs/router'
import { useAuthenticationState } from '../../services/authentication/AuthenticationController'
import styles from './MainLayout.module.css'

export function MainLayout(props: ParentProps) {

    const iamService = useIamService(), navigate = useNavigate(), authenticatedState = useAuthenticationState()

    // TODO: not ideal this is in layout but this has to go here as it's the only place in the app that is available on every route
    // and therefore the only place we can handle sign out effectively - other places either don't trigger this OR we get an error on routing during sign out
    createEffect(() => {
        if (authenticatedState() === 'SignedOut') {
            navigate('/', { replace: true })
        }
    })

    return (
        <>
            <Header />
            <main class={styles.Main}>
                <Show when={iamService.isSignedIn()}>
                    {props.children}
                </Show>
            </main>
        </>
    )
}