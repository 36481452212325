import {KeyMapped, mapById, Optional} from '@punnet/pure-utility-kit'
import * as PlanConfig from '@punnet/product-client'

import { PlanBenefit } from '@punnet/subscription-pure'

//TODO: CC - for consistency, we should pass in the PlanConfig Excess object rather than the domain model
export function mapToPlanBenefit (benefit: PlanConfig.Benefit, planExcess?: PlanConfig.Excess): PlanBenefit[] {
    const mapLimit = (benefitType: string) => {
        return typeof benefit.defaultLimit === 'object' ? benefit.defaultLimit[benefitType] : benefit.defaultLimit
    }

    return benefit.benefitTypes.map(benefitType => {
        const planBenefit = {
            id: benefitType,
            limit: mapLimit(benefitType)
        } as PlanBenefit

        if (planExcess?.benefitTypes?.includes(benefit.id)) {
            planBenefit.benefitSpecificExcess = false
            planBenefit.excess = planExcess.defaultLimit
        }
        return planBenefit
    })
}

/**
 * Returns a collection of PlanBenefit objects mapped from plan config benefits.
 *
 * Note:
 *  - Benefits that are not active are not mapped
 *  - A single Benefit may produce multiple PlanBenefit objects
 *  - The defaultLimit for the benefit is used
 *
 * @param benefits
 * @param planExcess
 * @returns
 */
//TODO: CC - for consistency, we should pass in the PlanConfig Excess object and not the domain model
export function mapToPlanBenefits (benefits: PlanConfig.Benefit[], planExcess?: PlanConfig.Excess): KeyMapped<PlanBenefit> {

    const planBenefits = benefits.flatMap(benefit => {
        if (!benefit.isActive) return
        return mapToPlanBenefit(benefit, planExcess)
    })

    return mapById(planBenefits.filter(b => !!b))
}

export function mapToExcess (excess?: PlanConfig.Excess): Optional<Excess> {
    if (excess) {
        //NOTE: as this'll end up in the store, we can't return this as an actual Excess object since it won't be reactive
        return {
            id: excess.id,
            amountInPence: excess.defaultLimit,
            benefitTypes: excess.benefitTypes
        } as Excess
    }
}
