import type {Accessor} from 'solid-js'
import {createBufferedSignal} from './createBufferedSignal'


/**
 * Creates a throttled signal with a specified initial value, maximum update rate, and buffer size.
 *
 * This function uses createBufferedSignal internally to manage a buffer of values.
 * It ensures that the signal's value is not updated more frequently than the specified maxRate.
 *
 * @param initialValue The initial value of the signal.
 * @param maxRate The minimum time (in milliseconds) between value updates.
 * @param bufferSize The maximum size of the internal buffer (default is 2).
 * @returns A tuple containing:
 *   - An accessor function to get the current value
 *   - A push function to add a new value (throttled)
 *   - An accessor function to get the entire buffer
 *
 * The throttled signal ensures that rapid changes to the value are buffered
 * and applied at a controlled rate, which can be useful for performance optimization
 * in scenarios like handling frequent user input or API calls.
 */

export function createThrottledSignal<T>(initialValue: T, maxRate: number, bufferSize: number = 2): ThrottledSignal<T> {

    const [value, push, advanceBuffer, buffer] = createBufferedSignal(initialValue, bufferSize)

    let timeoutHandle: any = 0
    let lastUpdatedAt = 0

    const checkBuffer = () => {
        if (buffer().length > 1 && !timeoutHandle) {
            const waitTime = Math.max((lastUpdatedAt + maxRate) - Date.now(), 0)
            timeoutHandle = setTimeout(() => {
                timeoutHandle = null
                lastUpdatedAt = Date.now()
                advanceBuffer()
                checkBuffer()
            }, waitTime)
        }
    }

    const pushValue = (value: T) => {
        if (push(value)) {
            checkBuffer()
            return true
        } else {
            return false
        }
    }

    return [value, pushValue, buffer]
}

export type ThrottledSignal<T> = [Accessor<T>, (value: T) => boolean, Accessor<T[]>]
