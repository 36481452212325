import { DatePickerModal, type DatePickerModalProps } from '@punnet/web-client-kit-library'

import styles from './SovereignDatePicker.module.css'

export function SovereignDatePicker(props: DatePickerModalProps) {

    return (
        <DatePickerModal
            {...props}
            class={styles.DatePicker}
            enableTransitions={true}
            transitionMarkerClass={styles.datePickerTransitionMarker}
            appearance={
                {
                    header: styles.DatePickerHeader,
                    nudgeSelector: styles.NudgeSelector,
                    nudgeSelectors: styles.NudgeSelectors,
                    nudgeLeft: styles.nudgeLeft,
                    nudgeRight: styles.nudgeRight,
                    monthTable: styles.monthTable,
                    weekNames: styles.weekNames,
                    allDays: styles.allDays,
                    inMonth: styles.inMonth,
                    outMonth: styles.outMonth,
                    selected: styles.selected,
                }
            }
        />
    )
}

