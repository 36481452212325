import { IsEmail, IsTrue, Required } from '@punnet/model-validation-pure'

export class User {
    id: string
    cognitoUserId?: string
    intercomUserId?: string

    @Required('Please enter your first name')
    firstname: string

    @Required('Please enter your last name')
    lastname: string

    @IsEmail('Please enter a valid email address')
    email?: string

    @Required()
    marketingAccepted: boolean = false

    @IsTrue<User>('You must agree to our Privacy Policy and Term of Service to proceed...')
    policiesAccepted: boolean = false

}