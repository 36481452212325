import { useLocation } from '@solidjs/router'
import type { JSXElement, ParentComponent } from 'solid-js'
import { Show, createEffect, createSignal } from 'solid-js'
import { getRouteNavigationPrimary, getRouteNavigationSecondary } from '../../../../AppRoutes'

export const Navigation: ParentComponent<{type: 'primary' | 'secondary'}> = (props) => {
    const location = useLocation()

    const [alternativeNavigation, setAlternativeNavigation] = createSignal<JSXElement | undefined>()

    createEffect(() => {
        const alternativeRoutes = props.type === 'primary' ? getRouteNavigationPrimary(location.pathname) : getRouteNavigationSecondary(location.pathname)
        setAlternativeNavigation(alternativeRoutes)
    })

    return <nav>
        <Show when={alternativeNavigation()}>
            {alternativeNavigation()}
        </Show>
        <Show when={!alternativeNavigation()}>
            {props.children}
        </Show>
    </nav>
}