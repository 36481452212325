/*eslint no-unused-vars: 0*/

// extracts union of function properties! take my word for it
export type FunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends Function ? K : never;
}[keyof T];

// extracts union of function properties! take my word for it
export type NonFunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

// strips functions, but preserves optionality
export type StrictBlueprint<T> = Omit<T,
    FunctionPropertyNames<T> | number | symbol>;

// extracts only functions?
export type FunctionalSpec<T> = Pick<T, FunctionPropertyNames<T>>;

// // strips functions and makes all properties optional
// export type Blueprint<T> = {
//     [K in StringKeyUnion<StrictBlueprint<T>>]?: T[K]
// }

export type Fields<T extends object> = keyof Draft<T>;

export type Keyed<T extends string = string, V = unknown> = {
    [k in T]: V;
};

export type KeysFrom<T extends string, V> = {
    [k in T]?: V;
};

type JustMethodKeys<T> = {
    [P in keyof T]: T[P] extends Function ? P : never;
}[keyof T];
type JustMethods<T> = Pick<T, JustMethodKeys<T>>;

type JustPropKeys<T> = {
    [P in keyof T]: T[P] extends Function ? never : P;
}[keyof T];
export type JustProps<T> = Pick<T, JustPropKeys<T>>;

type JustPropsDeep<T> = T extends object
    ? {
        [K in keyof JustProps<T>]: JustProps<T>[K] extends (infer E)[]
            ? JustPropsDeep<E>[]
            : JustProps<T>[K] extends object
                ? JustPropsDeep<T[K]>
                : T[K];
    }
    : T;

export type Draft<T> = T extends object
    ? {
        -readonly [K in keyof JustProps<T>]?: JustProps<T>[K] extends (infer E)[]
            ? Draft<E>[]
            : JustProps<T>[K] extends object
                ? Draft<T[K]>
                : T[K];
    }
    : T;

export type TwoLevelPartial<T> = {
    [P in keyof T]?: T[P] extends object ? {
        [K in keyof T[P]]?: T[P][K]
    } : T[P];
}

export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> }
export type Writeable<T> = { -readonly [P in keyof T]: T[P] };
export type WriteablePropertiesOnly<T> = PropertiesOnly<Writeable<T>>


export type Augment<T, A> = T extends object
    ? {
    [K in keyof T]: T[K] extends object ? Augment<T[K], A> : T[K];
} & A
    : T;

// narrows a union type to a subset
export type Subset<T, U extends T> = U;

// type stamps

const ValidStamp = Symbol.for('ValidStamp')
export type Valid<T extends object> = T & { [ValidStamp]: symbol };

const FreshStamp = Symbol.for('FreshStamp')
export type Fresh<T extends object> = T & { [FreshStamp]: symbol };

export type SetterArg<T> = T | ((t: T) => T);
export type Setter<T> = (arg: SetterArg<T>) => void;
export type Getter<T> = () => T;
export type StatePair<T> = [Getter<T>, Setter<T>];
export type BasicStatePair<T> = [T, Setter<T>];

export type NumericRange = [number, number];

export type HasLength = { length: number };

export type Strinky<T = string> = Record<string, T>;

export type AnyFunc = (...args: any[]) => any
export type AsyncFunc = (...args: any[]) => Promise<any>

export type Obj = {
    [k: (string | symbol | number | undefined)]: unknown
}

export type Implements<T> = T & { [otherProps: string]: any }

export type FilterFlags<Base, Condition> = {
    [Key in keyof Base]: Base[Key] extends Condition ? Key : never
}

export type AllowedNames<Base, Condition> = FilterFlags<Base, Condition>[keyof Base]

export type SubType<Base, Condition> = Pick<Base, AllowedNames<Base, Condition>>

export type FunctionsOnly<T> = SubType<T, Function>

export type PropertiesOnly<T> = Omit<T, keyof FunctionsOnly<T>>

export type Properties<T> = PropertiesOnly<T> & Partial<FunctionsOnly<T>>

export type Functions<T> = FunctionsOnly<T> & Partial<PropertiesOnly<T>>

export type Optional<T> = T | undefined

export type Comparable = {isEqual(other?: any): boolean}


export type KeyedBy<K extends string, V = undefined> = {
    [k in K]: V extends undefined ? k : V
}

export function keysOf<const K extends string>(): KeyedBy<K> {
    return new Proxy({}, {
        get: (_, key) => key
    }) as KeyedBy<K>
}

export interface Dictionary<T> {
    [index: string]: T
}

export type Props<T> = {
    [k in keyof T]: T[k] extends AnyFunc ? never : T[k]
}



export type KeyMapped<T, K extends string = string> = {
    [_k in K]?: T
}

export type Redefine<Thing, Prop extends keyof Thing, PropsNewDef> = Omit<Thing, Prop> & { [_P in Prop]: PropsNewDef }

export type InterfaceOf<T> = T extends infer I ? I : never;



export type Equals<A, B> = A extends B
    ? B extends A
        ? true
        : false
    : false

export type FilterPredicate<T> = ((it: T) => boolean) | Partial<T>