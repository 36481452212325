export class CompanyMatch {
    id: string
    address: string
}


export class LookupCompanyRequest {
    searchTerm: string
    requestId?: string
}

export class LookupCompanyResponse {
    matches: CompanyMatch[]
    requestId?: string
}


export class BeneficialOwner {
    name: string
    birthMonth?: number
    birthYear?: number
}

//TODO: move somewhere else
export type CompaniesHouseSecrets = {
    apiKey: string
    endpoint: string
}
