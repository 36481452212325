import {User} from './User'
import {Company} from './Company'

import {KeyMapped, keyLookup} from '@punnet/pure-utility-kit'
import {Required} from '@punnet/model-validation-pure'
import {IsEmail} from '@punnet/model-validation-pure'
import {Type} from 'class-transformer'
import {Subscription} from './Subscription'
import {HasLifecycleStatus, LifecycleStatus} from './LifecycleStatus'


export type AccountType =
    | 'INDIVIDUAL'
    | 'GROUP'
    | 'COMPANY'
export const AccountTypes = keyLookup<AccountType>()





export class Account implements HasLifecycleStatus {
    id: string
    type: AccountType
    status: LifecycleStatus
    startDate?: number
    endDate?: number
    cancellationReason?: string

    @Required()
    name: string
    @Required()
    contactName: string
    @Required()
    @IsEmail()
    contactEmail: string

    @Type(() => User)
    @Required<Account>('', ({instance}) => instance.type === AccountTypes.COMPANY)
    user?: User

    @Type(() => Company)
    @Required<Account>('Please enter a valid company', ({instance}) => instance.type === AccountTypes.COMPANY)
    company?: Company

    paymentProviderCustomerId?: string
    subscriptions: KeyMapped<Subscription>
}
