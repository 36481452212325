
import { values } from '@punnet/pure-utility-kit'


import type { QuoteLife, QuoteLifeResponse } from '@punnet/quote-pure'


import { Life } from '../../models/Life'
import { Policy } from '../../models/Policy'
import { Subscription } from '../../models/Subscription'



export function applyQuoteToSubscription(subscription: Subscription, quoteResponse: QuoteLifeResponse) {

    values(subscription.policies).forEach(policy => {
        applyQuoteToPolicy(policy, quoteResponse)
    })
    subscription.totalMonthlyPremium = values(subscription.policies)
        .filter(p => p.status === 'ACTIVE')
        .map(p => p.totalMonthlyPremium).reduce((s, i) => s + i, 0)
}


export function applyQuoteToPolicy(policy: Policy, quoteResponse: QuoteLifeResponse) {
    values(policy.lives).forEach(life => {
        if (life.id in quoteResponse.lives) {
            const quoteLife = quoteResponse.lives[life.id]
            applyQuoteToLife(quoteLife, life)
        }
    })
    policy.totalMonthlyPremium = values(policy.lives)
        .filter(l => l.status === 'ACTIVE')
        .map(l => l.totalMonthlyPremium).reduce((s, i) => s + i, 0)
}


function applyQuoteToLife(quotedLife: QuoteLife, life: Life) {
    values(quotedLife.benefits).forEach(quotedBenefit => {
        if (quotedBenefit.id in life.benefits) {
            life.benefits[quotedBenefit.id] = {
                ...life.benefits[quotedBenefit.id],
                ...quotedBenefit,
            }
        }
    })
    life.totalMonthlyPremium = values(life.benefits)
        .filter(b => b.status === 'ACTIVE')
        .filter(b => b.premium)
        .map(b => b.premium.total).reduce((s, i) => s + i, 0)

}
