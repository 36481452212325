import type { Benefit } from '@punnet/product-client'
import { values } from '@punnet/pure-utility-kit'
import type { Plan } from '@punnet/subscription-pure'
import { Toggle } from '@punnet/web-client-kit-library'
import type { Component } from 'solid-js'
import { Show } from 'solid-js'
import { useStoreActions } from '../../../app/store/quotes/quoteAndBuyStore'
import styles from './Eap.module.css'


const isSelected = (benefit: Benefit, plan: Plan) => {
    return values(plan.benefits).some(b => b.id === benefit.benefitTypes[0])
}

export const Eap: Component<{ benefit: Benefit, plan: Plan, readonly?: boolean }> = (props) => {
    // TODO: need to remove this dependency on the store
    const { addBenefit, removeBenefit } = useStoreActions

    const handleToggle = (isOn: boolean) => {
        props.benefit.isActive = isOn

        if (isOn) {
            addBenefit(props.benefit)
        } else {
            removeBenefit(props.benefit)
        }
    }

    return <div class={styles.eap}>
        <div>
            <Show when={!props.readonly}>
                <Toggle isOn={isSelected(props.benefit, props.plan)} onToggle={handleToggle} />
            </Show>
            <Show when={props.readonly}>
                <div class={styles.TextOnly}>
                    <Show when={isSelected(props.benefit, props.plan)}>Added</Show>
                    <Show when={!isSelected(props.benefit, props.plan)}>Not added</Show>
                </div>
            </Show>
        </div>
        <div class={styles.description}>£0.50 per member</div>
    </div>
}