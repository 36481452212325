import {MrAccount} from '../MrAccount'
import {MrLifeBenefit} from '../MrLifeBenefit'
import {MrLife} from '../MrLife'
import {MrPolicy} from '../MrPolicy'
import {MrSubscription} from '../MrSubscription'
import {KeyMapped, values} from '@peachy/utility-kit-pure'
import {HasLifecycleStatus} from '../LifecycleStatus'

export type LifecycleProps = 'status' | 'startDate' | 'endDate'

export type BenefitMinimal = Pick<MrLifeBenefit, 'id' | LifecycleProps> & {
}

export type LifeMinimal = Pick<MrLife, 'firstname' | 'lastname' | 'email' | LifecycleProps> & {
    benefits: BenefitMinimal[] | BenefitMinimal
}
export type PolicyMinimal = Pick<MrPolicy, LifecycleProps> & {
    lives: LifeMinimal[] | LifeMinimal
}
export type SubscriptionMinimal = Pick<MrSubscription, 'versionIdx'| 'versionDate' | LifecycleProps> & {
    policies: PolicyMinimal[] | PolicyMinimal
}
export type AccountMinimal = Pick<MrAccount, 'type' | LifecycleProps> & {
    subscriptions: SubscriptionMinimal[] | SubscriptionMinimal
}


export function getMinimalAccount(account: MrAccount, includeBenefits = false): AccountMinimal {
    return {
        type: account.type,
        status: account.status,
        startDate: account.startDate,
        endDate: account.endDate,
        subscriptions: oneOrMany(account.subscriptions).map(s => getMinimalSubscription(s, includeBenefits)),
    }
}

export function getMinimalSubscription(subscription: MrSubscription, includeBenefits = false): SubscriptionMinimal {
    return {
        versionIdx: subscription.versionIdx,
        versionDate: subscription.versionDate,
        ...lifeCycleProps(subscription),
        policies: oneOrMany(subscription.policies).map(p => getMinimalPolicy(p, includeBenefits)),
    }
}

export function getMinimalPolicy(policy: MrPolicy, includeBenefits = false): PolicyMinimal {
    return {
        ...lifeCycleProps(policy),
        lives: oneOrMany(policy.lives).map(l => getMinimalLife(l, includeBenefits)),
    }

}


export function getMinimalLife(life: MrLife, includeBenefits = false): LifeMinimal {
    return {
        firstname: life.firstname,
        lastname: life.lastname,
        email: life.email,
        ...lifeCycleProps(life),
        benefits: includeBenefits ? oneOrMany(life.benefits).map(getMinimalBenefit) : undefined,
    }
}

export function getMinimalBenefit(benefit: MrLifeBenefit): BenefitMinimal {
    return {
        id: benefit.id,
        ...lifeCycleProps(benefit),
    }
}






function lifeCycleProps(o: HasLifecycleStatus) {
    return {
        status: o.status,
        startDate: o.startDate!,
        endDate: o.endDate
    }
}


function oneOrMany<T>(elementMap: KeyMapped<T>) {
    return {
        map: <M>(mapper: (t: T) => M): M|M[] => {
            const mapped = values(elementMap).map(t => mapper(t))
            if (mapped.length < 2) {
                return mapped[0]
            } else {
                return mapped
            }
        }
    }
}
