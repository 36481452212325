import { CompanyLookup, type CompanyLookupProps } from '@punnet/company-lookup-web-client'
import { classList } from '@punnet/web-client-kit-library'
import styles from './SovereignCompanyLookup.module.css'
import baseStyles from './SovereignLookup.module.css'


export function SovereignCompanyLookup(props: CompanyLookupProps) {
    
    // const styles = {} as any

    return <CompanyLookup
        {...props}
        lookupInputClass={classList(baseStyles.lookupInput, styles.lookupInput, props.lookupInputClass)}
        lookupListClass={classList(baseStyles.lookupList, styles.lookupList, props.lookupListClass)}
        showHideTransitionMarkerClass={classList(baseStyles.lookupShowHideTransition)}
        updateTransitionMarkerClass={baseStyles.lookupUpdateTransition}
    />
}
