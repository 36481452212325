import { useSubscriptionService } from '@punnet/subscription-web-client'
import { useParams } from '@solidjs/router'
import type { ParentProps } from 'solid-js'
import { Show, createContext, createEffect, createResource, createSignal, useContext } from 'solid-js'
import { Pulse } from '../../../../components/Pulse/Pulse'
import { useStoreActions } from '../../../store/quotes/quoteAndBuyStore'
import type { SubscriptionQuoteResponse } from '@punnet/subscription-pure'

const Context = createContext<SubscriptionQuoteResponse>()

async function loadQuote() {
    const response = await useSubscriptionService().loadSmeDraftQuote({ quoteId: useParams().quoteId })
    return response
}

export const SubscriptionQuoteLoader = (props: ParentProps) => {
    const [savedSubscriptionQuote] = createResource(loadQuote)
    const [isReady, setIsReady] = createSignal(false)

    createEffect(() => {
        if (savedSubscriptionQuote.state === 'ready') {
            useStoreActions.hydrate(savedSubscriptionQuote())
            setIsReady(true)
        }
    })

    return (
        <>
            <Show when={ !savedSubscriptionQuote.error && isReady() }>
                <Context.Provider value={ savedSubscriptionQuote() }>
                    {props.children}
                </Context.Provider>
            </Show>
            <Show when={savedSubscriptionQuote.error}>
                <div>No quote found for id: {useParams().quoteId}</div>
            </Show>
            <Pulse isShown={savedSubscriptionQuote.loading} />
        </>
    )
}

export const useSavedSubscriptionQuote = () => {
    return useContext(Context)
}
