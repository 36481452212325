import { Properties } from '@punnet/pure-utility-kit'
import { AnEvent } from '@punnet/core-event-pure'

export class EmailSendSuccess extends AnEvent {
    constructor(from: Properties<EmailSendSuccess>) {
        super()
        Object.assign(this, from)
    }

    toEmail: string
}
export class EmailSendFailure extends EmailSendSuccess {
    constructor(from: Properties<EmailSendFailure>) {
        super(from)
        Object.assign(this, from)
    }
    error: string
}


export type EmailSendResult = EmailSendSuccess | EmailSendFailure
