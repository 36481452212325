import type { Draft } from "@punnet/pure-utility-kit"
import type { Policy } from "@punnet/subscription-pure"

const findDuplicates = <T>(arr: T[]): T[] => {
    const seen = new Set<T>()
    const duplicates = new Set<T>()

    arr.forEach(item => seen.has(item) ? duplicates.add(item) : seen.add(item))

    // TODO set has callback issues
    return Array.from(duplicates)
}

export const findDuplicateEmails = (policies: Draft<Policy>[]): string[] => {
    return findDuplicates(policies.flatMap((p: Draft<Policy>) => Object.values(p.lives).map(l => l.email)))
}