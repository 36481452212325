import {PropertiesOnly} from '@peachy/utility-kit-pure'
import {assign} from 'lodash-es'

export class BankDetails {

    readonly accountNumber: string
    readonly sortCode: string

    constructor(props: PropertiesOnly<BankDetails>) {
        assign(this, props)
    }

}
